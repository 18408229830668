export const CONTAINER = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

export const ITEM = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export const NEW_MAP_DATA = [
  {
    type: 'Polygon',
    name: 'N7',
    id: 'N7',
    coordinates: [
      [48.7318001, 21.2437714],
      [48.7315887, 21.2431491],
      [48.731456, 21.2432511],
      [48.7314693, 21.2432913],
      [48.7314268, 21.2433208],
      [48.7313941, 21.2432216],
      [48.7312667, 21.2433235],
      [48.7315073, 21.2440088],
      [48.7318001, 21.2437714],
    ],
    center: [48.7315887, 21.2434293],
  },
  {
    type: 'Polygon',
    name: 'N9',
    id: 'N9',
    coordinates: [
      [48.7318001, 21.2437714],
      [48.7315073, 21.2440088],
      [48.7315442, 21.2441178],
      [48.7315114, 21.2441487],
      [48.7315663, 21.2443083],
      [48.7316008, 21.2442801],
      [48.7316627, 21.2444679],
      [48.731829, 21.2443378],
      [48.7318785, 21.2444826],
      [48.7320086, 21.2443793],
      [48.7318001, 21.2437714],
    ],
    center: [48.7317302, 21.2441454],
  },
  {
    type: 'Polygon',
    name: 'PK5',
    id: 'PK5',
    coordinates: [
      [48.731525, 21.2457553],
      [48.7313999, 21.2458599],
      [48.7313857, 21.2458163],
      [48.7313136, 21.2458753],
      [48.7314021, 21.2461201],
      [48.7314742, 21.2460611],
      [48.7314786, 21.2460745],
      [48.7315277, 21.2460349],
      [48.7315237, 21.2460228],
      [48.7315993, 21.2459618],
      [48.731525, 21.2457553],
    ],
    center: [48.7314794, 21.2458792],
  },
  {
    type: 'Polygon',
    name: 'PK7',
    id: 'PK7',
    coordinates: [
      [48.7320165, 21.2456252],
      [48.7319572, 21.2454502],
      [48.7318236, 21.2455548],
      [48.7317799, 21.2454267],
      [48.7316808, 21.2455065],
      [48.7317237, 21.2456312],
      [48.7316056, 21.2457231],
      [48.7316653, 21.2459001],
      [48.7320165, 21.2456252],
    ],
    center: [48.7318306, 21.2456485],
  },
  {
    type: 'Polygon',
    name: 'PK9',
    id: 'PK9',
    coordinates: [
      [48.7318, 21.2452151],
      [48.7317204, 21.2449905],
      [48.7316682, 21.2450334],
      [48.7316642, 21.24502],
      [48.7315828, 21.2450844],
      [48.7315881, 21.2450978],
      [48.731509, 21.2451615],
      [48.7315881, 21.2453848],
      [48.7318, 21.2452151],
    ],
    center: [48.7316634, 21.2451778],
  },
  {
    type: 'Polygon',
    name: 'PK11',
    id: 'PK11',
    coordinates: [
      [48.7321005, 21.2446276],
      [48.7319015, 21.2447819],
      [48.7318926, 21.244755],
      [48.731821, 21.2448087],
      [48.7318776, 21.2449736],
      [48.7318661, 21.244983],
      [48.7320368, 21.2454913],
      [48.7320465, 21.2454832],
      [48.7321111, 21.2456616],
      [48.732181, 21.2456039],
      [48.7321766, 21.2455905],
      [48.7323729, 21.2454403],
      [48.7323181, 21.2452767],
      [48.7321686, 21.2453961],
      [48.7320014, 21.2449025],
      [48.7321536, 21.2447872],
      [48.7321005, 21.2446276],
    ],
    center: [48.7320047, 21.2450971],
  },
  {
    type: 'Polygon',
    name: 'PK13',
    id: 'PK13',
    coordinates: [
      [48.732564, 21.244232],
      [48.7324959, 21.2442803],
      [48.7325207, 21.244354],
      [48.7322677, 21.2445418],
      [48.7323446, 21.2447819],
      [48.7326029, 21.244586],
      [48.7326241, 21.2446558],
      [48.7326887, 21.2446102],
      [48.732564, 21.244232],
    ],
    center: [48.7324789, 21.2444709],
  },
  {
    type: 'Polygon',
    name: 'PK15',
    id: 'PK15',
    coordinates: [
      [48.7323473, 21.2436298],
      [48.732265, 21.2436942],
      [48.7322907, 21.2437653],
      [48.7320607, 21.2439477],
      [48.7321421, 21.2441837],
      [48.7323703, 21.2440013],
      [48.7323906, 21.2440644],
      [48.7324747, 21.2439973],
      [48.7323473, 21.2436298],
    ],
    center: [48.7322675, 21.2438809],
  },
  {
    type: 'Polygon',
    name: 'N5',
    id: 'N5',
    coordinates: [
      [48.732231, 21.2426864],
      [48.7317736, 21.2430552],
      [48.7318749, 21.2433469],
      [48.7319904, 21.2432543],
      [48.7320125, 21.2433167],
      [48.7320235, 21.2433073],
      [48.7320324, 21.2433328],
      [48.7321031, 21.2432758],
      [48.7321199, 21.2433241],
      [48.7322066, 21.2432557],
      [48.7321513, 21.2430921],
      [48.7323031, 21.2429687],
      [48.7322686, 21.2428674],
      [48.7322898, 21.2428513],
      [48.732231, 21.2426864],
    ],
    center: [48.7320544, 21.2429427],
  },
  {
    type: 'Polygon',
    name: 'N3',
    id: 'N3',
    coordinates: [
      [48.7328352, 21.2423669],
      [48.7327791, 21.2424085],
      [48.7327516, 21.2423281],
      [48.7323138, 21.2426734],
      [48.7324381, 21.2430348],
      [48.7325725, 21.2429275],
      [48.7325818, 21.2429543],
      [48.7327459, 21.2428256],
      [48.732737, 21.2428015],
      [48.7328746, 21.2426935],
      [48.7328251, 21.2425507],
      [48.7328817, 21.2425051],
      [48.7328352, 21.2423669],
    ],
    center: [48.7326021, 21.2425811],
  },
  {
    type: 'Polygon',
    name: 'PK19',
    id: 'PK19',
    coordinates: [
      [48.7329748, 21.2427639],
      [48.7328599, 21.242851],
      [48.7329554, 21.2431327],
      [48.7329138, 21.2431649],
      [48.7329766, 21.2433607],
      [48.7330023, 21.2433419],
      [48.7330129, 21.24337],
      [48.733004, 21.2433767],
      [48.7330093, 21.2433915],
      [48.7329227, 21.2434559],
      [48.732935, 21.2434907],
      [48.7329041, 21.2435162],
      [48.7328767, 21.2434398],
      [48.7328935, 21.2434277],
      [48.7328625, 21.2433365],
      [48.7328448, 21.2433499],
      [48.7327962, 21.2432131],
      [48.7329085, 21.243126],
      [48.7328687, 21.2430133],
      [48.7326714, 21.2431756],
      [48.7328475, 21.2436758],
      [48.7327855, 21.2437201],
      [48.7328855, 21.244003],
      [48.7329342, 21.2439615],
      [48.7331128, 21.2444738],
      [48.7333136, 21.2443142],
      [48.7332774, 21.2442069],
      [48.7331677, 21.2442967],
      [48.7331173, 21.2441586],
      [48.7331385, 21.2441398],
      [48.7331093, 21.2440553],
      [48.7330863, 21.2440728],
      [48.7330606, 21.2439896],
      [48.7330925, 21.2439655],
      [48.7331066, 21.2440084],
      [48.7332146, 21.2439186],
      [48.7332756, 21.244105],
      [48.7332862, 21.2440969],
      [48.7333959, 21.2444268],
      [48.7335118, 21.2443423],
      [48.7333994, 21.2440165],
      [48.7334083, 21.2440097],
      [48.7333083, 21.2437134],
      [48.7331588, 21.24383],
      [48.7331871, 21.2439132],
      [48.7331588, 21.243936],
      [48.7329908, 21.2434639],
      [48.7330244, 21.2434371],
      [48.7330421, 21.2434907],
      [48.7331924, 21.2433767],
      [48.7330907, 21.2430737],
      [48.7330819, 21.2430817],
      [48.7329748, 21.2427639],
    ],
    center: [48.7329542, 21.2437049],
  },
  {
    type: 'Polygon',
    name: 'PK3',
    id: 'PK3',
    coordinates: [
      [48.7317253, 21.2463443],
      [48.7315912, 21.2464489],
      [48.7317045, 21.2467849],
      [48.7318385, 21.2466809],
      [48.7317253, 21.2463443],
    ],
    center: [48.7317243, 21.2464729],
  },
  {
    type: 'Polygon',
    name: 'PK8',
    id: 'PK8',
    coordinates: [
      [48.7319863, 21.2466342],
      [48.7320916, 21.2465511],
      [48.7320606, 21.2464619],
      [48.7320248, 21.2464921],
      [48.7320067, 21.2464438],
      [48.7321075, 21.2463653],
      [48.7320951, 21.2463291],
      [48.7321716, 21.2462667],
      [48.7322477, 21.2464853],
      [48.7322995, 21.2464431],
      [48.7322654, 21.2463479],
      [48.7322747, 21.2463405],
      [48.7322508, 21.2462741],
      [48.732288, 21.2462439],
      [48.7322163, 21.2460361],
      [48.7321871, 21.2460595],
      [48.7321774, 21.2460307],
      [48.7318634, 21.2462855],
      [48.7319863, 21.2466342],
    ],
    center: [48.7320563, 21.2461912],
  },
  {
    type: 'Polygon',
    name: 'PK6',
    id: 'PK6',
    coordinates: [
      [48.7324322, 21.246565],
      [48.7321567, 21.2467782],
      [48.7322221, 21.2469713],
      [48.7324999, 21.2467608],
      [48.7324322, 21.246565],
    ],
    center: [48.7323222, 21.2466937],
  },
  {
    type: 'Polygon',
    name: 'N1',
    id: 'N1',
    coordinates: [
      [48.7336113, 21.241402],
      [48.7336423, 21.2413792],
      [48.7336732, 21.2414717],
      [48.7339032, 21.2412933],
      [48.7339103, 21.2413121],
      [48.7340093, 21.2412383],
      [48.734004, 21.2412155],
      [48.7342376, 21.2410345],
      [48.7341792, 21.2408548],
      [48.7339297, 21.2410492],
      [48.7339218, 21.2410264],
      [48.7338413, 21.2410935],
      [48.733851, 21.2411217],
      [48.7336201, 21.2413014],
      [48.7336263, 21.2413201],
      [48.7334353, 21.2414623],
      [48.7334264, 21.2414368],
      [48.7331938, 21.2416192],
      [48.7331876, 21.2415978],
      [48.7330921, 21.2416702],
      [48.7331, 21.2416957],
      [48.7328665, 21.241866],
      [48.7329231, 21.2420363],
      [48.7331593, 21.2418552],
      [48.7331655, 21.2418821],
      [48.7331991, 21.2418593],
      [48.733223, 21.2419344],
      [48.7331496, 21.2419907],
      [48.7332504, 21.2422924],
      [48.7334309, 21.2421543],
      [48.7333309, 21.2418485],
      [48.7332584, 21.2419022],
      [48.7332363, 21.2418338],
      [48.7332619, 21.2418164],
      [48.7332539, 21.2417922],
      [48.7334795, 21.2416138],
      [48.7336308, 21.2420712],
      [48.7333972, 21.2422509],
      [48.7333893, 21.242224],
      [48.7332991, 21.2423018],
      [48.733307, 21.2423233],
      [48.7330753, 21.2425017],
      [48.7331301, 21.242668],
      [48.7333645, 21.2424896],
      [48.7334574, 21.2427726],
      [48.7334185, 21.2428047],
      [48.7334521, 21.2429013],
      [48.7335573, 21.2428222],
      [48.7334344, 21.242452],
      [48.7334609, 21.2424346],
      [48.7334565, 21.2424198],
      [48.7336803, 21.2422549],
      [48.7338298, 21.2427055],
      [48.7336042, 21.2428785],
      [48.7335927, 21.242845],
      [48.7335016, 21.2429214],
      [48.7335096, 21.2429482],
      [48.7332716, 21.2431306],
      [48.7333291, 21.2432983],
      [48.7335662, 21.2431132],
      [48.7335759, 21.24314],
      [48.7336697, 21.2430703],
      [48.7336617, 21.2430435],
      [48.7338952, 21.2428624],
      [48.7338705, 21.2427833],
      [48.7340536, 21.2426452],
      [48.7340093, 21.2425151],
      [48.7340297, 21.242499],
      [48.7339032, 21.2421261],
      [48.7338855, 21.2421409],
      [48.7336962, 21.2415763],
      [48.733675, 21.2415937],
      [48.7336113, 21.241402],
    ],
    center: [48.7336169, 21.2416554],
  },
  {
    type: 'Polygon',
    name: 'V4',
    id: 'V4',
    coordinates: [
      [48.7341451, 21.2463368],
      [48.734276, 21.2462322],
      [48.7339788, 21.2453873],
      [48.7347678, 21.2447248],
      [48.7346918, 21.2445075],
      [48.733763, 21.2452693],
      [48.7341451, 21.2463368],
    ],
    center: [48.7342219, 21.2449545],
  },
  {
    type: 'Polygon',
    name: 'U2',
    id: 'U2',
    coordinates: [
      [48.735556, 21.24572],
      [48.735503, 21.2455698],
      [48.7351173, 21.2458863],
      [48.7350979, 21.2458165],
      [48.7351315, 21.2457951],
      [48.7349652, 21.2453418],
      [48.7348467, 21.245441],
      [48.7350059, 21.2458917],
      [48.7350413, 21.2458621],
      [48.7350643, 21.2459292],
      [48.7347104, 21.2462189],
      [48.7346892, 21.2461626],
      [48.7345512, 21.2462725],
      [48.7345972, 21.2464093],
      [48.7347051, 21.2463208],
      [48.7347317, 21.2463986],
      [48.7350749, 21.2461196],
      [48.7350819, 21.2461411],
      [48.735556, 21.24572],
    ],
    center: [48.7351112, 21.2459448],
  },
  {
    type: 'Polygon',
    name: 'W4',
    id: 'W4',
    coordinates: [
      [48.7356109, 21.244057],
      [48.735319, 21.2442743],
      [48.7357878, 21.245661],
      [48.7357559, 21.2456851],
      [48.7358019, 21.2458165],
      [48.7358338, 21.245787],
      [48.7358692, 21.2458943],
      [48.7358391, 21.2459212],
      [48.7359116, 21.2461357],
      [48.7361947, 21.2459185],
      [48.7362053, 21.2459507],
      [48.7363026, 21.2458756],
      [48.7362884, 21.2458273],
      [48.7363114, 21.2458139],
      [48.7362831, 21.24572],
      [48.7362548, 21.2457388],
      [48.7360867, 21.2452184],
      [48.7359611, 21.2453284],
      [48.7360443, 21.2455832],
      [48.7360178, 21.2456047],
      [48.7360054, 21.2455564],
      [48.7359488, 21.245602],
      [48.7359435, 21.2455778],
      [48.7359665, 21.2455617],
      [48.7359116, 21.2453928],
      [48.7358798, 21.2454169],
      [48.7358851, 21.245441],
      [48.7358462, 21.2454652],
      [48.7356923, 21.2450172],
      [48.7357188, 21.2449958],
      [48.7356127, 21.2446847],
      [48.7355861, 21.2447034],
      [48.7354676, 21.2443413],
      [48.7356586, 21.2441992],
      [48.7356109, 21.244057],
    ],
    center: [48.7358558, 21.2455699],
  },
  {
    type: 'Polygon',
    name: 'W1',
    id: 'W1',
    coordinates: [
      [48.735036, 21.2417947],
      [48.7349829, 21.2418349],
      [48.7350254, 21.241969],
      [48.7350068, 21.2419797],
      [48.7351076, 21.2422989],
      [48.7351943, 21.2422332],
      [48.7350926, 21.241918],
      [48.7350775, 21.2419288],
      [48.735036, 21.2417947],
    ],
    center: [48.7350929, 21.2420337],
  },
  {
    type: 'Polygon',
    name: 'W2',
    id: 'W2',
    coordinates: [
      [48.7352111, 21.242319],
      [48.735204, 21.2423231],
      [48.7351465, 21.242366],
      [48.7351386, 21.2423405],
      [48.7350811, 21.2423874],
      [48.7352713, 21.2429735],
      [48.7353951, 21.2428823],
      [48.7352111, 21.242319],
    ],
    center: [48.7350929, 21.2420337],
  },
  {
    type: 'Polygon',
    name: 'W3',
    id: 'W3',
    coordinates: [
      [48.7354776, 21.2430667],
      [48.7354152, 21.2431156],
      [48.7354599, 21.2432457],
      [48.7355209, 21.2431968],
      [48.7354776, 21.2430667],
    ],
    center: [48.7350929, 21.2420337],
  },
  {
    type: 'Polygon',
    name: 'L9B',
    id: 'L9',
    coordinates: [
      [48.7305671, 21.2453765],
      [48.7315419, 21.244588],
      [48.7313137, 21.2439335],
      [48.7310041, 21.2441668],
      [48.7311527, 21.2446148],
      [48.7304787, 21.2451271],
      [48.7304291, 21.2449796],
      [48.7302911, 21.2450949],
      [48.7304044, 21.2454221],
      [48.7303743, 21.2454463],
      [48.7304592, 21.2456877],
      [48.7304928, 21.2456582],
      [48.7306237, 21.2455535],
      [48.7305671, 21.2453765],
    ],
    center: [48.7309942, 21.2448079],
  },
  {
    type: 'Polygon',
    name: 'L9A',
    id: 'L9',
    coordinates: [
      [48.7306237, 21.2455535],
      [48.7304928, 21.2456582],
      [48.7307094, 21.2463057],
      [48.7307855, 21.2466879],
      [48.7306731, 21.2467456],
      [48.7307138, 21.246944],
      [48.7307943, 21.2471036],
      [48.7310942, 21.2468327],
      [48.7309872, 21.2465216],
      [48.7309712, 21.2465364],
      [48.7306237, 21.2455535],
    ],
    center: [48.7306368, 21.2457521],
  },
  {
    type: 'Polygon',
    name: 'PK4',
    id: 'PK4',
    coordinates: [
      [48.7317597, 21.2472527],
      [48.7316137, 21.2473681],
      [48.7317499, 21.2477516],
      [48.7318924, 21.2476323],
      [48.7317597, 21.2472527],
    ],
    center: [48.7317432, 21.2474388],
  },
  {
    type: 'Polygon',
    name: 'PK2',
    id: 'PK2',
    coordinates: [
      [48.7318411, 21.2476778],
      [48.7318013, 21.24771],
      [48.7318189, 21.2477623],
      [48.731642, 21.2479032],
      [48.7317314, 21.248166],
      [48.7321294, 21.2478482],
      [48.7320383, 21.2475853],
      [48.7318561, 21.2477315],
      [48.7318411, 21.2476778],
    ],
    center: [48.7319025, 21.2477795],
  },
  {
    type: 'Polygon',
    name: 'N32',
    id: 'N32',
    coordinates: [
      [48.7297393, 21.2443076],
      [48.7298039, 21.2444927],
      [48.7297703, 21.2445209],
      [48.7298296, 21.2446885],
      [48.7299543, 21.2445866],
      [48.7300056, 21.2447341],
      [48.730087, 21.2446697],
      [48.7300383, 21.2445249],
      [48.7303373, 21.2442835],
      [48.7302772, 21.2441078],
      [48.7299171, 21.2443948],
      [48.7298561, 21.2442111],
      [48.7297393, 21.2443076],
    ],
    center: [48.7300389, 21.2443734],
  },
  {
    type: 'Polygon',
    name: 'PK10',
    id: 'PK10',
    coordinates: [
      [48.7325226, 21.2457929],
      [48.7322962, 21.2459672],
      [48.7325509, 21.2467048],
      [48.7327721, 21.2465278],
      [48.7325226, 21.2457929],
    ],
    center: [48.7325032, 21.2462005],
  },
  {
    type: 'Point',
    name: 'PK10A',
    id: 'PK10A',
    coordinates: [[48.7325032, 21.2462005]],
    center: [48.7325032, 21.2462005],
  },
  {
    type: 'Polygon',
    name: 'PK10A',
    id: 'PK10A',
    coordinates: [
      [48.7325226, 21.2457929],
      [48.732735, 21.2464244],
      [48.7328659, 21.2463197],
      [48.7326466, 21.2457001],
      [48.7325226, 21.2457929],
    ],
    center: [48.7325032, 21.2462005],
  },
  {
    type: 'Point',
    name: 'PK10A',
    id: 'PK10A',
    coordinates: [[48.7326944, 21.2460622]],
    center: [48.7325032, 21.2462005],
  },
  {
    type: 'Polygon',
    name: 'PK12',
    id: 'PK12',
    coordinates: [
      [48.7326466, 21.2457001],
      [48.7328978, 21.2464324],
      [48.7330163, 21.2463385],
      [48.732774, 21.2456009],
      [48.7326466, 21.2457001],
    ],
    center: [48.7328447, 21.2460166],
  },
  {
    type: 'Point',
    name: 'PK12',
    id: 'PK12',
    coordinates: [[48.7328447, 21.2460166]],
    center: [48.7328447, 21.2460166],
  },
  {
    type: 'Polygon',
    name: 'PK12A',
    id: 'PK12A',
    coordinates: [
      [48.732774, 21.2456009],
      [48.7329756, 21.2462124],
      [48.7331419, 21.2460837],
      [48.7329367, 21.2454748],
      [48.732774, 21.2456009],
    ],
    center: [48.7329509, 21.2458342],
  },
  {
    type: 'Point',
    name: 'PK12A',
    id: 'PK12A',
    coordinates: [[48.7329509, 21.2458342]],
    center: [48.7329509, 21.2458342],
  },
  {
    type: 'Polygon',
    name: 'PK14',
    id: 'PK14',
    coordinates: [
      [48.7334657, 21.2459764],
      [48.7336019, 21.2458637],
      [48.7335665, 21.2457565],
      [48.7336196, 21.2457109],
      [48.7334109, 21.2450966],
      [48.7329827, 21.2454346],
      [48.7331986, 21.2460569],
      [48.733257, 21.2460086],
      [48.7332959, 21.2461212],
      [48.7334286, 21.2460113],
      [48.7333295, 21.2457135],
      [48.7332658, 21.2457645],
      [48.7331756, 21.245499],
      [48.7333224, 21.2453863],
      [48.7334073, 21.2456277],
      [48.7333578, 21.2456653],
      [48.7334657, 21.2459764],
    ],
    center: [48.7333896, 21.245389],
  },
  {
    type: 'Point',
    name: 'PK14',
    id: 'PK14',
    coordinates: [[48.7333896, 21.245389]],
    center: [48.7333896, 21.245389],
  },
  {
    type: 'Polygon',
    name: 'R-50',
    id: 'R-50',
    coordinates: [
      [48.7357432, 21.2718033],
      [48.7354017, 21.2720782],
      [48.735476, 21.2722888],
      [48.7358139, 21.2720179],
      [48.7357432, 21.2718033],
    ],
    center: [48.7356144, 21.2719664],
  },
  {
    type: 'Polygon',
    name: 'R-7',
    id: 'R-7',
    coordinates: [
      [48.7351819, 21.2731203],
      [48.7349484, 21.2733107],
      [48.7350068, 21.273473],
      [48.7352394, 21.2732852],
      [48.7351819, 21.2731203],
    ],
    center: [48.7350341, 21.2733236],
  },
  {
    type: 'Polygon',
    name: 'R-16',
    id: 'R-16',
    coordinates: [
      [48.7323085, 21.2719036],
      [48.7323811, 21.2721101],
      [48.7323209, 21.272153],
      [48.7323528, 21.2722415],
      [48.7324111, 21.2721986],
      [48.7325845, 21.2727109],
      [48.7325633, 21.2727243],
      [48.732634, 21.2729336],
      [48.7326623, 21.2729094],
      [48.732956, 21.2737436],
      [48.7330038, 21.273698],
      [48.7330197, 21.2737463],
      [48.7330746, 21.2737007],
      [48.7330604, 21.2736551],
      [48.7331152, 21.2736148],
      [48.7328516, 21.2728531],
      [48.7328835, 21.272829],
      [48.7328888, 21.2728504],
      [48.7329666, 21.272786],
      [48.7328428, 21.2724239],
      [48.7327614, 21.272491],
      [48.7327667, 21.2725151],
      [48.7327367, 21.2725393],
      [48.7324713, 21.2717722],
      [48.7323085, 21.2719036],
    ],
    center: [48.7327036, 21.2726866],
  },
  {
    type: 'Polygon',
    name: 'R-43',
    id: 'R-43',
    coordinates: [
      [48.7319053, 21.2729341],
      [48.7321689, 21.2736583],
      [48.7322573, 21.2735805],
      [48.7324944, 21.2742484],
      [48.7325793, 21.274176],
      [48.7325103, 21.2739775],
      [48.7325935, 21.2739077],
      [48.732666, 21.2741169],
      [48.7327474, 21.2740338],
      [48.7326306, 21.2737039],
      [48.7326766, 21.2736771],
      [48.7325899, 21.2734223],
      [48.7325492, 21.2734705],
      [48.7324732, 21.2732721],
      [48.7325528, 21.2732774],
      [48.7323104, 21.2725881],
      [48.7319053, 21.2729341],
    ],
    center: [48.7323419, 21.273124],
  },
  {
    type: 'Polygon',
    name: 'Polygón 5',
    id: 'Polygón 5',
    coordinates: [
      [48.7311791, 21.2728549],
      [48.7310703, 21.2729421],
      [48.7312552, 21.2734477],
      [48.7313622, 21.2733592],
      [48.7311791, 21.2728549],
    ],
    center: [48.7311666, 21.2730191],
  },
  {
    type: 'Polygon',
    name: 'R-19',
    id: 'R-19',
    coordinates: [
      [48.7318108, 21.2732068],
      [48.7317082, 21.2732913],
      [48.7317488, 21.2734107],
      [48.7314755, 21.273632],
      [48.7314596, 21.2735823],
      [48.7313437, 21.2736762],
      [48.7314516, 21.2739847],
      [48.7315675, 21.2738895],
      [48.7315525, 21.2738412],
      [48.7318196, 21.2736212],
      [48.7318656, 21.27375],
      [48.7319726, 21.2736601],
      [48.7318108, 21.2732068],
    ],
    center: [48.7316537, 21.273529],
  },
  {
    type: 'Polygon',
    name: 'R-14',
    id: 'R-14',
    coordinates: [
      [48.7333501, 21.2738576],
      [48.7330016, 21.2741339],
      [48.7330794, 21.2743377],
      [48.7332315, 21.274217],
      [48.7332439, 21.2742519],
      [48.7333041, 21.2741982],
      [48.7332899, 21.2741634],
      [48.7334191, 21.2740587],
      [48.7333501, 21.2738576],
    ],
    center: [48.7331992, 21.2740416],
  },
  {
    type: 'Polygon',
    name: 'R-15',
    id: 'R-15',
    coordinates: [
      [48.732823, 21.2742838],
      [48.7324727, 21.2745708],
      [48.7325541, 21.2747693],
      [48.7327097, 21.2746325],
      [48.7327203, 21.2746674],
      [48.7327717, 21.2746191],
      [48.732761, 21.2745869],
      [48.7328973, 21.2744796],
      [48.732823, 21.2742838],
    ],
    center: [48.7326986, 21.2744252],
  },
  {
    type: 'Polygon',
    name: 'R-25',
    id: 'R-25',
    coordinates: [
      [48.7320747, 21.2720635],
      [48.7318898, 21.272211],
      [48.7318827, 21.2721855],
      [48.7318199, 21.2722365],
      [48.7318279, 21.2722606],
      [48.731643, 21.2724095],
      [48.7317085, 21.2726013],
      [48.732141, 21.2722566],
      [48.7320747, 21.2720635],
    ],
    center: [48.7319031, 21.2722496],
  },
  {
    type: 'Polygon',
    name: 'R8',
    id: 'R8',
    coordinates: [
      [48.7344144, 21.2735923],
      [48.7343021, 21.2736848],
      [48.7343587, 21.2738417],
      [48.7343242, 21.2738686],
      [48.7343189, 21.2738498],
      [48.7341146, 21.2740107],
      [48.734165, 21.2741556],
      [48.7343578, 21.273996],
      [48.7343852, 21.2740858],
      [48.7344401, 21.2740483],
      [48.7344082, 21.2739544],
      [48.7345117, 21.2738712],
      [48.7344144, 21.2735923],
    ],
    center: [48.7342876, 21.2738976],
  },
  {
    type: 'Polygon',
    name: 'R9',
    id: 'R9',
    coordinates: [
      [48.7339624, 21.2739611],
      [48.7338695, 21.2740362],
      [48.7339686, 21.2743232],
      [48.7340615, 21.2742481],
      [48.7339624, 21.2739611],
    ],
    center: [48.7339624, 21.2739611],
  },
  {
    type: 'Polygon',
    name: 'R11',
    id: 'R11',
    coordinates: [
      [48.7344136, 21.2730679],
      [48.7340934, 21.2733307],
      [48.7341517, 21.273497],
      [48.7344772, 21.2732261],
      [48.7344136, 21.2730679],
    ],
    center: [48.7343124, 21.2732056],
  },
  {
    type: 'Polygon',
    name: 'R17',
    id: 'R17',
    coordinates: [
      [48.7322884, 21.2746496],
      [48.732169, 21.2747301],
      [48.7324352, 21.2756072],
      [48.732552, 21.2755227],
      [48.732406, 21.2750439],
      [48.7324264, 21.2750305],
      [48.7324025, 21.2749447],
      [48.7323777, 21.2749581],
      [48.7322884, 21.2746496],
    ],
    center: [48.7322884, 21.2746496],
  },
  {
    type: 'Polygon',
    name: 'Hala opráv',
    id: 'Hala opráv',
    coordinates: [
      [48.7304279, 21.2717668],
      [48.7303235, 21.2718513],
      [48.7305208, 21.2723864],
      [48.7306207, 21.2722965],
      [48.7304279, 21.2717668],
    ],
    center: [48.7304377, 21.2720052],
  },
  {
    type: 'Polygon',
    name: 'Š31',
    id: 'Š31',
    coordinates: [
      [48.9855585, 21.2402345],
      [48.9855488, 21.2402452],
      [48.9855251, 21.2401527],
      [48.9852557, 21.2403338],
      [48.9852205, 21.240205],
      [48.9851281, 21.24026],
      [48.9852276, 21.240614],
      [48.9852469, 21.2406006],
      [48.985254, 21.2406301],
      [48.9853552, 21.2405658],
      [48.9853376, 21.2405041],
      [48.9854758, 21.2404129],
      [48.9854916, 21.2404706],
      [48.9855928, 21.2404062],
      [48.9855875, 21.2403807],
      [48.9855963, 21.2403753],
      [48.9855585, 21.2402345],
    ],
    center: [48.98537, 21.2403185],
  },
  {
    type: 'Polygon',
    name: 'Š32',
    id: 'Š32',
    coordinates: [
      [48.9854036, 21.2407214],
      [48.9853561, 21.2407509],
      [48.9853622, 21.2407804],
      [48.9853103, 21.2408112],
      [48.9853992, 21.2411358],
      [48.9854335, 21.2411116],
      [48.9854414, 21.2411411],
      [48.9854828, 21.2411143],
      [48.9853913, 21.2407777],
      [48.9854177, 21.2407616],
      [48.9854036, 21.2407214],
    ],
    center: [48.9853858, 21.240871],
  },
  {
    type: 'Polygon',
    name: 'B1',
    id: 'B1',
    coordinates: [
      [48.9916547, 21.2483014],
      [48.9913493, 21.2485012],
      [48.9914188, 21.2487332],
      [48.9914769, 21.2486917],
      [48.9914936, 21.2487668],
      [48.9915376, 21.2487279],
      [48.9915218, 21.2486595],
      [48.991718, 21.2485254],
      [48.9916547, 21.2483014],
    ],
    center: [48.9915229, 21.24845],
  },
  {
    type: 'Polygon',
    name: 'J9',
    id: 'J9',
    coordinates: [
      [48.6976956, 21.2333796],
      [48.6975637, 21.2332361],
      [48.6973371, 21.2337069],
      [48.6974681, 21.2338463],
      [48.6976956, 21.2333796],
    ],
    center: [48.6974853, 21.2335798],
  },
  {
    type: 'Polygon',
    name: 'J5B',
    id: 'J5B',
    coordinates: [
      [48.698489, 21.234902],
      [48.6981066, 21.2349824],
      [48.6981314, 21.2352211],
      [48.6985103, 21.2351326],
      [48.698489, 21.234902],
    ],
    center: [48.6982722, 21.2350253],
  },
  {
    type: 'Polygon',
    name: 'J7',
    id: 'J7',
    coordinates: [
      [48.6985917, 21.2343146],
      [48.6980624, 21.2337164],
      [48.6980447, 21.233754],
      [48.6978906, 21.2336091],
      [48.6976888, 21.234049],
      [48.6978287, 21.2341912],
      [48.6975826, 21.2346713],
      [48.697464, 21.2345479],
      [48.6972498, 21.2350146],
      [48.6975206, 21.2353016],
      [48.6978782, 21.2345157],
      [48.6981084, 21.2347759],
      [48.698142, 21.2347491],
      [48.6982004, 21.2348188],
      [48.6982199, 21.2347839],
      [48.6982553, 21.2348242],
      [48.6982713, 21.2347947],
      [48.6983191, 21.2348403],
      [48.6983403, 21.2348161],
      [48.6983739, 21.234851],
      [48.6985917, 21.2343146],
    ],
    center: [48.6980757, 21.2341751],
  },
  {
    type: 'Polygon',
    name: 'J9C',
    id: 'J9C',
    coordinates: [
      [48.6973254, 21.2337688],
      [48.6969474, 21.2338654],
      [48.6969705, 21.2340947],
      [48.6973502, 21.2339955],
      [48.6973254, 21.2337688],
    ],
    center: [48.6971312, 21.2338721],
  },
  {
    type: 'Polygon',
    name: 'J9D',
    id: 'J9D',
    coordinates: [
      [48.696899, 21.2338717],
      [48.6965299, 21.2339562],
      [48.696552, 21.2341829],
      [48.6969211, 21.234097],
      [48.696899, 21.2338717],
    ],
    center: [48.6967028, 21.2339928],
  },
  {
    type: 'Polygon',
    name: 'J9B',
    id: 'J9B',
    coordinates: [
      [48.6977585, 21.2343264],
      [48.6974487, 21.2339884],
      [48.6973602, 21.2341842],
      [48.6976665, 21.2345114],
      [48.6977585, 21.2343264],
    ],
    center: [48.6975473, 21.2341779],
  },
  {
    type: 'Polygon',
    name: 'J5D',
    id: 'J5D',
    coordinates: [
      [48.6978134, 21.2346696],
      [48.6977249, 21.2348573],
      [48.6980205, 21.2352087],
      [48.6981161, 21.2350129],
      [48.6978134, 21.2346696],
    ],
    center: [48.6978739, 21.2348564],
  },
  {
    type: 'Polygon',
    name: 'J5C',
    id: 'J5C',
    coordinates: [
      [48.6980471, 21.2351684],
      [48.6978223, 21.2356244],
      [48.6979426, 21.2357585],
      [48.6981639, 21.2352945],
      [48.6980471, 21.2351684],
    ],
    center: [48.6979695, 21.2354304],
  },
  {
    type: 'Polygon',
    name: 'J5A',
    id: 'J5A',
    coordinates: [
      [48.6989424, 21.2348144],
      [48.6985582, 21.2349029],
      [48.6985865, 21.2351362],
      [48.6989654, 21.235045],
      [48.6989424, 21.2348144],
    ],
    center: [48.6987554, 21.2349093],
  },
  {
    type: 'Polygon',
    name: 'J17',
    id: 'J17',
    coordinates: [
      [48.6965266, 21.231665],
      [48.6962053, 21.2317387],
      [48.6962327, 21.2319909],
      [48.6965487, 21.2319117],
      [48.6965266, 21.231665],
    ],
    center: [48.6963475, 21.2317643],
  },
  {
    type: 'Polygon',
    name: 'J15',
    id: 'J15',
    coordinates: [
      [48.6961309, 21.2310199],
      [48.6961548, 21.2312653],
      [48.69647, 21.2311929],
      [48.6964478, 21.2309448],
      [48.6961309, 21.2310199],
    ],
    center: [48.6962865, 21.2310549],
  },
  {
    type: 'Polygon',
    name: 'J19',
    id: 'J19',
    coordinates: [
      [48.696891, 21.2308418],
      [48.6965626, 21.2309209],
      [48.6965865, 21.2311596],
      [48.6969175, 21.2310818],
      [48.696891, 21.2308418],
    ],
    center: [48.6967282, 21.2309462],
  },
  {
    type: 'Polygon',
    name: 'J13',
    id: 'J13',
    coordinates: [
      [48.6965792, 21.2310886],
      [48.6964625, 21.231115],
      [48.6964693, 21.2311922],
      [48.6963716, 21.2312166],
      [48.6963866, 21.2313789],
      [48.6967088, 21.2313038],
      [48.6966911, 21.2311335],
      [48.6965858, 21.2311589],
      [48.6965792, 21.2310886],
    ],
    center: [48.6965334, 21.2311595],
  },
  {
    type: 'Polygon',
    name: 'G6',
    id: 'G6',
    coordinates: [
      [48.7168762, 21.2555359],
      [48.7167709, 21.2555601],
      [48.7168213, 21.2561153],
      [48.7166868, 21.2561421],
      [48.7166956, 21.2562185],
      [48.7169346, 21.2561676],
      [48.7168762, 21.2555359],
    ],
    center: [48.7168559, 21.2557852],
  },
];

// export const RESTAURANTS = [
//     { type: 'Restaurant', name: 'Jedáleň Němcovej 1', coordinates: [48.7335614, 21.2413591] },
//     { type: 'Restaurant', name: 'Jedáleň Jedlíkova 7', coordinates: [48.6982904, 21.2339807] },
//     { type: 'Restaurant', name: 'Bistro Urbánkova 2', coordinates: [48.73516, 21.2460752] },
//     { type: 'Restaurant', name: 'Bistro ZP, Němcovej 9', coordinates: [48.7317875, 21.2443554] },
//     { type: 'Restaurant', name: 'Pizzéria Forte Jedlíkova 7', coordinates: [48.6980638, 21.2338681] },
//     { type: 'Caffee', name: 'Libresso, Němcovej 7', coordinates: [48.7315028, 21.2439016] },
//     { type: 'Restaurant', name: 'Jedáleň Budovateľská 31, Prešov', coordinates: [48.9850333, 21.2422471] },
// ];

export const MARKERS = [
  {
    type: 'Restaurant',
    name: 'Jedáleň Němcovej 1',
    idCanteen: 793836,
    coordinates: [48.7335614, 21.2413591],
  },
  {
    type: 'Restaurant',
    name: 'Jedáleň Jedlíkova 7',
    idCanteen: 793850,
    coordinates: [48.6982904, 21.2339807],
  },
  {
    type: 'Restaurant',
    name: 'Bistro Urbánkova 2',
    idCanteen: 793852,
    coordinates: [48.73516, 21.2460752],
  },
  {
    type: 'Restaurant',
    name: 'Bistro ZP, Němcovej 9',
    idCanteen: 793854,
    coordinates: [48.7317875, 21.2443554],
  },
  {
    type: 'Restaurant',
    name: 'Pizzéria Forte Jedlíkova 7',
    idCanteen: 793858,
    coordinates: [48.6980638, 21.2338681],
  },
  {
    type: 'Caffee',
    name: 'Libresso, Němcovej 7',
    idCanteen: 793860,
    coordinates: [48.7315028, 21.2439016],
  },
  {
    type: 'Restaurant',
    name: 'Jedáleň Budovateľská 31, Prešov',
    idCanteen: 793856,
    coordinates: [48.9850333, 21.2422471],
  },
  {
    type: 'SportHall',
    name: 'Telocvičňa Urbánkova',
    coordinates: [48.73500290227571, 21.24564890391553],
    scheduleLink:
      'https://ktv.tuke.sk/wps/portal/ktv/vyucba/rozvrh/telocvicna-urbankova',
  },
  {
    type: 'SportHall',
    name: 'Telocvičňa Boženy Nemcovej',
    coordinates: [48.733754650506235, 21.24200771210524],
    scheduleLink:
      'https://ktv.tuke.sk/wps/portal/ktv/vyucba/rozvrh/telocvicna-bozeny-nemcovej',
  },
  {
    type: 'SportHall',
    name: 'Telocvičňa Vysokoškolská',
    coordinates: [48.73429977468583, 21.245395686491914],
    scheduleLink:
      'https://ktv.tuke.sk/wps/portal/ktv/vyucba/rozvrh/telocvicna-vysokoskolska',
  },
  {
    type: 'SportHall',
    name: 'Telocvičňa Jedlíkova T1',
    coordinates: [48.69818664584028, 21.234496631152354],
    scheduleLink:
      'https://ktv.tuke.sk/wps/portal/ktv/vyucba/rozvrh/telocvicna-jedlikova-t1',
  },
  {
    type: 'SportHall',
    name: 'Telocvičňa Jedlíkova T2',
    coordinates: [48.69825650560868, 21.23424346157727],
    scheduleLink:
      'https://ktv.tuke.sk/wps/portal/ktv/vyucba/rozvrh/telocvicna-jedlikova-t2',
  },
  {
    type: 'SportHall',
    name: 'Telocvičňa Jedlíkova T3',
    coordinates: [48.69845246889408, 21.234414116333124],
    scheduleLink:
      'https://ktv.tuke.sk/wps/portal/ktv/vyucba/rozvrh/telocvicna-jedlikova-t3',
  },
  {
    type: 'SportHall',
    name: 'Telocvičňa Jedlíkova T4',
    coordinates: [48.6983513024247, 21.234676182234328],
    scheduleLink:
      'https://ktv.tuke.sk/wps/portal/ktv/vyucba/rozvrh/telocvicna-jedlikova-t4',
  },
  // parking
  {
    type: 'Parking',
    coordinates: [48.733703625174904, 21.243446837304862],
  },
  {
    type: 'Parking',
    coordinates: [48.73249242627236, 21.242364943479707],
  },
  {
    type: 'Parking',
    coordinates: [48.73187126015337, 21.24282946692855],
  },
  {
    type: 'Parking',
    coordinates: [48.73446126726208, 21.244632768887687],
  },
  {
    type: 'Parking',
    coordinates: [48.73447516546984, 21.24534742160615],
  },
  {
    type: 'Parking',
    coordinates: [48.735721323053525, 21.244531263814157],
  },
  {
    type: 'Parking',
    coordinates: [48.735597363259856, 21.24620123075406],
  },
  {
    type: 'Parking',
    coordinates: [48.73130494933208, 21.24499961915445],
  },
  {
    type: 'Parking',
    coordinates: [48.7309702342163, 21.245751482705774],
  },
  {
    type: 'Parking',
    coordinates: [48.732384216629086, 21.24694029830499],
  },
  {
    type: 'Parking',
    coordinates: [48.6973083, 21.2331447],
  },
  {
    type: 'Parking',
    coordinates: [48.6978183, 21.2332119],
  },
  {
    type: 'Parking',
    coordinates: [48.6983353, 21.2334614],
  },

  {
    type: 'Parking',
    coordinates: [48.6989992, 21.2344483],
  },
  {
    type: 'Parking',
    coordinates: [48.6986433, 21.2353039],
  },
  {
    type: 'Parking',
    coordinates: [48.6969881, 21.2347058],
  },
  {
    type: 'Parking',
    coordinates: [48.6960108, 21.2321308],
  },
  {
    type: 'Parking',
    coordinates: [48.6968783, 21.2330483],
  },
  {
    type: 'Parking',
    coordinates: [48.73327395025169, 21.271289200046358],
  },
  {
    type: 'Parking',
    coordinates: [48.733877899646934, 21.272706464565754],
  },
  {
    type: 'Parking',
    coordinates: [48.73564811525649, 21.270863517872655],
  },

  {
    type: 'TramStop',
    name: 'Botanická záhrada',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Botanick%C3%A1%20z%C3%A1hrada',
    transportLines: ['4', 'R4'],
    coordinates: [48.7343675, 21.2407817],
  },
  {
    type: 'TramStop',
    name: 'Technická univerzita',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Technick%C3%A1%20univerzita',
    coordinates: [48.7306411, 21.2441939],
  },
  {
    type: 'TramStop',
    name: 'Technická univerzita',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Technick%C3%A1%20univerzita',
    transportLines: ['4', 'R4'],
    coordinates: [48.7300997, 21.2448347],
  },
  {
    type: 'TramStop',
    name: 'Okresný úrad',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Okresný%20úrad',
    transportLines: ['1', '2', '6', 'R3'],
    coordinates: [48.7351922, 21.2469839],
  },
  {
    type: 'TramStop',
    name: 'Okresný úrad',
    transportLines: ['1', '2', '6', 'R3'],
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Okresný%20úrad',
    coordinates: [48.7355514, 21.2468392],
  },
  {
    type: 'TramStop',
    name: 'Poliklinika Sever',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Poliklinika%20Sever',
    transportLines: ['1', '2', '6', 'R3'],
    coordinates: [48.7320911, 21.2495481],
  },
  {
    type: 'TramStop',
    name: 'Poliklinika Sever',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Poliklinika%20Sever',
    transportLines: ['1', '2', '6', 'R3'],
    coordinates: [48.7319972, 21.2497494],
  },
  {
    type: 'TramStop',
    name: 'Obchodné centrum Optima',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=OC%20Optima',
    transportLines: ['R1', 'R2', 'R3', 'R6', 'R7'],
    coordinates: [48.6966083, 21.2355389],
  },
  {
    type: 'TramStop',
    name: 'Obchodné centrum Optima',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=OC%20Optima',
    transportLines: ['5', 'R1', 'R2', 'R3', 'R4', 'R6', 'R7'],
    coordinates: [48.6968739, 21.2359681],
  },
  {
    type: 'TramStop',
    name: 'Moldavská, obchodné centrá',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Moldavská%20obchodné%20centrá',
    transportLines: ['R1', 'R2', 'R3', 'R6', 'R7'],
    coordinates: [48.6997369, 21.2383328],
  },
  {
    type: 'TramStop',
    name: 'Moldavská, obchodné centrá',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Moldavská%20obchodné%20centrá',
    transportLines: ['5', 'R1', 'R2', 'R3', 'R4', 'R6', 'R7'],
    coordinates: [48.6999017, 21.2386011],
  },

  {
    type: 'BusStop',
    name: 'Botanická záhrada',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Botanick%C3%A1%20z%C3%A1hrada',
    transportLines: ['12', '14', '19', 'N2'],
    coordinates: [48.7341081, 21.2405536],
  },
  {
    type: 'BusStop',
    name: 'Botanická záhrada',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Botanick%C3%A1%20z%C3%A1hrada',
    coordinates: [48.7342214, 21.2390569],
  },
  {
    type: 'BusStop',
    name: 'Botanická záhrada',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Botanick%C3%A1%20z%C3%A1hrada',
    coordinates: [48.7350617, 21.2417392],
  },
  {
    type: 'BusStop',
    name: 'Technická univerzita',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Technick%C3%A1%20univerzita',
    coordinates: [48.7309094, 21.2438258],
  },
  {
    type: 'BusStop',
    name: 'Technická univerzita',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Technick%C3%A1%20univerzita',
    coordinates: [48.7305858, 21.2440431],
    transportLines: ['12', '14', '19', 'N2'],
  },
  {
    type: 'BusStop',
    name: 'Technická univerzita',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Technick%C3%A1%20univerzita',
    coordinates: [48.7297092, 21.2445261],
  },
  {
    type: 'BusStop',
    name: 'Technická univerzita',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Technick%C3%A1%20univerzita',
    coordinates: [48.7294367, 21.2441772],
  },
  {
    type: 'BusStop',
    name: 'Okresný úrad',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Okresný%20úrad',
    coordinates: [48.7365008, 21.2453281],
  },
  {
    type: 'BusStop',
    name: 'Džungľa',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Džungľa',
    coordinates: [48.7354139, 21.2697908],
  },
  {
    type: 'BusStop',
    name: 'Džungľa',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Džungľa',
    coordinates: [48.7359003, 21.2696136],
  },
  {
    type: 'BusStop',
    name: 'Pod Furčou',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Pod%20Furčou',
    coordinates: [48.7358206, 21.2737203],
  },
  {
    type: 'BusStop',
    name: 'Pod Furčou',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Pod%20Furčou',
    coordinates: [48.7359161, 21.2741172],
  },
  {
    type: 'BusStop',
    name: 'Vo výmoli',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Vo%20výmoli',
    coordinates: [48.7324664, 21.2760858],
  },
  {
    type: 'BusStop',
    name: 'Vo výmoli',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Vo%20výmoli',
    coordinates: [48.7328239, 21.276255],
  },
  {
    type: 'BusStop',
    name: 'Luník VIII',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Luník%20VIII',
    coordinates: [48.6973094, 21.2312797],
  },
  {
    type: 'BusStop',
    name: 'Luník VIII',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=Luník%20VIII',
    coordinates: [48.6971678, 21.2314942],
  },
  {
    type: 'BusStop',
    name: 'Obchodné centrum Optima',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=OC%20Optima',
    coordinates: [48.6968828, 21.23559],
  },
  {
    type: 'BusStop',
    name: 'Obchodné centrum Optima',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=OC%20Optima',
    coordinates: [48.6962258, 21.2365958],
  },
  {
    type: 'BusStop',
    name: 'Obchodné centrum Optima',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=OC%20Optima',
    coordinates: [48.6967994, 21.2371967],
  },
  {
    type: 'BusStop',
    name: 'Obchodné centrum Optima',
    cpLink: 'https://cp.hnonline.sk/kosice/spojenie/?f=OC%20Optima',
    coordinates: [48.6967411, 21.2376311],
  },
  {
    type: 'BusStop',
    name: 'Moldavská, obchodné centrá',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Moldavská%20obchodné%20centrá',
    coordinates: [48.6994214, 21.2378108],
  },
  {
    type: 'BusStop',
    name: 'Moldavská, obchodné centrá',
    cpLink:
      'https://cp.hnonline.sk/kosice/spojenie/?f=Moldavská%20obchodné%20centrá',
    coordinates: [48.7006942, 21.2376928],
  },

  {
    type: 'TicketMachine',
    cardPay: true,
    coordinates: [48.7344117, 21.2408969],
  },
  {
    type: 'TicketMachine',
    cardPay: true,
    coordinates: [48.7299528, 21.244965],
  },
  {
    type: 'TicketMachine',
    cardPay: true,
    coordinates: [48.7354956, 21.2468822],
  },
];
