import { FC, useState } from 'react'
import styled from 'styled-components';

const StarIcon = styled.span<{ filled: boolean }>`
  font-size: 2em;
  cursor: pointer;
  color: ${props => (props.filled ? '#eab308' : '#ccc')};

  &:hover {
    color: #eab308;
  }
`;

interface StarPickerProps {
  totalStars: number;
  onChange?: (rating: number) => void;
}

const StarPicker: FC<StarPickerProps> = ({ totalStars, onChange }) => {
  const [rating, setRating] = useState<number>(0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleStarClick = (starIndex: number) => {
    setRating(starIndex + 1);
    if (onChange) onChange(starIndex + 1);
  };

  const handleStarHover = (starIndex: number) => {
    setHoveredRating(starIndex + 1);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => (
        <StarIcon
          key={index}
          filled={index < (hoveredRating || rating)}
          onClick={() => handleStarClick(index)}
          onMouseEnter={() => handleStarHover(index)}
          onMouseLeave={handleMouseLeave}
        >
          ★
        </StarIcon>
      ))}
    </div>
  )
}

export default StarPicker;

StarPicker.defaultProps = {
  onChange: undefined
};