import React, { type ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant: 'primary' | 'secondary' | 'ghost' | 'outline' | 'icon' | 'circular';
  icon?: React.ReactNode;
  align?: string;
  children?: React.ReactNode;
}

const ButtonWrapper = styled.button<{ variant: string, align: string }>`
  font-size: 1em;
  padding: 0.5em 1em 0.55em 1em;
  border-radius: ${({ theme }) => theme.rounded.medium};
  cursor: ${({ disabled }) => (disabled !== undefined && disabled ? 'not-allowed' : 'pointer')};
  border: none;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  white-space: nowrap;

  /* Common styles for all buttons */
  ${({ theme: buttonTheme }) => css`
    &:disabled {
      cursor: not-allowed;
      background-color: ${buttonTheme.colors.detail};
      color: ${buttonTheme.colors.text};
      opacity: 0.5;
    }

    &:hover:disabled,
    &:focus:disabled,
    &:active:disabled {
      opacity: 0.3;
    }
  `}

  /* Primary Button */
  ${({ variant, theme: buttonTheme }) => variant === 'primary' && css`
      font-weight: 500;
      background-color: ${buttonTheme.colors.primary};
      color: ${buttonTheme.colors.background};

      &:hover,
      &:active {
        opacity: 0.9;
      }
    `}
  
  /* Secondary Button */
  ${({ variant, theme: buttonTheme }) => variant === 'secondary' && css`
      background-color: ${buttonTheme.colors.secondary};
      color: ${buttonTheme.colors.text};
    `}
  
  /* Ghost Button */
  ${({ variant, theme: buttonTheme }) => variant === 'ghost' && css`
      background-color: transparent;
      color: ${buttonTheme.colors.text};

      &:hover,
      &:active {
        background-color: ${buttonTheme.colors.secondary};
      }
    `}

  /* Icon Button */
  ${({ variant, theme: buttonTheme }) => variant === 'icon' && css`
      background-color: transparent;
      color: ${buttonTheme.colors.icons};
      padding: 0.5em;

      &:hover,
      &:active {
        background-color: ${buttonTheme.colors.secondary};
      }
    `}
  
  /* Outline Button */
  ${({ variant, theme: buttonTheme }) => variant === 'outline' && css`
      background-color: transparent;
      color: ${buttonTheme.colors.text};
      border: ${buttonTheme.border};

      &:hover,
      &:active {
        background-color: ${buttonTheme.colors.secondary};
      }
    `}
  
  /* Circular Button */
  ${({ variant, theme: buttonTheme }) => variant === 'circular' && css`
      background-color: ${buttonTheme.colors.secondary};
      color: ${buttonTheme.colors.detail};
      padding: 0.25em;
      border-radius: ${buttonTheme.rounded.full};

      &:hover,
      &:active {
        background-color: ${buttonTheme.colors.secondary80};
        color: ${buttonTheme.colors.text};
      }
    `}

  /* Small Button */
  ${({ variant, theme: buttonTheme }) => variant === 'small' && css`
      background-color: ${buttonTheme.colors.background};
      color: ${buttonTheme.colors.text};
      border: ${buttonTheme.border};
      border-radius: ${buttonTheme.rounded.full};
      font-size: 0.75em;
      padding: 0.25em 0.875em;
    `}
`;

const Button: React.FC<ButtonProps> = ({
  variant,
  icon,
  children,
  align,
  ...rest
}) => (
  <ButtonWrapper align={align ?? 'center'} variant={variant} type="button" {...rest}>
    {icon !== undefined && icon}
    {children}
  </ButtonWrapper>
);

export default Button;

Button.defaultProps = {
  icon: null,
  children: null,
  align: 'center',
};
