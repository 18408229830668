import React from 'react';
import {
  ApartmentIcon,
  DoorFrontIcon,
  PeopleIcon,
  PersonI,
} from '../components/Icons';

export const searchOptions = [
  {
    title: 'employees',
    icon: <PersonI size={20} color="gray" />,
    id: 0,
    selected: false,
    color: '#A7A9AC',
    filterType: 'EMPLOYEE',
  },
  {
    title: 'rooms',
    icon: <DoorFrontIcon size={20} color="gray" />,
    id: 1,
    selected: false,
    color: '#A7A9AC',
    filterType: 'ROOM',
  },
  {
    title: 'building',
    icon: <ApartmentIcon size={20} color="gray" />,
    id: 2,
    selected: false,
    color: '#A7A9AC',
    filterType: 'BUILDING',
  },
  {
    title: 'departments',
    icon: <PeopleIcon size={20} color="gray" />,
    id: 3,
    selected: false,
    color: '#A7A9AC',
    filterType: 'DEPARTMENT',
  },
];
