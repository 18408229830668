import { useQuery } from "@tanstack/react-query";
import { getUrlFriendlyName } from "../../helper/util";
import { StudentAccount, StudentCourseLesson } from "./use-account";
import { Assignment } from "./use-assignment";
import { Semester } from "./use-semester";
import useAuth from "../use-auth";

export interface Course {
  id?: number;
  studentAccount: StudentAccount;
  name: string;
  color: string;
  lessons: StudentCourseLesson[];
  semester: string;
}

export interface ArchivedCourse extends Course {
  maxPoints: number;
  points: number;
}

const baseURL = "";

export const useCourses = () => {
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: ["courses"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/account/courses`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load courses.");
      }

      return (await response.json()) as Course[];
    },
    enabled: isAuthenticated(),
  });
};

export const useCurrentCourses = () => {
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: ["courses"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/account/courses`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load courses.");
      }

      const semesterResponse = await fetch(`${baseURL}/api/schedule/semester`);

      if (!semesterResponse.ok) {
        const error = await semesterResponse.json();
        // UNAUTHORIZED
        if (semesterResponse.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load schedule.");
      }

      const semester: Semester = await semesterResponse.json();
      const courses: Course[] = await response.json();

      const currentCourses = courses.filter(
        (course) => course.semester === semester.name
      );

      return currentCourses;
    },
    enabled: isAuthenticated(),
  });
};

export const useCourseId = (id: string) =>
  useQuery({
    queryKey: ["course"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/account/course/${id}`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load courses.");
      }

      return (await response.json()) as Course;
    },
  });

export const useCourseName = (name: string) =>
  useQuery({
    queryKey: ["course"],
    queryFn: async () => {
      let courses: Course[] = [];
      const response = await fetch(`${baseURL}/api/account/courses`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load courses.");
      }

      courses = await response.json();
      const course: Course | undefined = courses.find(
        (c) => getUrlFriendlyName(c.name) === name
      );

      if (course) return course;
      throw new Error("This course doesn't exist.");
    },
  });

export const useCourseColor = (title: string) =>
  useQuery({
    queryKey: ["courseColor"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/account/courses`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load schedule.");
      }

      const courses: Course[] = await response.json();

      const course: Course | undefined = courses.find((c) => c.name === title);

      if (course) return course.color;
      return "#FFFFFF";
    },
  });

export const useArchivedCourses = () =>
  useQuery({
    queryKey: ["archivedCourses"],
    queryFn: async () => {
      const acResponse = await fetch(`${baseURL}/api/account/courses/archived`);

      if (!acResponse.ok) {
        const error = await acResponse.json();
        // UNAUTHORIZED
        if (acResponse.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load archived courses.");
      }

      const cResponse = await fetch(`${baseURL}/api/account/courses`);

      if (!cResponse.ok) {
        const error = await cResponse.json();
        // UNAUTHORIZED
        if (cResponse.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load courses.");
      }

      const evaluations: Assignment[] = await acResponse.json();
      const courses: Course[] = await cResponse.json();

      const matched: ArchivedCourse[] = [];

      evaluations.forEach((ac) => {
        courses.forEach((c) => {
          if (ac.name.includes(c.name)) {
            const archivedCourse: ArchivedCourse = {
              ...c,
              maxPoints: ac.maxPoints,
              points: ac.points,
            };
            matched.push(archivedCourse);
          }
        });
      });

      return matched;
    },
  });
