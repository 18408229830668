import { useQuery } from "@tanstack/react-query";

export interface levelApiType {
  department: {
    acronym: string;
    color: string;
    idDepartment: 0;
    name: string;
    nameEn: string;
    web: string;
  };
  idDepartment: 0;
  idRoom: 0;
  idRoomDepartment: 0;
  room: {
    building: {
      address: string;
      idBuilding: 0;
      latitude: 0;
      levels: any;
      longitude: 0;
      name: string;
    };
    idBuilding: 0;
    idRoom: 0;
    idRoomType: 0;
    level: string;
    name: string;
    nameEn: string;
    number: string;
    roomType: {
      idRoomType: 0;
      name: string;
      nameEn: string;
      pictogram: string;
    };
  };
}

const baseURL = "";

export const useLevel = (building: string, level: string) =>
  useQuery({
    queryKey: ["level"],
    queryFn: async () => {
      const response = await fetch(
        `${baseURL}/api/building/${building}/${level.charAt(0)}`
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to load feedback.");
      }

      return (await response.json()) as levelApiType;
    },
  });
