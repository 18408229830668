import { useQuery } from "@tanstack/react-query";
import { Room } from "./use-room";

export interface Department {
  department: DepartmentData;
  employees: Employee[];
  rooms: Room[];
  topLevelDepartment: DepartmentData;
}

interface DepartmentData {
  acronym: string;
  color: string;
  idDepartment: number;
  name: string;
  nameEn: string;
  web: string;
}

interface Employee {
  fullName: string;
  idEmployee: number;
  position: string;
  positionEn: string;
}

const baseURL = "";

export const useDepartment = (idDepartment: string) =>
  useQuery({
    queryKey: ["department"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/department/${idDepartment}`);

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to load feedback.");
      }

      return (await response.json()) as Department;
    },
  });