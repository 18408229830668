import React, { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '../components/Flex';
import { H1, Text } from '../components/Typography';

const AuthPage: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'authentication' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.pathname + window.location.search;
        await fetch(url);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Column justifyContent="center" alignItems="center">
      <H1 fontWeight={600}>{t('authenticating')}</H1>
      <Text>{t('loggingIn')}</Text>
    </Column>
  );
};

export default AuthPage;
