import styled from 'styled-components';

export const Card = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  width: 100%;
  padding: 0.75em 1em;
  margin-top: 0.5em;
  background-color: ${({ theme, background }) => background ?? theme.colors.background};
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.rounded.large};
  box-shadow: ${({ theme }) => theme.shadow.base};
`;
