import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enVersion from "./enVersion.json";
import skVersion from "./skVersion.json";

const resources = {
  en: {
    translation: enVersion,
  },
  sk: {
    translation: skVersion,
  },
};

let lang = localStorage.getItem("lang");
if (lang === null) {
  localStorage.setItem("lang", "sk");
  lang = "sk";
}

i18n.use(initReactI18next).init({
  resources,
  lng: lang, // sk or en
  fallbackLng: "sk",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
