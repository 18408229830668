import React, { type FC } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Column } from "../Flex";
import Button from "../Button";
import { Feedback, useSendFeedback } from "../../hooks/api/use-feedback";
import useAuth from "../../hooks/use-auth";
import { H3, TextSmall } from "../Typography";

interface ReportBugFormProps {
  onSubmitCallback: () => void;
}

const ReportBugForm: FC<ReportBugFormProps> = ({ onSubmitCallback }) => {
  const { user, isAuthenticated, login } = useAuth();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const feedbackMutation = useSendFeedback();

  const placeholderTranslation: string = t("forms.typeSomething");

  const descriptionValidation = {
    required: {
      value: true,
      message: t("forms.required"),
    },
    minLength: {
      value: 3,
      message: t("forms.tooShort"),
    },
    maxLength: {
      value: 255,
      message: t("forms.tooLong"),
    },
  };

  const onSubmit = (data: any, e?: any) => {
    e.preventDefault();

    if (!isAuthenticated() || !user) {
      login();
      return;
    }

    const newFeedback: Feedback = {
      createdOn: new Date(),
      type: "bug",
      content: data.description
    };

    feedbackMutation.mutate(newFeedback,
      {
        onSuccess: () => {
          toast.success(t("forms.toastSuccessSend"));
          onSubmitCallback();
        },
        onError: () => {
          toast.error(t("toastError"));
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Column style={{ gap: "1em" }}>
        <H3 fontWeight={600}>{t("forms.reportBug.title")}</H3>
        <TextSmall>{t("forms.reportBug.description")}</TextSmall>

        <textarea minLength={3} maxLength={255} placeholder={placeholderTranslation} {...register("description", descriptionValidation)} />

        <Button type="submit" variant="primary" style={{ width: "100%" }}>
          {t("send")}
        </Button>
      </Column>
    </form>
  );
};

export default ReportBugForm;
