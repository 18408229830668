import React, { useState, type FC } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  CalendarMonth,
  Delete,
  Edit,
  Grade,
  Share,
} from '@styled-icons/material-rounded';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { H1, H2, H3, Text, TextSmall, TextTiny } from './Typography';
import { Column, Row } from './Flex';
import Overlay from './Overlay';
import Margin from './Margin';
import Icon from './Icon';
import { ClickableContainer, Container } from './Container';
import Slider from './input/Slider';
import Button from './Button';
import {
  useDeleteAssignment,
  useEditAssignment,
  type Assignment as AProps,
} from '../hooks/api/use-assignment';
import { getUrlFriendlyName } from '../helper/util';
import EditAssignmentForm from './forms/EditAssignmentForm';
import { BannerImage } from './AddAssignment';
import banner from '../assets/images/add_assignment_banner.jpg';
import ConfirmationModal from './Confirm';

interface AssignmentProps {
  clickable?: boolean;
  assignment: AProps;
}

const disappear = keyframes`
    0% {
      opacity: 100;
    }
    100% {
      opacity: 0;
    }
  `;

const AssignmentCard = styled.div<{ clickable: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 0.5em 1em 0.5em 0.5em;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border-radius: ${({ theme }) => theme.rounded.large};
  background-color: ${({ clickable, theme }) => (clickable ? theme.colors.background : theme.colors.secondary)};

  &:hover {
    background-color: ${({ clickable, theme }) => clickable && theme.colors.secondary};
  }
`;

const OptionTab = styled(ClickableContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  overflow: hidden;
`;

const RemoveTab = styled(OptionTab)`
  color: ${({ theme }) => theme.colors.destructive};
  width: 100%;
`;

const Tooltip = styled(TextTiny)`
  position: absolute;
  bottom: -1.5em;
  width: 100%;
  text-align: center;
  animation: 1s ${disappear} 2s forwards;
`;

const AssignmentTitle = styled(Text)`
  overflow: hidden;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  vertical-align: middle;
  height: 100%;
  align-items: center;
`;

const WeekBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 4em;
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.rounded.large};
  background-color: ${({ theme }) => theme.colors.background};
`;

const DataBox = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.75em;
  align-items: center;
  width: fit-content;
  padding: 0.25em 0.75em;
  gap: 0.75em;
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.rounded.medium};
  background-color: ${({ theme }) => theme.colors.background};

  div {
    gap: 0.5em;
    padding-right: 0.75em;
    border-right: ${({ theme }) => theme.border};
  }

  div:last-child {
    padding-right: 0;
    border: none;
  }
`;

const Assignment: FC<AssignmentProps> = ({ clickable = false, assignment }) => {
  const { t } = useTranslation();
  const { id, name, points, maxPoints, dueWeek, studentCourse } = assignment;

  const [isOverlayShown, setIsOverlayShown] = useState<boolean>(false);
  const [isEditOverlayShown, setIsEditOverlayShown] = useState<boolean>(false);
  const [isConfirmationShown, setIsConfirmationShown] = useState<boolean>(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);
  const [showRemoveTooltip, setShowRemoveTooltip] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number>(points);

  const openOverlay = () => {
    if (!isOverlayShown) setIsOverlayShown(true);
  };

  const editMutation = useEditAssignment();
  const deleteMutation = useDeleteAssignment();

  const editAssignment = () => {
    const editedAssignment: AProps = {
      ...assignment,
      points: sliderValue,
    };

    editMutation.mutate({
      assignmentId: id ?? 0,
      assignment: editedAssignment,
    });
    setIsOverlayShown(false);
  };

  const handleRemove = () => {
    try {
      if (id) {
        deleteMutation.mutate(id);
        toast.success(t('assignment.toastSuccessDelete'));
        setIsOverlayShown(false);
      }
    } catch (e: any) {
      toast.error(t('toastError'));
      console.error('Error deleting assignment:', e);
    }
  };

  const copyLink = () => {
    const BASE_URL = process.env.REACT_APP_TUNA_URL;
    const url = `${BASE_URL}/course/${getUrlFriendlyName(studentCourse.name)}?share=${id}`;
    const text = t('assignment.shareText', { courseName: studentCourse.name, name, dueWeek });

    const shareData: ShareData = { url, text };

    if (navigator.share && navigator.canShare(shareData)) {
      navigator.share(shareData).catch((e: any) => {
        console.error(`Assignment couldn't be shared: ${e}`);
      });
    } else {
      navigator.clipboard.writeText(url).catch((e: any) => {
        console.error(`Assignment couldn't be shared: ${e}`);
      });
      setShowCopyTooltip(true);
    }
  };

  return (
    <>
      <AssignmentCard
        clickable={clickable}
        onClick={clickable ? openOverlay : undefined}
      >
        <Row style={{ gap: '1em' }} alignItems="center">
          {dueWeek < 13 ? (
            <WeekBox>
              <H2 fontWeight={600} style={{ letterSpacing: '0.05em' }}>
                {dueWeek === 0
                  ? '???'
                  : `${dueWeek.toString().padStart(2, '0')}.`}
              </H2>
              <TextTiny>{t('week')}</TextTiny>
            </WeekBox>
          ) : (
            <WeekBox>
              <Text fontWeight={500}>{t('assignment.examPeriod1')}</Text>
              <Text fontWeight={500}>{t('assignment.examPeriod2')}</Text>
            </WeekBox>
          )}
          <Row alignItems="center" justifyContent="space-between">
            <AssignmentTitle fontWeight={500}>{name}</AssignmentTitle>
            <Row
              alignItems="baseline"
              style={{ gap: '0.4em', width: 'fit-content' }}
            >
              <H2 fontWeight={600}>{points}</H2>
              <TextSmall fontWeight={500} color="detail">
                /{maxPoints}
              </TextSmall>
            </Row>
          </Row>
        </Row>
      </AssignmentCard>

      <Overlay
        isOpen={isOverlayShown}
        onClose={() => {
          setIsOverlayShown(false);
        }}
      >
        <Margin direction="y" size={2}>
          <H1 fontWeight={600} align="center">
            {name}
          </H1>

          <DataBox>
            <Row justifyContent="center" alignItems="center">
              <Icon color="detail" size={24}>
                <CalendarMonth />
              </Icon>
              <Row alignItems="baseline">
                {/* eslint-disable-next-line no-nested-ternary */}
                {dueWeek < 13 ? (
                  dueWeek === 0 ? (
                    <TextSmall color="detail" fontWeight={600}>{t('weekNotSpecified')}</TextSmall>
                  ) : (
                    <>
                      <Text color="detail" fontWeight={600}>{dueWeek}.</Text>
                      <TextSmall color="detail" fontWeight={600}>{t('week')}</TextSmall>
                    </>
                  )
                ) : (
                  <TextSmall color="detail" fontWeight={600}>{t('exam')}</TextSmall>
                )}
              </Row>
            </Row>
            <Row justifyContent="center" alignItems="center">
              <Icon color="detail" size={24}>
                <Grade />
              </Icon>
              <Row alignItems="baseline">
                <Text color="detail" fontWeight={600}>
                  {points}/{maxPoints}
                </Text>
                <TextSmall color="detail" fontWeight={600}>{t('5+points')}</TextSmall>
              </Row>
            </Row>
          </DataBox>
        </Margin>

        <Row
          alignItems="center"
          justifyContent="space-between"
          style={{ gap: '0.75em' }}
        >
          <div style={{ position: 'relative', width: '100%' }}>
            <OptionTab onClick={copyLink}>
              <Icon color="text" size={32}>
                <Share />
              </Icon>
              <Text color="text">{t('share')}</Text>
            </OptionTab>
            {showCopyTooltip && (
              <Tooltip
                onAnimationEnd={() => {
                  setShowCopyTooltip(false);
                }}
              >
                {t('linkCopied')}
              </Tooltip>
            )}
          </div>

          <OptionTab
            onClick={() => {
              setIsOverlayShown(false);
              setIsEditOverlayShown(true);
            }}
          >
            <Icon color="text" size={32}>
              <Edit />
            </Icon>
            <Text color="text">{t('edit')}</Text>
          </OptionTab>

          <div style={{ position: 'relative', width: '100%' }}>
            <RemoveTab onClick={() => setIsConfirmationShown(true)}>
              <Icon color="inherit" size={32}>
                <Delete />
              </Icon>
              <Text color="inherit">{t('delete')}</Text>
            </RemoveTab>

            {showRemoveTooltip && (
              <Tooltip
                onAnimationEnd={() => {
                  setShowRemoveTooltip(false);
                }}
              >
                {t('assignment.holdFor2s')}
              </Tooltip>
            )}
          </div>
        </Row>

        <section>
          <Margin direction="y" size={1.5}>
            <Container>
              <H3 fontWeight={700}>{t('evaluate')}</H3>
              <Text color="detail">
                {t('assignment.evaluateDescription')}
              </Text>
              <Slider
                min={0}
                max={maxPoints}
                value={sliderValue > maxPoints ? maxPoints : sliderValue}
                onChange={(value) => {
                  setSliderValue(value);
                }}
              />
              <Button
                variant="primary"
                onClick={editAssignment}
                style={{ width: '100%' }}
              >
                {t('done')}
              </Button>
            </Container>
          </Margin>
        </section>
      </Overlay>

      <Overlay
        isOpen={isEditOverlayShown}
        onClose={() => {
          setIsEditOverlayShown(false);
        }}
      >
        <Column style={{ gap: '1em' }}>
          <BannerImage src={banner} />

          <EditAssignmentForm
            assignment={assignment}
            onSubmitCallback={() => {
              setIsEditOverlayShown(false);
            }}
          />
        </Column>
      </Overlay>

      <ConfirmationModal 
        isOpen={isConfirmationShown} 
        message={t('assignment.confirmDeletion')}
        onConfirm={handleRemove} 
        onCancel={() => { setIsConfirmationShown(false) }}
      />
    </>
  );
};

export default Assignment;

Assignment.defaultProps = {
  clickable: false,
};
