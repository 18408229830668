import React, { useEffect, type ChangeEvent, type FC } from 'react';
import styled from 'styled-components';
import ICAL from 'ical.js';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import {
  type StudentCourseLesson,
  useCreateSchedule,
} from '../hooks/api/use-account';
import lightTheme from '../theme/light';
import darkTheme from '../theme/dark';
import { Text, H3 } from './Typography';
import Margin from './Margin';
import Input from './input/FileInput';
import tutorial from '../assets/tutorial.gif';

const BannerImage = styled.img`
  width: 100%;
  margin-bottom: 1em;
  border-radius: ${({ theme }) => theme.rounded.large};
`;

const Warning = styled(Text)`
  margin: 0.25em 0;
  padding: 0.25em 0.5em;
  color: ${({ theme }) => theme.colors.destructive};
  font-weight: 500;
`;

const MaisLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`;

interface ScheduleUploadProps {
  edit?: boolean;
}

const ScheduleUpload: FC<ScheduleUploadProps> = ({ edit = false }) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isError } = useCreateSchedule();
  const currentTheme = localStorage.getItem('theme');
  const theme = currentTheme === 'light' ? lightTheme : darkTheme;
  const colors = Object.values(theme.courseColor);

  const getCourseName = (summary: string) => {
    //  get: Manažment softvérových projektov
    // from: Manažment softvérových projektov (26000636) - Cvičenie laboratórne
    const nameMatch = summary.match(/^(.*?)\s?\(/);
    return nameMatch ? nameMatch[1] : '';
  };

  const getRoom = (location: string) => {
    const roomMatch = location.match(/\(([^()]+)\)[^()]*$/);
    return roomMatch ? roomMatch[1] : '';
  };

  const readFileAsync = async (file: Blob) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      const file = event.target.files[0];
      if (!file) return;
  
      readFileAsync(file)
        .then((fileContent) => {
          const icalData: string = fileContent as string;
          const jcalData = ICAL.parse(icalData);
          const comp = new ICAL.Component(jcalData);
          const vevents = comp.getAllSubcomponents('vevent');
  
          const uniqueCourses = new Map<string, string>();
          vevents.forEach((e: any) => {
            const courseName: string = getCourseName(
              e.getFirstPropertyValue('summary'),
            );
            if (!uniqueCourses.has(courseName)) {
              const colorIndex = uniqueCourses.size % colors.length;
              uniqueCourses.set(courseName, colors[colorIndex]);
            }
          });
  
          const icalLessons: StudentCourseLesson[] = vevents
            .filter(
              (e: any) => e.getFirstPropertyValue('categories') === 'Rozvrh',
            )
            .map((e: any) => {
              const name = getCourseName(e.getFirstPropertyValue('summary'));
              const room = getRoom(e.getFirstPropertyValue('location'));
              const startDate: Date = e
                .getFirstPropertyValue('dtstart')
                .toJSDate();
  
              return {
                studentCourse: {
                  name,
                  color: uniqueCourses.get(name) ?? undefined,
                },
                room,
                startDate,
              };
            }, []);
  
          mutate(icalLessons);
          return vevents;
        })
        .catch((error) => {
          console.error('Error reading or parsing iCal file:', error);
        })
    };

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }

    if (isError) {
      toast.error(t('toastError'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError])

  return (
    <>
      <BannerImage src={tutorial} />
      <H3 fontWeight={600}>{edit ? t('_schedule.edit') : t('_schedule.set')}</H3>
      {edit && (
        <Warning>
          {t('_schedule.editWarning')}
        </Warning>
      )}
      <Text>
        {t('_schedule.uploadSteps.1')}
        <MaisLink
          href="https://student.tuke.sk/student/rozvrh.mais"
          target="_blank"
          rel="noreferrer"
        >
          student.tuke.sk
        </MaisLink>
        .<br />
        {t('_schedule.uploadSteps.2')}
        <br />
        {t('_schedule.uploadSteps.3')}
        <br />
        {t('_schedule.uploadSteps.4')}
      </Text>
      <Margin direction="y" size={0.75}>
        <Input
          name="scheduleInput"
          id="fileInput"
          type="file"
          accept=".ics"
          onUpload={handleFileUpload}
        />
      </Margin>
    </>
  );
};

export default ScheduleUpload;

ScheduleUpload.defaultProps = ({
  edit: false
})
