import { Lesson } from "../hooks/api/use-lesson";
import { Appointment } from "../components/Schedule";

export const getUrlFriendlyName = (name: string) => {
  // Replace spaces with dashes and normalize special characters
  const urlFriendlyString: string = name
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .normalize("NFD") // Normalize characters
    .replace(/[\u0300-\u036f]/g, ""); // Remove combining diacritical marks

  return urlFriendlyString;
};

export const getAcronym = (input: string) => {
  const words = input.split(" ");
  const acronym = words
    .map((word) => {
      if (word.length === 1) {
        return word.toLowerCase(); // Convert single-letter words to lowercase (e.g. "a", "v", "o")
      }
      return word.charAt(0).toUpperCase(); // Capitalize the first letter of non-single-letter words
    })
    .join("");
  return acronym;
};

export const AppointmentFactory = (
  lessons: Lesson[],
  semesterStartDate: Date
) => {
  if (!lessons || lessons.length === 0) return;
  const date: Date = semesterStartDate;
  const appointments: Appointment[] = [];

  for (let week = 0; week < 13; week += 1) {
    lessons.forEach((lesson) => {
      const startDate: Date = new Date(lesson.start);
      const endDate: Date = new Date(lesson.end);

      startDate.setDate(startDate.getDate() + 7 * week);
      endDate.setDate(endDate.getDate() + 7 * week);

      appointments.push({
        title: lesson.name,
        type: lesson.type,
        room: lesson.room,
        start: startDate,
        end: endDate,
      });
    });
    date.setDate(date.getDate() + 7);
  }

  return appointments;
};

export const decodeSatisfaction = (message: string) => {
  const ratingString = message.slice(0, 4);
  const regex = /^(\d+)\/(\d+)\*/;
  const match = ratingString.match(regex);

  if (!match) {
    return null;
  }

  const stars = parseInt(match[1], 10);
  const totalStars = parseInt(match[2], 10);
  const content = message.slice(4, message.length);

  return {
    stars,
    totalStars,
    content,
  };
};
