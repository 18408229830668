import React, { type FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface SkeletonProps {
  width: string;
  height: string;
}

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonContainer = styled.div<{ width: string; height: string }>`
  display: inline-block;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0;
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: ${(props) => (props.height ? props.height : '16px')};
`;

const SkeletonContent = styled.div`
  width: 100%;
  height: 100%;
  animation: ${loadingAnimation} 1.5s ease-in-out infinite;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
`;

const Skeleton: FC<SkeletonProps> = ({ width, height }) => (
    <SkeletonContainer width={width} height={height}>
      <SkeletonContent />
    </SkeletonContainer>
  );

export default Skeleton;
