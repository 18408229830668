import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore, OpenInNew, UnfoldMore } from '@styled-icons/material-rounded'
import Button from '../components/Button'
import { Container } from '../components/Container'
import { Row } from '../components/Flex'
import { StudentAccount, useUsers } from '../hooks/api/use-account'
import { Text, TextSmall } from "../components/Typography"
import { Feedback, useFeedback } from '../hooks/api/use-feedback'
import { useSemester } from '../hooks/api/use-semester'
import Badge from '../components/Badge'
import DropdownSelect from '../components/input/DropdownSelect'
import usePaging from '../hooks/use-paging'
import { decodeSatisfaction } from '../helper/util'

interface AdminPageProps {
  
}

const Table = styled.table`
    width: 100%;
    border: ${({ theme }) => theme.border};
    border-radius: ${({ theme }) => theme.rounded.medium};
    overflow-x: auto;
`

const TableCell = styled.td`
    padding: 0.25em 0.5em;
    border-top: ${({ theme }) => theme.border};

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 2em;
`

const TableHeaderCell = styled.th`
    padding: 0.25em 0.5em;
    text-align: left;
    color: ${({ theme }) => theme.colors.detail};
    font-weight: 500;
    font-size: 0.85em;
`

const SecondaryNavigation = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${({ theme }) => theme.border};
    padding-bottom: 0.5em;
    margin-bottom: 1em;
`

const NavigationTab = styled.a<{ isActive: boolean }>`
    position: relative;
    padding: 0.5em 0.75em;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.rounded.medium};
    &:hover {
        background-color: ${({ theme }) => theme.colors.secondary};
    }
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        bottom: -0.5em;
        left: 0;
        border-bottom: ${({ theme, isActive }) => isActive ? `0.15em solid ${theme.colors.text}` : 'none'};
    }
`

const NoOrder = styled(UnfoldMore)`
  cursor: pointer;
  width: 1em;
`

const AscOrder = styled(ExpandLess)`
  cursor: pointer;
  width: 1em;
`

const DescOrder = styled(ExpandMore)`
  cursor: pointer;
  width: 1em;
`

const Searchbar = styled.input`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.rounded.medium};
  font-size: 1em;
  margin: 0.1em;
  padding: 0.5em 0.75em;
`

interface OrderButtonProps {
  column: string
  setOrdering: (ordering: any) => void
  ordering: any
  onChange: () => void
}

const OrderButton: FC<OrderButtonProps> = ({ column, setOrdering, ordering, onChange }) => {
  const nextOrder = (currOrder: string) => {
    switch (currOrder) {
      case "none":
        return "asc";
      case "asc":
        return "desc";
      case "desc":
        return "none";
      default:
        return "none";
    }
  };

  const changeOrdering = () => {
    const newOrdering = { ...ordering };
    newOrdering[column] = nextOrder(ordering[column]);
    setOrdering(newOrdering);
    onChange();
  };

  switch (ordering[column]) {
    case "none":
      return <NoOrder onClick={changeOrdering} />;
    case "asc":
      return <AscOrder onClick={changeOrdering} />;
    case "desc":
      return <DescOrder onClick={changeOrdering} />;
    default:
      return null;
  }
};

const Users: FC = () => {
  const accounts = useUsers();
  const { t } = useTranslation();
  const [searchedString, setSearchedString] = useState<string>("");
  const [filteredData, setFilteredData] = useState(accounts.data);
  const USERS_PER_PAGE = 25;
  const { currentPage, totalPages, nextPage, prevPage, paginatedData } = usePaging(filteredData || [], USERS_PER_PAGE);
  const [ordering, setOrdering] = useState<{ [key: string]: string }>({
    login: 'none',
    faculty: 'none',
    year: 'none',
    lastActive: 'desc'
  });

  const orderData = (column: string) => {
    if (!filteredData) return;
    
    const sortingFunctions: { [key: string]: (a: StudentAccount, b: StudentAccount) => number } = {
      login: (a, b) => (ordering[column] === 'asc' ? (a.login || "").localeCompare(b.login || "") : (b.login || "").localeCompare(a.login || "")),
      faculty: (a, b) => (ordering[column] === 'asc' ? (a.faculty || "").localeCompare(b.faculty || "") : (b.faculty || "").localeCompare(a.faculty || "")),
      year: (a, b) => (ordering[column] === 'asc' ? a.year - b.year : b.year - a.year),
      lastActive: (a, b) => (ordering[column] === 'asc' ? Number(a.lastActiveOn) - Number(b.lastActiveOn) : Number(b.lastActiveOn) - Number(a.lastActiveOn)),
    };
  
    const comparisonFunction = sortingFunctions[column];
    if (comparisonFunction) {
      const sortedData = [...filteredData].sort(comparisonFunction);
      setFilteredData(sortedData);
    }
  };

  useEffect(() => {
    if (searchedString === "") {
      setFilteredData(accounts.data);
      return;
    }
    const filtered = accounts.data?.filter(acc => {
      if (!acc.faculty || !acc.login) return false;
      return acc.faculty.toLowerCase().includes(searchedString.toLowerCase()) ||
        acc.login.toLowerCase().includes(searchedString.toLowerCase());
    });
    setFilteredData(filtered);
  }, [accounts.data, searchedString]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Row alignItems="center" justifyContent="space-between" className="mb-3">
        <Searchbar type="text" placeholder={t('search') || "Hľadať"} onChange={(e: any) => setSearchedString(e.target.value)} />
        <a rel="noopener noreferrer"
          target="_blank"
          href="/adminer/?pgsql=postgres&username=tuna&db=tuna&ns=public&select=student_account"
        >
          <Row alignItems="center" style={{ gap: '0.25em' }}>
            <Text>Adminer</Text>
            <OpenInNew size={16} />
          </Row>
        </a>
      </Row>
      <Table>
        <thead>
          <tr>
            <TableHeaderCell>#</TableHeaderCell>
            <TableHeaderCell>
              {t('user')} <OrderButton column="login" setOrdering={setOrdering} ordering={ordering} onChange={() => {orderData('login')}} />
            </TableHeaderCell>
            <TableHeaderCell>
              {t('faculty')} <OrderButton column="faculty" setOrdering={setOrdering} ordering={ordering} onChange={() => {orderData('faculty')}} />
            </TableHeaderCell>
            <TableHeaderCell>
              {t('year')} <OrderButton column="year" setOrdering={setOrdering} ordering={ordering} onChange={() => {orderData('year')}} />
            </TableHeaderCell>
            <TableHeaderCell>
              {t('lastActive')} <OrderButton column="lastActive" setOrdering={setOrdering} ordering={ordering} onChange={() => {orderData('lastActive')}} />
            </TableHeaderCell>
          </tr>
        </thead>
        <tbody>
          {!accounts.isLoading && paginatedData() && paginatedData().map((account, index) => {
            const date: Date = new Date(account.lastActiveOn)
            const dateString: string = date.toLocaleString("sk")
            return (
              <tr key={`user-${account.login}`}>
                <TableCell>{(currentPage-1) * USERS_PER_PAGE + index + 1}</TableCell>
                <TableCell>{account.login ?? ''}</TableCell>
                <TableCell>{account.faculty ?? '-'}</TableCell>
                <TableCell>{account.year === 0 ? '-' : account.year}</TableCell>
                <TableCell>{dateString}</TableCell>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <Row justifyContent="center" style={{ gap: "1em", marginTop: "1em" }}>
        <Button variant="circular" icon={<ChevronLeft size={16} />} onClick={prevPage} />
        <Row style={{ width: "fit-content" }} justifyContent="center" alignItems="baseline">
          <Text style={{ marginRight: "0.3em" }} fontWeight={600}>{currentPage}</Text>
          <TextSmall fontWeight={500}>/ {totalPages}</TextSmall>
        </Row>
        <Button variant="circular" icon={<ChevronRight size={16} />} onClick={nextPage} />
      </Row>
    </div>
  );
};

const FeedbackTable: FC = () => {
  const feedback = useFeedback();
  const { t } = useTranslation();
  const [searchedString, setSearchedString] = useState<string>("");
  const [filteredData, setFilteredData] = useState(feedback.data);
  const [ordering, setOrdering] = useState<{type: string, dir: string}>({ 
    type: "createdOn",
    dir: "desc"
  })

  const orderData = () => {
    if (!filteredData) return;
    
    const sortingFunctions: { [key: string]: (a: Feedback, b: Feedback) => number } = {
      login: (a, b) => (ordering.dir === 'asc' ? (a.login || "").localeCompare(b.login || "") : (b.login || "").localeCompare(a.login || "")),
      content: (a, b) => (ordering.dir === 'asc' ? (a.content || "").localeCompare(b.content || "") : (b.content || "").localeCompare(a.content || "")),
      createdOn: (a, b) => (ordering.dir === 'asc' ? Number(a.createdOn) - Number(b.createdOn) : Number(b.createdOn) - Number(a.createdOn)),
      type: (a, b) => (ordering.dir === 'asc' ? (a.type || "").localeCompare(b.type || "") : (b.type || "").localeCompare(a.type || "")),
    };
  
    const comparisonFunction = sortingFunctions[ordering.type];
    if (comparisonFunction) {
      const sortedData = [...filteredData].sort(comparisonFunction);
      setFilteredData(sortedData);
    }
  };

  useEffect(() => {
    orderData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  useEffect(() => {
    if (searchedString === "") {
      setFilteredData(feedback.data);
      orderData();
      return;
    }
    const filtered = feedback.data?.filter(f => {
      if (!f.login || !f.type) return false;
      return f.login.toLowerCase().includes(searchedString.toLowerCase()) 
        || f.content.toLowerCase().includes(searchedString.toLowerCase())
        || f.type.toLowerCase().includes(searchedString.toLowerCase());
    });
    setFilteredData(filtered);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback.data, searchedString]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Row alignItems="center" justifyContent="space-between" className="mb-3">
        <Row alignItems="center" style={{ gap: "1em", flexWrap: "wrap" }}>
          <Searchbar type="text" placeholder={t('search') || "Hľadať"} onChange={(e: any) => setSearchedString(e.target.value)} />
          <DropdownSelect 
            options={[t('byNewest'), t('byOldest'), t('byType')]} 
            values={["desc,createdOn", "asc,createdOn", "desc,type"]} 
            onChange={(e) => setOrdering({ type: e.target.value.split(',').at(1) || "", dir: e.target.value.split(',').at(0) || "" })} 
          />
        </Row>
        <a rel="noopener noreferrer"
          target="_blank"
          href="/adminer/?pgsql=postgres&username=tuna&db=tuna&ns=public&select=feedback"
        >
          <Row alignItems="center" style={{ gap: '0.25em' }}>
            <Text>Adminer</Text>
            <OpenInNew size={16} />
          </Row>
        </a>
      </Row>

      {!feedback.isLoading && filteredData && filteredData.map((f) => (
        <Container style={{ marginTop: '1em' }}>
          <Row>
            <Row alignItems="center" style={{ gap: '1em', marginBottom: '0.5em' }}>
              <Text fontWeight={500}>{f.login || "anonym"}</Text>
              <TextSmall>{new Date(f.createdOn).toLocaleDateString('sk')}</TextSmall>
            </Row>
            {f.type && (
              <Badge variant="secondary">
                <Text fontWeight={600}>{f.type}</Text>
              </Badge>
            )}
          </Row>
          <Row style={{ gap: "1em" }}>
            {f.type === "satisfaction" && (
              <Row alignItems="center" style={{ width: "fit-content", height: "fit-content", whiteSpace: "nowrap" }}>
                <Text fontWeight={500}>{decodeSatisfaction(f.content)?.stars}</Text>
                <TextSmall fontWeight={500}>/{decodeSatisfaction(f.content)?.totalStars}</TextSmall>
                <Text fontWeight={500}>★</Text>
              </Row>
            )}
            <Text>{f.type === "satisfaction" ? decodeSatisfaction(f.content)?.content  : f.content}</Text>
          </Row>
        </Container>
      ))}
    </div>
  )
}

const SemesterTab = styled(Text)`
  white-space: nowrap;
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.rounded.medium};
  padding: 0.25em 0.5em;
`

const AdminPage: FC<AdminPageProps> = () => {
    const { tab } = useParams();
    const semester = useSemester();
    const { t } = useTranslation();

    return (
      <main>
        <SecondaryNavigation>
          <Row alignItems="center">
            <NavigationTab href="/admin/users" isActive={tab === 'users'}>{t('users')}</NavigationTab>
            <NavigationTab href="/admin/feedback" isActive={tab === 'feedback'}>{t('feedback')}</NavigationTab>
          </Row>
          <SemesterTab fontWeight={500}>{semester.data?.name}</SemesterTab>
        </SecondaryNavigation>

        { tab === 'feedback' && <FeedbackTable /> }
        { tab === 'users' && <Users /> }
      </main>
    )
}

export default AdminPage