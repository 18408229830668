import { useState, useEffect } from "react";
import { useCreateAccount } from "./api/use-account";

export interface User {
  idUser: number;
  login: null | string;
  fullName: null | string;
  email: null | string;
  uidnumber: number;
  roles: UserRole[];
}

export interface UserRole {
  idRole: number;
  name: string;
}

export const useAuth = () => {
  const account = useCreateAccount();
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const ssoServer: string = "/oauth2/authorization/kpi-fei";

  const isAuthenticated = () => {
    if (!user) return false;
    return !!user.login;
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const request = await fetch("/api/user");
        if (request.ok) {
          const response: User = await request.json();
          setUser(response);
          if (isAuthenticated()) account.mutate();
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error(error);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthStatus().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = () => {
    window.location.href = ssoServer;
  };

  const logout = async () => {
    try {
      const response = await fetch("/logout");
      if (response.ok) {
        setUser(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isAdmin = () => {
    if (!user || !user.roles) return false;
    if (user.roles.find((role) => role.name === "tuna_admin")) return true;
    return false;
  };

  const isEmployee = () => {
    if (user && user.idUser !== 0) return true;
    return false;
  };

  return {
    user,
    ssoServer,
    isLoading,
    login,
    logout,
    isAuthenticated,
    isAdmin,
    isEmployee,
  };
};

export default useAuth;
