import React, { type ComponentPropsWithoutRef, type FC } from 'react';
import styled from 'styled-components';
import { ChevronRight } from '@styled-icons/material-rounded';
import { Text } from './Typography';
import { Column, Row } from './Flex';

interface SubjectProps extends ComponentPropsWithoutRef<'div'> {
  color: string;
  name: string;
  time: string;
  room: string;
  courseLink: string;
  openable: boolean;
  children?: React.ReactNode;
}

const VerticalLine = styled.div<{ color: string }>`
  display: flex;
  width: 0.375em;
  height: 100%;
  min-height: 6em;
  border-radius: ${({ theme }) => theme.rounded.medium};
  background-color: ${({ color }) => color};
`;

const Subject: FC<SubjectProps> = ({
  color,
  name,
  time,
  room,
  courseLink,
  children,
  openable,
}) => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {courseLink !== "" ? (
        <a href={courseLink} style={{ width: '100%' }}>
          <Row style={{ gap: '1em', margin: '0.5em' }}>
            <VerticalLine color={color} />
            <Column style={{ margin: '0.5em 0' }}>
              <Row style={{ marginBottom: '0.25em', gap: '0.5em' }}>{children}</Row>
              <Text fontWeight={700}>{name}</Text>
              <Row alignItems="center" justifyContent="space-between">
                <Text>{time}</Text>
                <Text>{room}</Text>
                {openable && <ChevronRight size={24} />}
              </Row>
            </Column>
          </Row>
        </a>) : (
        <Row style={{ gap: '1em', marginTop: '1em' }}>
          <VerticalLine color={color} />
          <Column style={{ margin: '0.5em 0' }}>
            <Row style={{ marginBottom: '0.25em', gap: '0.5em' }}>{children}</Row>
            <Text fontWeight={700}>{name}</Text>
            <Row alignItems="center" justifyContent="space-between">
              <Text>{time}</Text>
              <Text>{room}</Text>
              {openable && <ChevronRight size={24} />}
            </Row>
          </Column>
        </Row>
      )}
    </>
  );

export default Subject;

Subject.defaultProps = ({
  children: null
})
