import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContainerLayout from '../layouts/ContainerLayout';

import Person from '../containers/Person';
import Building from '../containers/Building';
import Level from '../containers/Level';
import Room from '../containers/Room';
import Schedule from '../containers/Schedule';
import Search from '../containers/Search';
import QrReader from '../containers/QrReader';
import Courses from '../containers/Courses';
// import Map from '../containers/Map.js';
import Map from '../containers/Map';
import Department from '../containers/Department';
import Canteens from '../containers/Canteens';
import Canteen from '../containers/Canteen';
import SchedulePage from '../containers/SchedulePage';
import CoursePage from '../containers/CoursePage';
import AuthPage from '../containers/AuthPage';
import useAuth from '../hooks/use-auth';
import AdminPage from '../containers/AdminPage';
import NotFoundPage from '../containers/NotFoundPage';
import LoadingPage from '../containers/LoadingPage';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { login, isLoading, isAuthenticated } = useAuth();
  if (!navigator.onLine) return children;
  if (!isLoading && !isAuthenticated()) login();
  if (isLoading && !isAuthenticated()) return <LoadingPage />
  return children;
}

function RequirePrivilege({ children }: { children: JSX.Element }) {
  const { isLoading, isAuthenticated, isAdmin, login } = useAuth();
  if (!isLoading && !isAuthenticated()) login();
  if (!isLoading && isAdmin()) return children;
  return null;
}

function RouterResolver() {
  return (
    <Router>
      <ContainerLayout watchFullwidth>
        <Routes>
          <Route path="/people/:idPerson" element={<Person />} />

          <Route path="/building/:idBuilding" element={<Building />} />
          <Route path="/p/:idBuilding" element={<Building />} />

          <Route path="/p/:idBuilding/:idLevel" element={<Level />} />
          <Route path="/level/:idBuilding/:idLevel" element={<Level />} />
          <Route path="/building/:idBuilding/:idLevel" element={<Level />} />

          <Route path="/qrReader" element={<QrReader />} />

          <Route path="/room/:idRoom" element={<Room />} />
          <Route path="/r/:idRoom" element={<Room />} />

          <Route path="/schedule/:type/:id" element={<Schedule />} />
          <Route path="/schedule/:type/:id/:date" element={<Schedule />} />

          <Route path="/search" element={<Search />} />

          <Route path="/map/:building?/:level?/:idRoom?" element={<Map />} />

          <Route path="/department/:idDepartment" element={<Department />} />

          <Route
            path="/schedule"
            element={(
                <RequireAuth><SchedulePage /></RequireAuth>
            )}
          />

          <Route 
            path="/courses" 
            element={(
              <RequireAuth><Courses /></RequireAuth>
            )}
          />

          <Route
            path="/course/:courseName"
            element={(
                <RequireAuth><CoursePage /></RequireAuth>
            )}
          />

          <Route path="/login/oauth2/code/kpi-fei" element={<AuthPage />} />
          <Route path="/admin/:tab" element={<RequirePrivilege><AdminPage /></RequirePrivilege>} />

          <Route path="/" element={<Search />} />
          <Route path="/canteen" element={<Canteens />} />
          <Route path="/canteen/:idCanteen" element={<Canteen />} />

          <Route path="/" element={<Search />} />
          
          {/* Not found page */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ContainerLayout>
    </Router>
  );
}

export default RouterResolver;
