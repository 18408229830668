import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { DarkMode, LightMode } from '@styled-icons/material-rounded';
import Button from './Button';
import Icon from './Icon';

const TogglerButton = styled(Button)`
  color: ${({ theme }) => theme.colors.detail};

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`

function ThemeToggler() {
  const { t } = useTranslation();
  const { theme, toggleTheme } = useContext(ThemeContext);

  const title: string = theme === 'light' ? t('lightMode') : t('darkMode');

  return (
    <TogglerButton
      variant="ghost"
      style={{ width: '100%', justifyContent: 'left' }}
      onClick={() => toggleTheme(theme === 'light' ? 'dark' : 'light')}
      title={title}
      icon={<Icon color="inherit">{theme === 'light' ? <DarkMode /> : <LightMode />}</Icon>}
    />
  );
}

export default ThemeToggler;
