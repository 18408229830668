import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Row } from '../Flex';
import { BuildingIcon } from '../Icons';

interface Props {
  building: any;
  floors?: Array<any>;
  selectFloor: (floor: any) => void;
}

const FloorSelectItem = styled.div`
  margin-bottom: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 0.65rem;
  background: rgba(0, 0, 0, 0.05);
  transition: 0.15s ease;
  font-size: 0.9375rem;
  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }
`;

const DetailLink = styled.a`
  height: 40px;
  width: 100%;
  background: #111111;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  transition: 0.15s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.9);
  }
`;

function MapBuildingDetail(props: Props) {
  const { building, floors, selectFloor } = props;
  const { t } = useTranslation();

  return (
    <div className="">
      <Row alignItems="center">
        <BuildingIcon color="#BDBDBD" className="mr-3" />
        <div className="font-bold">
          {t('building')} {building?.name}
        </div>
      </Row>

      <div className=" mt-1">
        <div className="font-semibold text-sm">{t('address')}</div>
        <div className="mb-2 text-sm">{building?.address}</div>

        <DetailLink href={`/building/${building?.idBuilding}`} target="_blank">
          {t('detail')}
        </DetailLink>
      </div>

      {floors && floors.length > 0 && (
        <div className="mt-3">
          <div className="font-semibold text-sm mb-2">{t('selectFloor')}</div>
          {floors?.map((floor: any) => (
            <FloorSelectItem
              onClick={() => {
                selectFloor(floor);
              }}
            >
              {floor.level}
            </FloorSelectItem>
          ))}
        </div>
      )}
    </div>
  );
}

MapBuildingDetail.defaultProps = {
  floors: [],
};

export default MapBuildingDetail;
