import { useQuery } from "@tanstack/react-query";

export interface Semester {
  name: string;
  startDate: Date;
  endDate: Date;
  currentWeek: number;
}

const baseURL = "";

const passedSemesterWeeks = (semesterStartDate: Date) => {
  const date: Date = new Date();
  const difference = Math.abs(date.getTime() - semesterStartDate.getTime());
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const weeks = difference / millisecondsInDay / 7;

  if (Math.abs(weeks - Math.floor(weeks)) < 0.9) {
    return Math.floor(weeks) + 1;
  }
  return Math.ceil(weeks) + 1;
};

export const useSemester = () =>
  useQuery({
    queryKey: ["semester"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/schedule/semester`);

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to load semester.");
      }

      const { name, startDate, endDate } = await response.json();

      const semester: Semester = {
        name,
        startDate,
        endDate,
        currentWeek: passedSemesterWeeks(new Date(startDate)),
      };

      return semester;
    },
  });
