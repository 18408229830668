import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { type Course } from "./use-course";

export interface Assignment {
  id?: number;
  name: string;
  dueWeek: number;
  studentCourse: Course;
  maxPoints: number;
  points: number | 0;
  credibilityStatus?: string & { length: 1 };
  creatorStudentAccount: {
    login: string;
  };
  ownerStudentAccount: {
    login: string;
  };
  dateCreated?: Date;
}

const baseURL = "";

export const useAssignment = (id?: number) =>
  useQuery({
    queryKey: ["assignment"],
    queryFn: async () => {
      if (id) {
        const response = await fetch(`${baseURL}/api/assignment/${id}`);
        return (await response.json()) as Assignment;
      }
      throw new Error("Assignment not found.");
    },
  });

export const useUserAssignments = () =>
  useQuery({
    queryKey: ["assignments"],
    queryFn: async () => {
      const user = await (await fetch(`${baseURL}/api/user`)).json();
      const response = await fetch(
        `${baseURL}/api/assignment/owner/${user.login}`
      );

      if (!response.ok) {
        const error = await response.json();
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load assignments.");
      }

      return (await response.json()) as Assignment[];
    },
  });

export const useCourseAssignments = (courseId: number) =>
  useQuery({
    queryKey: ["courseAssignments", courseId],
    queryFn: async () => {
      const user = await (await fetch(`${baseURL}/api/user`)).json();
      const response = await fetch(
        `${baseURL}/api/assignment/owner/${user.login}`
      );

      if (!response.ok) {
        const error = await response.json();
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load assignments.");
      }

      const data = (await response.json()) as Assignment[];
      const filteredData = data.filter(
        (assignment) => assignment.studentCourse.id === courseId
      );
      return filteredData;
    },
  });

export const useCreateAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (assignment: Assignment) => {
      const response = await fetch(`${baseURL}/api/assignment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(assignment),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to create assignment");
      }

      return response.json();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["courseAssignments"] });
    },
  });
};

export const useEditAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      assignmentId: number;
      assignment: Assignment;
    }) => {
      const response = await fetch(
        `${baseURL}/api/assignment/${data.assignmentId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data.assignment),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to edit assignment");
      }

      return response.json();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["courseAssignments"] });
    },
  });
};

export const useDeleteAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (assignmentId: number) => {
      const response = await fetch(
        `${baseURL}/api/assignment/${assignmentId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to edit assignment");
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["courseAssignments"] });
    },
  });
};
