import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Variants, motion } from 'framer-motion';
import styled from 'styled-components';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Row } from '../components/Flex';
import { getCanteen } from '../api/api';
import { Canteen } from '../api/api.types';
import { LeftArrow, RestaurantIcon, RightArrow } from '../components/Icons';
import { H1, Text } from '../components/Typography';
import Button from '../components/Button';
import { CanteenMenu } from '../components/CanteenMenu';
import LoadingPage from './LoadingPage';

const MotionGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-between;
  grid-row-gap: 30px;

  @media only screen and (max-width: 600px) {
    & {
      grid-template-columns: 100%;
    }
  }
`;

const MotionCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.pressBackground};
  border: ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.colors.text};
  box-shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)";
  border-radius: 5px;
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => theme.border};
  padding-left: 10px;
  padding-right: 10px;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
`;

const containerVariants: Variants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const itemVariants: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

function getCurrentWorkDate() {
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);

  while (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return currentDate;
}

function getPreviousWorkDate(date: Date) {
  const currentDate = new Date(date);

  do {
    currentDate.setDate(currentDate.getDate() - 1);
  } while (currentDate.getDay() === 0 || currentDate.getDay() === 6);

  return currentDate;
}

function getNextWorkDate(date: Date) {
  const currentDate = new Date(date);

  do {
    currentDate.setDate(currentDate.getDate() + 1);
  } while (currentDate.getDay() === 0 || currentDate.getDay() === 6);

  return currentDate;
}

export default function () {
  const { t } = useTranslation();
  const { idCanteen } = useParams();
  const [canteen, setCanteen] = useState<Canteen>();

  const [currentDate, setCurrentDate] = useState<Date>(getCurrentWorkDate());

  const previousDate = getPreviousWorkDate(currentDate);
  const nextDate = getNextWorkDate(currentDate);

  useEffect(() => {
    (async () => {
      try {
        if (idCanteen) {
          const c = await getCanteen(idCanteen);
          setCanteen(c);
        }
      } catch (e) {
        setCanteen(undefined);
      }
    })();
  }, [idCanteen]);

  if (canteen === undefined) return <LoadingPage />;

  return (
    <main>
      <Row alignItems="center" justifyContent="space-between">
        <Button variant="secondary" onClick={() => setCurrentDate(previousDate)}>
          <LeftArrow size={24} />
        </Button>
        <H1>{moment(currentDate).format('dddd DD. MM. YYYY')}</H1>
        <Button variant="secondary" onClick={() => setCurrentDate(nextDate)}>
          <RightArrow size={24} />
        </Button>
      </Row>
      <MotionGrid
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <MotionCard key={canteen.idCanteen} variants={itemVariants}>
          <CardTitle>
            <RestaurantIcon />
            <Text fontWeight={500}>{canteen.name}</Text>
          </CardTitle>
          <CanteenMenu canteen={canteen} date={currentDate} />
          <CardFooter>
            <Text fontWeight={200}>
              {t('updatedAt')}{' '}
              {moment(
                canteen.menus.find(
                  (menu) => menu.date === currentDate.getTime(),
                )?.updatedAt,
              ).format('HH:mm')}
            </Text>
          </CardFooter>
        </MotionCard>
      </MotionGrid>
    </main>
  );
}
