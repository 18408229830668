const baseURL = "";

export const useSearch = () => {
  const search = async (
    text: string,
    offset: number,
    limit: number,
    filter: string
  ) => {
    let url = `${baseURL}/api/search?text=${text}&offset=${offset}&limit=${limit}`;
    if (filter !== "") url = `${url}&type=${filter}`;

    const r = await fetch(url);
    const json = await r.json();
    return json;
  };

  return search;
};
