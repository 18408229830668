import { useQuery } from "@tanstack/react-query";
import { Lesson } from "./use-lesson";

const baseURL = "";

async function fetchLessonsForDate(id: string, formattedDate: string) {
  const response = await fetch(
    `${baseURL}/api/schedule/employee/${id}/${formattedDate}`
  );
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to load schedule.");
  }
  return response.json();
}

export const useEmployeeSchedule = (id: number) =>
  useQuery({
    queryKey: ["employeeSchedule", id],
    queryFn: async () => {
      const semester = await fetch(`${baseURL}/api/schedule/semester`);

      if (!semester.ok) {
        const error = await semester.json();
        throw new Error(error.message || "Failed to load semester.");
      }

      const { startDate } = await semester.json();
      const currDate: Date = new Date(startDate);
      const endDate: Date = new Date(currDate);
      endDate.setDate(endDate.getDate() + 7);
      const lessons: Lesson[] = [];
      const lessonsPromises = [];

      while (currDate < endDate) {
        const formattedDate = currDate.toISOString().slice(0, 10);
        lessonsPromises.push(fetchLessonsForDate(id.toString(), formattedDate));
        currDate.setDate(currDate.getDate() + 1);
      }

      const lessonsArrays = await Promise.all(lessonsPromises);
      lessonsArrays.forEach((lessonsForDate) =>
        lessons.push(...lessonsForDate)
      );

      return lessons;
    },
  });
