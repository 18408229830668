import React, { type FC, useState } from "react";
import styled from "styled-components";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  useCreateAssignment,
  type Assignment as AProps,
} from "../hooks/api/use-assignment";
import { H2, Text } from "./Typography";
import Overlay from "./Overlay";
import Margin from "./Margin";
import Button from "./Button";
import { Row } from "./Flex";
import Assignment from "./Assignment";
import useAuth from "../hooks/use-auth";

interface ImportAssignmentProps {
  assignment: AProps;
}

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const ImportAssignment: FC<ImportAssignmentProps> = ({ assignment }) => {
  const { t } = useTranslation();
  const { user, login, isAuthenticated } = useAuth();
  const [isOverlayShown, setIsOverlayShown] = useState<boolean>(true);
  const { name, dueWeek, maxPoints, studentCourse, creatorStudentAccount } = assignment;

  const closeOverlay = () => {
    setIsOverlayShown(false);
  };

  const assignmentMutation = useCreateAssignment();

  const addAssignment = () => {
    if (!isAuthenticated) {
      login();
      return;
    }
    if (!user) return;
    const newAssignment: AProps = {
      name,
      dueWeek,
      maxPoints,
      studentCourse,
      points: 0,
      creatorStudentAccount,
      ownerStudentAccount: {
        login: user.login || "",
      },
      dateCreated: new Date(),
    };

    assignmentMutation.mutate(newAssignment, {
      onSuccess: () => {
        toast.success(t('assignment.toastSuccessImport'));
        setIsOverlayShown(false);
      },
      onError: () => {
        toast.error(t('toastError'));
      },
    });
  };

  return (
    <Overlay isOpen={isOverlayShown} onClose={closeOverlay}>
      <ContentWrapper>
        <Margin direction="bottom" size={0.5}>
          <H2 fontWeight={600}>{t('assignment.importTitle')}</H2>
          <Text>
            {t('assignment.importDescription')}
          </Text>
        </Margin>

        <Assignment assignment={assignment} />

        <Row justifyContent="space-between">
          <Button variant="ghost" onClick={closeOverlay}>
            {t('close')}
          </Button>
          <Button variant="primary" onClick={addAssignment}>
            {t('import')}
          </Button>
        </Row>
      </ContentWrapper>
    </Overlay>
  );
};

export default ImportAssignment;
