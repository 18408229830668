import React, { useEffect, useState, type FC } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { Column, Row } from '../Flex';
import { Text, H3 } from '../Typography';
import Button from '../Button';

interface SidePageProps {
  title: string;
  options: string[];
  isOpen: boolean;
  onClose: (e?: React.ChangeEvent<HTMLInputElement>) => void;
}

const SidePageContainer = styled.div<{ isVisible: boolean }>`
  z-index: 1;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: ${({ isVisible }) => (isVisible ? '0' : '-100%')};
  width: 100%;
  height: 100%;
  padding: 1em;
  background-color: ${({ theme }) => theme.colors.background};
  transition: right 0.2s ease-in-out;

  @media (min-width: 900px) {
    right: ${({ isVisible }) => (isVisible ? '0' : '-100%')};
    width: 100%;
    height: 100%;
    padding: 2em;
  }
`;

const Option = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  align-items: center;
  border-bottom: ${({ theme }) => theme.border};
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const RadioLabel = styled.label`
  width: 100%;
  cursor: pointer;
  padding: 0.75em 0;
`;

const CustomRadio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: ${({ theme }) => theme.colors.text};
  width: 1em;
  height: 1em;
  display: grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: translateY(-0.4em);
    transition: 120ms transform ease-in-out;
  }

  &:checked::before {
    content: "✓";
  }
`;

const TrucatedText = styled(Text)`
  padding-left: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SidePage: FC<SidePageProps> = ({ title, options, isOpen, onClose }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] =
    useState<React.ChangeEvent<HTMLInputElement>>();

  const closeSidePage = () => {
    onClose(selectedOption);
  };

  const handleOptionClick = (event: any) => {
    setSelectedOption(event);
    onClose(event);
  };

  return (
    <SidePageContainer isVisible={isOpen}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        style={{ position: 'relative' }}
      >
        <Button variant="ghost" onClick={closeSidePage}>
          {t('back')}
        </Button>
        <H3
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
          }}
          align="center"
          fontWeight={600}
        >
          {title}
        </H3>
      </Row>
      <ul>
        {options.map((option, index) => (
            <Option key={option}>
              <RadioLabel htmlFor={`option-${index}`}>{option}</RadioLabel>
              <CustomRadio
                id={`option-${index}`}
                checked={index === Number(selectedOption?.target.value)}
                type="radio"
                value={index}
                onChange={handleOptionClick}
              />
            </Option>
          ))}
      </ul>
    </SidePageContainer>
  );
};

interface SelectProps {
  id?: string;
  label: string;
  name: string;
  validation: any;
  value?: number;
  options: string[];
}

const SelectionTab = styled.button`
  padding: 0.5em 1em;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.medium};
  font-size: 1em;
  cursor: pointer;
`;

const Select: FC<SelectProps> = ({
  id,
  label,
  name,
  options,
  value,
  validation,
}) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(value || -10);

  const toggleSelector = () => {
    setIsSelecting(!isSelecting);
  };

  useEffect(() => {
    // -10 = not selected (displays 'Select ›')
    if (value === undefined) setSelectedIndex(-10);
    else setSelectedIndex(value);
  }, [value]);

  const handleInputChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelecting(false);
    if (e !== undefined) {
      const index: number = Number(e.target.value);
      setSelectedIndex(index);
      setValue(name, index);
    }
  };

  return (
    <>
      <input
        id={id}
        type="hidden"
        value={selectedIndex}
        {...register(name, validation)}
      />
      <Column>
        <SelectionTab type="button" onClick={toggleSelector} name="dueWeek">
          <Row justifyContent="space-between" alignItems="center">
            <label htmlFor={name}>{label}</label>
            {selectedIndex !== -10 ? (
              <TrucatedText color="detail" fontWeight={500}>
                {options[selectedIndex as number]} ›
              </TrucatedText>
            ) : (
              <Text color="detail" fontWeight={500}>
                {t('select')} ›
              </Text>
            )}
          </Row>
        </SelectionTab>

        <ErrorMessage errors={errors} name={name} />
      </Column>

      <SidePage
        title={label}
        options={options}
        isOpen={isSelecting}
        onClose={handleInputChange}
      />
    </>
  );
};

export default Select;

Select.defaultProps = {
  id: "",
  value: -10,
}
