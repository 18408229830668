import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Search, QrCode } from '@styled-icons/material-rounded';
import { Row } from './Flex';

interface SearchProps {
  placeholder: string;
  onChange: (value: string) => void;
  value?: string;
}

const SearchIcon = styled(Search)`
  color: ${({ theme }) => theme.colors.icons};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.link};
  }
`;

const QrCodeIcon = styled(QrCode)`
  color: ${({ theme }) => theme.colors.icons};

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const InvisibleInput = styled.input`
  padding: 10px;
  border-radius: 50px;
  border: #fff;
  font-size: 0.9em;
  background: ${({ theme }) => theme?.colors?.background};
  color: ${({ theme }) => theme?.colors?.text};
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const WrapperBorder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 3px;
  height: 44px;
  padding: 0.5em;
  border: ${({ theme }) => theme.border};
  box-shadow: none;
  border-radius: 24px;
  width: 100%;

  &:hover,
  &:focus-within {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border: ${({ theme }) => theme.borderHover};
  }
`;

export const WrapperSpan = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px;
  height: 44px;
  width: 44px;
  min-width: 44px;
  border: ${({ theme }) => theme.border};
  box-shadow: none;
  border-radius: 24px;
  position: relative;
  cursor: pointer;
  background: transparent;
  padding: 0px;

  &:hover,
  &:focus-within {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border: ${({ theme }) => theme.borderHover};
  }
`;

export function SearchBar(props: SearchProps) {
  const { placeholder, onChange, value } = props;
  const history = useNavigate();
  const { t } = useTranslation();

  const qrTitle = t('read qr code');

  return (
    <Row>
      <WrapperBorder>
        <InvisibleInput
          type="text"
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          autoFocus
          value={value}
        />
        <SearchIcon size={33} />
      </WrapperBorder>
      <WrapperSpan
        title={qrTitle}
        onClick={() => {
          history('/qrReader');
        }}
      >
        <QrCodeIcon size={33} />
      </WrapperSpan>
    </Row>
  );
}

SearchBar.defaultProps = {
  value: '',
};
