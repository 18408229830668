import React, { type FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import key from '../assets/images/empty.svg';
import { H3, TextSmall } from './Typography';
import Button from './Button';
import Overlay from './Overlay';
import ScheduleUpload from './ScheduleUpload';

const MissingScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25em;
`;

const Image = styled.img`
  margin: 3em 0 2em 0;
  width: 12em;
`;

const MissingSchedule: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: '_schedule' });
  const [isOverlayShown, setIsOverlayShown] = useState<boolean>(false);

  return (
    <MissingScheduleWrapper>
      <Image src={key} />
      <H3 align="center" fontWeight={600}>{t('notFound')}</H3>
      <TextSmall align="center" style={{ marginBottom: '1em' }}>
        {t('notFoundDescription')}
      </TextSmall>
      <Button
        variant="primary"
        onClick={() => {
          setIsOverlayShown(true);
        }}
      >
        <TextSmall fontWeight={500}>{t('set')}</TextSmall>
      </Button>
      <Overlay
        isOpen={isOverlayShown}
        onClose={() => {
          setIsOverlayShown(false);
        }}
      >
        <ScheduleUpload />
      </Overlay>
    </MissingScheduleWrapper>
  );
};

export default MissingSchedule;
