import { cloneDeep } from 'lodash-es';
import type { FillLayer, LineLayer, SymbolLayer } from 'react-map-gl';

export const MAP_ICONS = [
  {
    name: 'marker-restaurant',
    iconPath: '/images/markers/restaurant-marker.png',
  },
  {
    name: 'marker-coffe',
    iconPath: '/images/markers/coffe-marker.png',
  },
  {
    name: 'marker-sport',
    iconPath: '/images/markers/sport-marker.png',
  },
  {
    name: 'marker-parking',
    iconPath: '/images/markers/parking-marker.png',
  },
  {
    name: 'marker-tram',
    iconPath: '/images/markers/tram-marker.png',
  },
  {
    name: 'marker-bus',
    iconPath: '/images/markers/bus-marker.png',
  },
  {
    name: 'marker-ticket-machine',
    iconPath: '/images/markers/ticket-machine-marker.png',
  },
  {
    name: 'marker-room',
    iconPath: '/images/markers/room-marker.png',
  },
  {
    name: 'marker-room-selected',
    iconPath: '/images/markers/room-marker-selected.png',
  },
];

export const BUILDINGS_LAYER: FillLayer = {
  id: 'buildings',
  type: 'fill',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['get', 'selected'], false],
      '#eaaa00',
      '#0c529b',
    ],
    'fill-opacity': 0.8,
  },
};

export const BUILDING_LABELS_LAYER: SymbolLayer = {
  id: 'building_labels',
  type: 'symbol',
  layout: {
    'text-field': ['get', 'name'],
    'text-size': 12,
    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
  },
  paint: {
    'text-halo-color': '#ffffff',
    'text-halo-width': 2,
  },
};

export const RESTAURANTS_LAYER = {
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 15, 0.1, 20, 0.4],
  },
  paint: {},
};

export const MARKERS_LAYER: SymbolLayer = {
  id: 'markers',
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0.1,
      20,
      ['get', 'maxIconSize'],
    ],
  },
  paint: {},
};

export const FLOOR_CORRIDOR_LAYER: LineLayer = {
  id: 'level_corridors',
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-width': 6,
    'line-color': 'black',
  },
};

export const FLOOR_CORRIDOR_ROOM_LAYER: SymbolLayer = {
  id: 'level_rooms',
  type: 'symbol',
  layout: {
    'icon-image': [
      'case',
      ['boolean', ['get', 'selected'], false],
      'marker-room-selected',
      'marker-room',
    ],
    'icon-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      0.05,
      15,
      0.2,
      18,
      0.3,
      20,
      0.8,
    ],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-field': ['get', 'number'],
    'text-offset': [0, 1.35],
    'text-anchor': 'top',
    'text-size': 12,
    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
    'text-optional': true,
  },
  paint: {
    'text-halo-color': '#ffffff',
    'text-halo-width': 2,
  },
};

export const buildMapDataBuildings = (
  data: any,
  idBuilding: string | null = null,
) => {
  const mapFeatures: any = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  data.forEach((item: any) => {
    const updatedItem = cloneDeep(item);
    const reversedCoords: any[] = [];

    item.coordinates?.forEach((c: Array<number>) => {
      reversedCoords.push(c.slice().reverse());
    });

    updatedItem.coordinates = [[...reversedCoords]];
    mapFeatures.data.features.push({
      type: 'Feature',
      geometry: {
        type: updatedItem.type,
        coordinates: updatedItem.coordinates,
      },
      properties: {
        name: updatedItem.name,
        id: updatedItem.id,
        center: [updatedItem.center[1], updatedItem.center[0]],
        selected: idBuilding === updatedItem.id,
      },
    });
  });

  return mapFeatures.data;
};

export const buildMapDataBuildingLabels = (data: any) => {
  const mapFeatures: any = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  data.forEach((item: any) => {
    mapFeatures.data.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.center[1], item.center[0]],
      },
      properties: {
        name: item.name,
        id: item.id,
      },
    });
  });

  return mapFeatures.data;
};

export const buildMapDataRestaurants = (data: any) => {
  const mapFeatures: any = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  data.forEach((item: any) => {
    mapFeatures.data.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.coordinates[1], item.coordinates[0]],
      },
      properties: {
        name: item.name,
        type: item.type,
        icon: item.type === 'Caffee' ? 'marker-coffe' : 'marker-restaurant',
      },
    });
  });

  return mapFeatures;
};

export const buildMapDataMarkers = (data: any) => {
  const mapFeatures: any = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  const getIcon = (type: string) => {
    if (type === 'SportHall') return 'marker-sport';
    if (type === 'Parking') return 'marker-parking';
    if (type === 'Restaurant') return 'marker-restaurant';
    if (type === 'Caffee') return 'marker-coffe';
    if (type === 'TramStop') return 'marker-tram';
    if (type === 'BusStop') return 'marker-bus';
    if (type === 'TicketMachine') return 'marker-ticket-machine';
  };

  const getMaxIconSize = (type: string) => {
    if (type === 'Parking') return 0.25;
    if (type === 'TramStop' || type === 'BusStop') return 0.25;
    if (type === 'TicketMachine') return 0.2;
    return 0.4;
  };

  data.forEach((item: any) => {
    const itemProperties: any = {
      name: item.name,
      type: item.type,
      icon: getIcon(item.type),
      maxIconSize: getMaxIconSize(item.type),
    };

    if (item.type === 'SportHall') {
      itemProperties.scheduleLink = item.scheduleLink;
    }

    if (item.type === 'Restaurant' || item.type === 'Caffee') {
      itemProperties.idCanteen = item.idCanteen;
    }

    if (item.type === 'TramStop' || item.type === 'BusStop') {
      itemProperties.cpLink = item.cpLink;
      // itemProperties.direction = item.direction;
      itemProperties.transportLines = item.transportLines;
    }

    if (item.type === 'TicketMachine') {
      itemProperties.cardPay = item.cardPay;
    }

    mapFeatures.data.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.coordinates[1], item.coordinates[0]],
      },
      properties: itemProperties,
    });
  });

  return mapFeatures.data;
};

export const buildFloorCorridors = (
  data: any,
  idRoom: string | null = null,
) => {
  const mapLineFeatures: any = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };
  const mapRoomFeatures: any = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  data.forEach((item: any) => {
    mapLineFeatures.data.features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [item.x0, item.y0],
          [item.x1, item.y1],
        ],
      },
      properties: {
        id: item.idFloorCorridor,
      },
    });

    const parsedRooms = JSON.parse(item.roomFeatures);
    parsedRooms?.features.forEach((room: any) => {
      const f = cloneDeep(room);
      f.properties.selected = f.properties.idRoom === Number(idRoom);
      mapRoomFeatures.data.features.push(f);
    });
  });

  return {
    corridors: mapLineFeatures.data,
    rooms: mapRoomFeatures.data,
  };
};
