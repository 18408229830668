import React, { type FC } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 1em;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.rounded.large};
  box-shadow: ${({ theme }) => theme.shadow.base};
`;

export const ClickableContainer = styled.button`
  position: relative;
  padding: 1em;
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.large};
  box-shadow: ${({ theme }) => theme.shadow.base};
  cursor: pointer;

  &:active,
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary80};
  }
`;

const ContainerButton: FC = ({ children }) => <ClickableContainer type="button">{children}</ClickableContainer>;

export default ContainerButton;
