import { ComponentPropsWithoutRef, FC } from 'react'
import styled from 'styled-components';

interface DropdownSelectProps extends ComponentPropsWithoutRef<'select'> {
  options: string[];
  values: string[];
  onChange: (e: any) => void;
}

const Select = styled.select`
  border: ${({ theme }) => theme.border};
  background-color: transparent;
  font-size: 1em;
  padding: 0.5em 0.75em;
  border-radius: ${({ theme }) => theme.rounded.medium};
`;

const DropdownSelect: FC<DropdownSelectProps> = ({ options, values, onChange }) => <Select onChange={(e) => onChange(e)}>
    {options.map((option, index) => <option value={values[index]}>{option}</option>)}
  </Select>

export default DropdownSelect