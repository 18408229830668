import React from 'react';
import styled from 'styled-components';
import { OpenInNew, KeyboardArrowRight } from '@styled-icons/material-rounded';
import { Link } from 'react-router-dom';

export const H1 = styled.h1<{
  fontWeight?: number;
  color?: string;
  align?: string;
}>`
  font-size: 1.5em;
  line-height: 1.75em;
  margin: 0;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.colors[props.color ?? 'inherit']};
  text-align: ${({ align }) => align ?? 'left'};
`;

export const H2 = styled.h2<{
  fontWeight?: number;
  color?: string;
  align?: string;
}>`
  font-size: 1.25em;
  line-height: 1.75em;
  margin: 0;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.colors[props.color ?? 'inherit']};
  text-align: ${({ align }) => align ?? 'left'};
`;

export const H3 = styled.h3<{
  fontWeight?: number;
  color?: string;
  align?: string;
}>`
  font-size: 1.125em;
  line-height: 1.625em;
  margin: 0;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.colors[props.color ?? 'inherit']};
  text-align: ${({ align }) => align ?? 'left'};
`;

export const Text = styled.p<{
  fontWeight?: number;
  color?: string;
  align?: string;
}>`
  font-size: 1em;
  line-height: 1.5em;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.colors[props.color ?? 'inherit']};
  text-align: ${({ align }) => align ?? 'left'};
`;

export const TextSmall = styled.p<{
  fontWeight?: number;
  color?: string;
  align?: string;
}>`
  margin: 0;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.colors[props.color ?? 'inherit']};
  text-align: ${({ align }) => align ?? 'left'};
`;

export const TextTiny = styled.small<{
  fontWeight?: number;
  color?: string;
  align?: string;
}>`
  margin: 0;
  font-size: 0.75em;
  line-height: 1em;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.colors[props.color ?? 'inherit']};
  text-align: ${({ align }) => align ?? 'left'};
`;

interface LinkProps {
  color?: string;
}

interface Props extends LinkProps {
  version?: string;
  hideIcon?: boolean;

  text: string;
  href: string;
}

const ExtLink = styled.a<LinkProps>`
  text-decoration: none;
  color: ${({ color, theme }) => color ?? theme.colors.link};
  display: inline-block;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ color, theme }) => color ?? theme.colors.link};
  display: inline-block;
`;

const OpenInNewIcon = styled(OpenInNew)`
  color: ${({ color }) => color};
  display: inline-block;
`;

const KeyboardArrowRightIcon = styled(KeyboardArrowRight)`
  color: ${({ color }) => color};
  display: inline-block;
`;

const Row = styled.div<{ version?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.8;
  padding: 3px 0px;

  ${({ version }) => {
    switch (version) {
      case 'filled':
        return '';
      case 'outlined':
        return 'border: 1px solid #bdbdbd; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 5px; padding: 10px; margin-right:20px;}';
      default:
        return '';
    }
  }}

  &:hover {
    opacity: 1;
  }
`;
// invisible, outlined, filled
export function InternalLink(props: Props) {
  const { href, text, color, version, hideIcon } = props;

  return (
    <Row version={version}>
      <StyledLink color={color} to={href}>
        {text}
      </StyledLink>
      {!(hideIcon ?? false) && (
        <KeyboardArrowRightIcon color={color} size={20} />
      )}
    </Row>
  );
}

export function ExternalLink(props: Props) {
  const { text, color, version, hideIcon, href } = props;

  return (
    <Row version={version}>
      <ExtLink
        color={color}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
      >
        {text}
      </ExtLink>
      {!(hideIcon ?? false) && <OpenInNewIcon color={color} size={15} />}
    </Row>
  );
}

InternalLink.defaultProps = {
  version: 'invisible',
  hideIcon: false,
  color: null,
};

ExternalLink.defaultProps = {
  version: 'invisible',
  hideIcon: false,
  color: null,
};
