import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MotionItem, MotionList } from '../components/Motion';
import { H1, H3, InternalLink, Text } from '../components/Typography';
import { Grid, Row } from '../components/Flex';
import { PersonIcon, RoomIcon } from '../components/Icons';
import Button from '../components/Button';
import { useRoom } from '../hooks/api/use-room';
import Error from './Error';
import LoadingPage from './LoadingPage';
import { useScheduleType } from '../hooks/api/use-scheduletype';

function Room() {
  const { t, i18n } = useTranslation();
  const { idRoom } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [schedule, setSchedule] = useState<boolean>(false);
  const room = useRoom(idRoom || "");
  const scheduleType = useScheduleType("room", idRoom || "");

  useEffect(() => {
    if (idRoom) {
      room.refetch();
      setData(room.data);
      scheduleType.refetch();
      setSchedule(scheduleType.data);
    } else setData(undefined)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRoom, room.data]);

  if (idRoom === undefined) return <Error message={t('invalidParams')} />;

  const skLang = i18n.language === 'sk';

  if (data === undefined) return <LoadingPage />;

  const { color } = data.topLevelDepartment || '#A29061';
  const { building } = data.room;

  const Employees = data?.employees.map((e: any) => (
    <Row key={e.idEmployee}>
      <PersonIcon color={color} />
      <div>
        <InternalLink
          href={`/people/${e.idEmployee}`}
          key={e.idEmployee}
          text={e.fullName}
        />
        <Text>{skLang ? e.position : e.positionEn}</Text>
      </div>
    </Row>
  ));

  return (
    <MotionList>
      <MotionItem key={1}>
        <Row>
          <RoomIcon color={color} />
          <div>
            <H1
              fontWeight={600}
            >{`${building.name}  - ${data.room.number}`}
            </H1>
            <Text>
              {skLang ? data.room.roomType.name : data.room.roomType.nameEn}
            </Text>
          </div>
        </Row>
      </MotionItem>
      <MotionItem key={2}>
        <H3 fontWeight={600}>{t('address')}</H3>
        <Row>
          <RoomIcon color={color} />
          <div>
            <InternalLink
              href={`/building/${building.name}`}
              text={building?.address}
            />
            <InternalLink
              href={`/level/${building.name}/${data.room.level}`}
              text={t(data.room.level)}
            />
          </div>
        </Row>
      </MotionItem>

      {!!data.employees.length && (
        <MotionItem key={3}>
          <H3 fontWeight={600}>{t('employees')}</H3>
          {Employees}
        </MotionItem>
      )}
      <MotionItem key={5}>
        <Grid>
          <Button
            variant="secondary"
            onClick={() => window.open(`/map/${building.name}`)}
          >
            {t('showOnMap')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => window.open(`https://at.tuke.sk/api/room/qr/${data.room.idRoom}`)}
          >
            {t('qrCode')}
          </Button>
          {schedule && (
            <Button
              variant="secondary"
              onClick={() => {
                navigate(
                  `/schedule/room/${data.room.idRoom}/${moment().format('yyyy-MM-DD')}`,
                );
              }}
            >
              {t('schedule')}
            </Button>
          )}
        </Grid>
      </MotionItem>
    </MotionList>
  );
}

export default Room;
