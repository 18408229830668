import { useQuery } from "@tanstack/react-query";

export interface RoomType {
  idRoomType: number;
  name: string;
  nameEn: string;
  pictogram: string;
}

export interface Room {
  idRoom: number;
  name: string;
  nameEn: string;
  roomType: RoomType;
  building: {
    address: string;
    idBuilding: number;
    latitude: number;
    longitude: number;
    name: string;
  };
}

const baseURL = "";

export const useRoom = (idRoom: string) =>
  useQuery({
    queryKey: ["room"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/room/${idRoom}`);

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to load room.");
      }

      return response.json();
    },
  });
