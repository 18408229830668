import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/use-auth';
import ExpandableSidebar from '../containers/Sidebar';

interface LayoutProps {
  children?: string | JSX.Element;
  watchFullwidth?: boolean;
}

const ContainerLayoutStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ContentWrapper = styled.div<{ fullwidth: boolean, isAuthenticated: boolean }>`
  position: relative;
  width: 100%;
  margin-top: ${({ fullwidth }) => fullwidth ? '0' : '1em'};
  margin-bottom: ${({ fullwidth }) => fullwidth ? '0' : '1em'};
  max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '900px')};
  padding: ${({ fullwidth }) => (fullwidth ? '0' : '0 15px')};
  margin-left: 18em;

  @media (max-width: 75em) {
    margin-top: ${({ fullwidth}) => fullwidth ? '0' : '5em'};
    margin-left: 0;
  }
`;


function ContainerLayout(props: LayoutProps) {
  const { isAuthenticated } = useAuth();
  const { children, watchFullwidth } = props;
  const location = useLocation();
  const fullwidthLayout =
    watchFullwidth && location.pathname.startsWith('/map');

  

  return (
    <ContainerLayoutStyled>
      <ExpandableSidebar />
      <ContentWrapper fullwidth={fullwidthLayout || false} isAuthenticated={isAuthenticated()}>
        {children}
      </ContentWrapper>
    </ContainerLayoutStyled>
  );
}

ContainerLayout.defaultProps = {
  children: null,
  watchFullwidth: false,
};

export default ContainerLayout;
