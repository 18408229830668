import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MotionItem, MotionList } from '../components/Motion';
import {
  ExternalLink,
  H1,
  H3,
  InternalLink,
  Text,
} from '../components/Typography';
import { Grid, Row } from '../components/Flex';
import { DepartmentIcon, PersonIcon, RoomIcon } from '../components/Icons';
import Button from '../components/Button';
import { usePerson } from '../hooks/api/use-person';
import { useScheduleType } from '../hooks/api/use-scheduletype';
import LoadingPage from './LoadingPage';

export default function() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const skLang = i18n.language === "sk";
  const [employee, setEmployee] = useState<any>();
  const [schedule, setSchedule] = useState<boolean>(false);
  const { idPerson } = useParams();

  const { data, refetch } = usePerson(idPerson || "");
  const scheduleType: any = useScheduleType("employee", idPerson || "");

  if (idPerson === undefined) return <>{t("invalidParams")}</>;

  useEffect(() => {
    if (idPerson) {
      refetch();
      setEmployee(data);
      scheduleType.refetch();
      setSchedule(scheduleType.data);
    } else setEmployee(undefined)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPerson, data]);

  if (employee === undefined) return <LoadingPage />;

  const { color } = employee.topLevelDepartment || "#A29061";
  let phoneNumbers: any;
  const positions: any = [];
  const buttons: any = [];
  const phones = employee.phone?.split(",");
  const primaryPhone = phones != null ? phones[0] : "";

  if (phones !== undefined) {
    phoneNumbers = phones.map((num: any) => (
      <ExternalLink href={`tel:${num}`} key={num} text={num} />
    ));
  } else {
    phoneNumbers = (
      <ExternalLink
        href={`tel:${employee.employee.phone}`}
        text={employee.employee.phone}
        hideIcon
      />
    );
  }

  const rooms = employee.rooms.map((r: any) => {
    const { room } = r;
    const name = `${room.building?.name} - ${room.number}`;
    const type = skLang ? room.roomType.name : room.roomType.nameEn;
    const roomDepartment = skLang ? r.department?.name : r.department?.nameEn;
    const positionName = skLang
      ? r.employeePosition?.name
      : r.employeePosition?.nameEn;

    buttons.push(
      <Button
        variant="secondary"
        onClick={() =>
          window.open(
            `https://at.tuke.sk/api/employee/vcard/${r.idRoomEmployeePosition}`,
          )
        }
      >
        {`${t("Vizitka")}`}&nbsp;<p>{positionName}</p>
      </Button>,
    );
    buttons.push(
      <Button
        variant="secondary"
        onClick={() =>
          window.open(
            `https://at.tuke.sk/api/employee/qr/vcard/${r.idRoomEmployeePosition}`,
          )
        }
      >
        {`${t("qrCodeCard")}`}&nbsp;<Text>{positionName}</Text>
      </Button>,
    );

    if (!positions.includes(positionName)) {
      positions.push(positionName);
    }

    return (
      <Row key={r.idRoom}>
        <RoomIcon color={color} />
        <div style={{ paddingBottom: '20px' }}>
          <InternalLink
            href={`/room/${r.idRoom}`}
            key={r.idRoom}
            text={name}
          />
          <Text>{type}</Text>

          <Text>{roomDepartment}</Text>
        </div>
      </Row>
    );
  });

  return (
    <MotionList>
      <MotionItem key={0}>
        <Row alignItems="flex-start">
          <PersonIcon color={color} />
          <div>
            <H1 fontWeight={600}>{employee.employee.fullName}</H1>
            {positions.map((p: string) => (
              <Text key={p}>{p}</Text>
            ))}
          </div>
        </Row>
      </MotionItem>
      <MotionItem key={1}>
        <H3 fontWeight={600}>{t("Kontakt")}</H3>
        <ExternalLink
          href={`mailto: ${employee.employee.email}`}
          text={employee.employee.email}
          hideIcon
        />
        {phoneNumbers}
      </MotionItem>
      <MotionItem key={10}>
        <H3 fontWeight={600}>{t("Pracovisko")}</H3>
        <Row alignItems="flex-start">
          <DepartmentIcon color={color} />
          <div>
            <InternalLink
              text={
                skLang ? employee.department.name : employee.department.nameEn
              }
              href={`/department/${employee.department.idDepartment}`}
              version="invisible"
            />
            <InternalLink
              text={
                skLang
                  ? employee.topLevelDepartment.name
                  : employee.topLevelDepartment.nameEn
              }
              href={`/department/${employee.topLevelDepartment.idDepartment}`}
              version="invisible"
            />
          </div>
        </Row>
      </MotionItem>
      <MotionItem key={2}>
        <H3 fontWeight={600}>{t("rooms")}</H3>
        {rooms}
      </MotionItem>

      <MotionItem key={3}>
        <Grid>
          <Button
            variant="secondary"
            onClick={() => window.open(`tel: ${primaryPhone}`)}
          >
            {t("call")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => window.open(`mailto: ${employee.employee.email}`)}
          >
            {t("send email")}
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              window.open(
                `https://res.tuke.sk/api/vupch/${employee.employee.idEmployee}/export`,
              )
            }
          >
            {t("VUPCH")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => window.open(employee.orcidUrl)}
          >
            {t("ORCID")}
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              window.open(`https://publons.com${employee.publonsUrl}`)
            }
          >
            {t("Publons")}
          </Button>
          {schedule && (
            <Button
              variant="secondary"
              onClick={() => {
                navigate(
                  `/schedule/employee/${idPerson}`,
                );
              }}
            >
              <Text align='left'>{t("schedule")}</Text>
            </Button>
          )}

          {buttons}
        </Grid>
      </MotionItem>
    </MotionList>
  );
};
