/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SatisfactionForm from "../components/forms/SatisfactionForm";
import useAuth from "../hooks/use-auth";
import { useSchedule } from "../hooks/api/use-account";
import { useSemester } from "../hooks/api/use-semester";
import { Appointment, TimeTable } from "../components/Schedule";
import Announcement from "../components/Announcement";
import Margin from "../components/Margin";
import { useEmployeeSchedule } from "../hooks/api/use-schedule";
import { AppointmentFactory } from "../helper/util";
import { TextSmall } from "../components/Typography";
import Overlay from "../components/Overlay";

export const EmployeeSchedule: FC = () => {
  const { user } = useAuth();
  const semester = useSemester();
  const employeeSchedule = useEmployeeSchedule(user?.idUser || 0);
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  useEffect(() => {
    if (employeeSchedule.data && semester.data) {
      setAppointments(AppointmentFactory(employeeSchedule.data, new Date(semester.data.startDate)) || []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeSchedule.data, semester.data]);

  return <TimeTable appointments={appointments} editable={false} />
}

export default function () {
  const { t } = useTranslation();
  const { isEmployee } = useAuth();
  const schedule = useSchedule();
  const semester = useSemester();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [isOverlayShown, setIsOverlayShown] = useState<boolean>(false);

  useEffect(() => {
    if (schedule.data && semester.data) {
      const startDate: Date = new Date(semester.data.startDate);
      setAppointments(AppointmentFactory(schedule.data, startDate) || []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule.data, semester.data]);

  return (
    <>
      <Margin direction="bottom" size={1}>
        <Announcement onClick={() => {setIsOverlayShown(true)}}>
          <span>👋</span>
          <TextSmall>
            {t('announcement')}
          </TextSmall>
        </Announcement>
      </Margin>

      {isEmployee() ? (
        <EmployeeSchedule />
      ) : (
        <TimeTable appointments={appointments} editable />
      )}

      <Overlay isOpen={isOverlayShown} onClose={() => {setIsOverlayShown(false)}}>
        <SatisfactionForm onSubmitCallback={() => {setIsOverlayShown(false)}} />
      </Overlay>
    </>
  );
}
