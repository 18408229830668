import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Row } from '../Flex';
import { RoomIcon } from '../Icons';

interface Props {
  room: any;
}
const DetailLink = styled.a`
  height: 40px;
  width: 100%;
  background: #111111;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  transition: 0.15s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.9);
  }
`;

function MapRoomDetail(props: Props) {
  const { room } = props;
  const { t } = useTranslation();

  return (
    <div className="">
      <Row alignItems="center">
        <RoomIcon color="#BDBDBD" className="mr-3" />
        <div className="flex flex-column">
          <div className="font-bold">
            {t('room')} {room?.room.number}
          </div>
          <div className="text-sm">{room?.room.roomType.name}</div>
        </div>
      </Row>

      <div className=" mt-1">
        <div className="font-semibold text-sm">{t('address')}</div>
        <div className="text-sm">{room?.room.building.address}</div>
        <div className="mb-2 text-sm">{room?.room.level}</div>

        <DetailLink href={`/room/${room?.room.idRoom}`} target="_blank">
          {t('detail')}
        </DetailLink>
      </div>
    </div>
  );
}

MapRoomDetail.defaultProps = {};

export default MapRoomDetail;
