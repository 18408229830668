import styled from 'styled-components';
import { motion, useTransform, useScroll } from 'framer-motion';

const Path = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: showColored 6s linear forwards;
  -moz-animation: showColored 6s linear forwards;
  -o-animation: showColored 6s linear forwards;
  animation: showColored 6s linear forwards;
  fill: none;
  stroke-width: 2.90801;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  stroke-opacity: 1;

  @keyframes showColored {
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

const Svg = styled.svg`
  position: relative;
`;

const LogoComponent = () => {
  const { scrollYProgress } = useScroll();
  const w = window.innerWidth;
  const maxWidth = w > 880 ? 880 : w * 0.8;
  const width = useTransform(scrollYProgress, [0, 1], [maxWidth, w * 0.2]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '10px',
      }}
    >
      <motion.div
        style={{ width }}
      >
        <Svg id="svg901" version="1.1" viewBox="0 0 264 108">
          <Path
            style={{ stroke: '#41b6e6' }}
            id="path862"
            d="M 203.32605,12.47024 177.69621,92.4669 h 29.49562 l 10.61853,-34.54838"
          />
          <Path
            style={{ stroke: '#a51890' }}
            d="M 96.7892,12.09483 V 92.4373 h 30.30284 l -0.0911,-27.20828"
          />
          <Path
            style={{ stroke: '#003da5' }}
            id="path858"
            d="M 143.30055,41.691762 143.21185,13.46086 H 172.7379 V 93.83032"
          />
          <Path
            style={{ stroke: '#d50032' }}
            id="path868"
            d="M 7.8472765,40.92252 7.4822785,74.01881 C 8.8196865,90.21505 20.675513,95.07101 30.001755,95.55918 L 69.58779,95.39034 C 84.63304,92.94625 89.20953,83.45955 89.96802,71.40438 L 89.69537,38.765552"
          />
          <Path
            style={{ stroke: '#84bd00' }}
            id="path874"
            d="m 203.39121,14.3462 25.18116,78.12069 h 29.50364"
          />
          <Path
            style={{ stroke: '#00843d' }}
            d="m 201.94864,13.45587 31.43081,-0.0113 25.30163,80.14348"
            id="path876"
          />
          <Path
            style={{ stroke: '#e57200' }}
            id="path850"
            d="m 8.0042815,13.32267 -0.152087,26.421692 26.9764645,0.164558 0.148299,27.42802 27.576254,-0.14829 0.44478,-26.983259 25.284378,-0.01511"
          />
          <Path
            style={{ stroke: '#eaaa00' }}
            id="path848"
            d="M 6.5532785,13.29779 89.49772,13.71262 89.69537,38.765552"
          />
          <Path
            style={{ stroke: '#5f259f' }}
            id="path856"
            d="m 97.62253,13.44463 44.76233,79.02229 h 29.50363 L 127.12617,13.44463 Z"
          />
        </Svg>
      </motion.div>
    </div>
  );
};

export const Logo = LogoComponent;
