import styled from 'styled-components';

const Icon = styled.i<{ color?: string; size?: number; weight?: number }>`
  width: ${(props) => (props.size ? `${props.size}px` : '1.5em')};
  color: ${(props) => props.theme.colors[props.color ?? 'inherit']};
  font-weight: ${(props) => (props.weight ? `${props.weight}` : '400')};

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
`;

export default Icon;
