import React, { type FC, type ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Card } from './Card';

interface SegmentProps {
  children: React.ReactNode;
}

const SegmentsContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 0.2em;
  border-radius: ${({ theme }) => theme.rounded.large};
  gap: 0.2em;
`;

const ButtonContainer = styled.button<{ isActive: boolean }>`
  width: 100%;
  border: none;
  padding: 0.5em 1em 0.55em 1em;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.detail};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.medium};

  ${({ isActive, theme }) => isActive && `
    box-shadow: ${theme.shadow.base};
    font-weight: ${theme.weights.medium};
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
    cursor: default;
  `}

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

interface SegmentButtonProps {
  children: ReactNode;
}

const SegmentButton: FC<SegmentButtonProps> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <>
      <SegmentsContainer>
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <ButtonContainer
                isActive={activeIndex === index}
                onClick={() => {
                  handleButtonClick(index);
                }}
              >
                {child.props.label}
              </ButtonContainer>
            );
          }
          return null;
        })}
      </SegmentsContainer>

      {React.Children.toArray(children)[activeIndex]}
    </>
  );
};

interface SegmentProps {
  label: string;
  children: ReactNode;
}

export const Segment: FC<SegmentProps> = ({ label, children }) => (
  <Card key={label}>{children}</Card>
);

export default SegmentButton;
