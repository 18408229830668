import React, { useState, type FC } from 'react';
import styled from 'styled-components';
import { H2 } from '../Typography';
import Margin from '../Margin';

interface SliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-contente: space-between;
  gap: 1.5em;
  width: 100%;
  margin: 1.75em 1em 0.75em 0;
`;

const InputRange = styled.input<{ percentage: number }>`
  width: 100%;
  -webkit-appearance: none;
  height: 0.5em;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.large};
  outline: none;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    bottom: 0.5em;
    border-radius: 50%;
    border: none;
    box-shadow: ${({ theme }) => theme.shadow.large};
    background-color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &::-moz-range-thumb {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: none;
    box-shadow: ${({ theme }) => theme.shadow.large};
    background-color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5em;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.primary} 0%,
      ${({ theme }) => theme.colors.primary} ${({ percentage }) => percentage}%,
      ${({ theme }) => theme.colors.secondary}
        ${({ percentage }) => percentage}%,
      ${({ theme }) => theme.colors.secondary} 100%
    );
    border-radius: ${({ theme }) => theme.rounded.large};
  }

  &::-moz-range-track {
    width: 100%;
    height: 0.5em;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.primary} 0%,
      ${({ theme }) => theme.colors.primary} ${({ percentage }) => percentage}%,
      ${({ theme }) => theme.colors.secondary}
        ${({ percentage }) => percentage}%,
      ${({ theme }) => theme.colors.secondary} 100%
    );
    border-radius: ${({ theme }) => theme.rounded.large};
  }
`;

const Slider: FC<SliderProps> = ({ min, max, value, onChange }) => {
  const [sliderValue, setSliderValue] = useState<number>(value);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setSliderValue(newValue);
    onChange(newValue);
  };

  return (
    <SliderContainer>
      <InputRange
        type="range"
        min={min}
        max={max}
        value={sliderValue}
        onChange={handleSliderChange}
        percentage={(sliderValue * 100) / max}
      />
      <Margin direction="right" size={1.5}>
        <H2 style={{ display: 'flex' }}>
          {sliderValue}
          <Margin direction="x" size={0.2}>
            /
          </Margin>
          {max}
        </H2>
      </Margin>
    </SliderContainer>
  );
};

export default Slider;
