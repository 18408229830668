import React, { type ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

interface BadgeProps extends ComponentPropsWithoutRef<'span'> {
  variant: 'primary' | 'secondary' | 'outline';
  subject?: string;
  children?: React.ReactNode;
}

const BadgeWrapper = styled.span<{ variant: string }>`
  font-size: 0.75em;
  padding: 0.25em 0.875em;
  border-radius: ${({ theme }) => theme.rounded.full};
  cursor: default;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-weight: ${({ theme }) => theme.weights.medium};

  /* Primary Badge */
  ${({ variant }) => variant === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.text};
    `}

  /* Secondary Badge */
  ${({ variant }) => variant === 'secondary' &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary};
      color: ${({ theme }) => theme.colors.text};
    `}
  
  /* Outline Badge */
  ${({ variant }) => variant === 'outline' &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.text};
      border: ${({ theme }) => theme.border};
    `}
`;

const Badge: React.FC<BadgeProps> = ({
  variant,
  subject,
  children,
  ...rest
}) => {
  const { t } = useTranslation('translation', { keyPrefix: '_subjects' });
  let updatedChildren = children;

  if (subject !== "") {
    updatedChildren = t([`${subject}`, 'unknown']);
  }

  return (
    <BadgeWrapper variant={variant} {...rest}>
      {subject !== "" && (
          <span style={{ fontWeight: '900' }}>{subject}</span>
        )}
      {updatedChildren}
    </BadgeWrapper>
  );
};

export default Badge;

Badge.defaultProps = {
  subject: "",
  children: null,
};
