import styled from 'styled-components';
import {
    Apartment,
    Person,
    DoorFront,
    KeyboardArrowRight,
    KeyboardArrowLeft,
    Layers,
    People,
    LocationOn,
    Close,
    Restaurant,
    StarOutline,
    Star,
} from '@styled-icons/material-rounded';

interface IconProps {
    color?: string;
    borderSize?: string;
}

export const LeftArrow = styled(KeyboardArrowLeft)<IconProps>`
    color: ${({ color }) => color};
`;

export const RightArrow = styled(KeyboardArrowRight)<IconProps>`
    color: ${({ color }) => color};
`;

export const ApartmentIcon = styled(Apartment)<IconProps>`
    color: ${({ color }) => color || 'white'};
`;

export const PersonI = styled(Person)<IconProps>`
    color: ${({ color }) => color || 'white'};
`;

export const DoorFrontIcon = styled(DoorFront)<IconProps>`
    color: ${({ color }) => color || 'white'};
`;

export const LayersIcon = styled(Layers)<IconProps>`
    color: ${({ color }) => color || 'white'};
`;

export const PeopleIcon = styled(People)<IconProps>`
    color: ${({ color }) => color || 'white'};
`;

export const LocationIcon = styled(LocationOn)<IconProps>`
    color: ${({ color }) => color || 'white'};
`;

export const CloseIcon = styled(Close)<IconProps>`
    color: ${({ color }) => color || 'white'};
`;

export const IconRestaurant = styled(Restaurant)<IconProps>`
    color: ${({ color, theme }) => color || theme?.colors?.text};
`;

export const IconStarOutline = styled(StarOutline)<IconProps>`
    color: ${({ color, theme }) => color || theme?.colors?.text};
`;

export const IconStar = styled(Star)<IconProps>`
    color: ${({ color, theme }) => color || theme?.colors?.text};
`;

const IconBackground = styled.span<IconProps>`
    width: ${({ borderSize }) => borderSize || '40px'};
    height: ${({ borderSize }) => borderSize || '40px'};
    margin: 10px 23px 10px 5px;
    background-color: ${({ color }) => color || 'gray'};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const BuildingIcon = (props: any) => (
    <IconBackground {...props}>
        <ApartmentIcon color="" size={30} />
    </IconBackground>
);

export const LevelIcon = (props: any) => (
    <IconBackground {...props}>
        <LayersIcon color="" size={30} />
    </IconBackground>
);

export const PersonIcon = (props: any) => (
    <IconBackground {...props}>
        <PersonI color="" size={30} />
    </IconBackground>
);

export const RoomIcon = (props: any) => (
    <IconBackground {...props}>
        <DoorFrontIcon color="" size={30} />
    </IconBackground>
);

export const DepartmentIcon = (props: any) => (
    <IconBackground {...props}>
        <PeopleIcon color="" size={30} />
    </IconBackground>
);

export const RestaurantIcon = (props: any) => (
    <IconBackground {...props}>
        <IconRestaurant color="" size={30} />
    </IconBackground>
);
