import moment from "moment";
import { type Canteen, type Floor, type BuildingFloor } from "./api.types";

// const urlBase = process.env.REACT_APP_TUNA_URL;
const urlBase = "";
// const urlBase = 'http://localhost:8080';

export const getRoom = async (
  idRoom: number | string | undefined
): Promise<any[]> => {
  const url = `${urlBase}/api/room/${idRoom}`;
  const r = await fetch(url);
  const data = await r.json();
  return data;
};

export const getFloor = async (
  idBuilding: string | undefined,
  level: string | undefined
): Promise<Floor> => {
  const request = await fetch(`${urlBase}/api/floor/${idBuilding}/${level}`);
  const response = await request.json();
  return response;
};

export const getFloors = async (
  idBuilding: string | undefined
): Promise<BuildingFloor> => {
  const request = await fetch(`${urlBase}/api/floor/${idBuilding}`);
  const response = await request.json();
  return response;
};

// TODO: put it inside a use-canteen hook
export const getCanteens = async (): Promise<Canteen[]> => {
  const request = await fetch(`${urlBase}/api/canteen/`);
  const response = await request.json();
  return response;
};

export const getCanteen = async (idCanteen: string): Promise<Canteen> => {
  const request = await fetch(`${urlBase}/api/canteen/${idCanteen}`);
  const response = await request.json();
  return response;
};

export const getCanteenMenu = async (
  idCanteen: number,
  date: Date
): Promise<Canteen["menus"][number]["items"]> => {
  const request = await fetch(
    `${urlBase}/api/canteen/${idCanteen}/${moment(date).format("YYYY-MM-DD")}`
  );
  const response = await request.json();
  return response;
};

export const rateCanteenFood = async (
  idCanteenFood: number
): Promise<boolean> => {
  const request = await fetch(`${urlBase}/api/canteen/rate/${idCanteenFood}`);
  const response = await request.json();
  return response;
};
