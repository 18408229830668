import React, { useState, type FC, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { Column, Row } from '../Flex';
import { Text } from '../Typography';

interface RollerProps {
  options: string[];
  onItemSelected: (item: number) => void;
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Picker = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  grid-template-columns: repeat(4, fit-content(500px));
  position: relative;
  font-feature-settings: "tnum";
  font-weight: 400;
  font-size: 1.25em;
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3.5em;
    pointer-events: none;
  }
  &:before {
    top: -0.2em;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.background},
      ${({ theme }) => theme.colors.background50}
    );
  }
  &:after {
    bottom: -0.3em;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.background50},
      ${({ theme }) => theme.colors.background}
    );
  }

  ul {
    width: 100%;
    max-height: 8em;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    padding-bottom: 2em;
    padding-top: 2em;
    span {
      padding-left: 0.5em;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  li {
    scroll-snap-align: center;
    height: 1.4em;
    text-align: center;
    word-spacing: 0.2em;
  }
`;

const PickerWindow = styled.div`
  position: absolute;
  border-top: ${({ theme }) => theme.border};
  border-bottom: ${({ theme }) => theme.border};
  height: 1.4em;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  pointer-events: none;
`;

const Roller: FC<RollerProps> = ({ options, onItemSelected }) => {
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const listRefCurrent = listRef.current;
    const handleScroll = () => {
      if (listRefCurrent) {
        const centerIndex = Math.round(listRefCurrent.scrollTop / 28); // Adjust as needed based on your item height
        onItemSelected(centerIndex);
      }
    };

    if (listRefCurrent) {
      listRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listRefCurrent) {
        listRefCurrent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [onItemSelected]);

  return (
    <InputContainer>
      <Picker>
        <PickerWindow />
        <ul ref={listRef}>
          <li />
          {options.map((option) => (
            <li key={option}>{option}</li>
          ))}
          <li />
        </ul>
      </Picker>
    </InputContainer>
  );
};

interface RollingPickerProps {
  label: string;
  name: string;
  value?: number;
  options: string[];
  validation: any;
}

const PickerTab = styled.button`
  padding: 0.5em 1em;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.medium};
  font-size: 1em;
  cursor: pointer;
`;

const ValueContainer = styled.span`
  padding: 0.25em 0.5em;
  box-shadow: ${({ theme }) => theme.shadow.base};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.rounded.medium};
`;

const RollingPicker: FC<RollingPickerProps> = ({
  name,
  label,
  options,
  value,
  validation,
}) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(value || -10);
  const { t } = useTranslation();

  const handleInputChange = (index: number) => {
    setSelectedIndex(index);
    setValue(name, index);
  };

  const toggleSelector = () => {
    if (!isSelecting) handleInputChange(0);
    setIsSelecting(!isSelecting);
  };

  return (
    <>
      <input
        type="hidden"
        value={selectedIndex}
        {...register(name, validation)}
      />
      <Column>
        <PickerTab type="button" onClick={toggleSelector} name="dueWeek">
          <Row justifyContent="space-between" alignItems="center">
            <label htmlFor={name}>{label}</label>
            {selectedIndex !== -10 ? (
              <ValueContainer>
                <Text>{options[selectedIndex as number]}</Text>
              </ValueContainer>
            ) : (
              <Text color="detail" fontWeight={500}>
                {t('select')} ›
              </Text>
            )}
          </Row>
        </PickerTab>

        <ErrorMessage errors={errors} name={name} />
      </Column>

      {isSelecting && (
        <Roller options={options} onItemSelected={handleInputChange} />
      )}
    </>
  );
};

export default RollingPicker;

RollingPicker.defaultProps = {
  value: -10,
}
