import React, { useEffect, type FC, useState, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';

interface OverlayProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const slideInDesktop = keyframes`
  from {
    transform: translateY(200%);
    bottom: 0;
    opacity: 0;
  }
  to {
    transform: translateY(50%);
    bottom: 50%;
    opacity: 100%;
  }
`;

const slideInMobile = keyframes`
  from {
    transform: translateY(200%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 100%;
  }
`;

const slideOutDesktop = keyframes`
  from {
    transform: translateY(50%);
    bottom: 50%;
  }
  to {
    transform: translateY(200%);
    bottom: 0;
    opacity: 0;
  }
`;

const slideOutMobile = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200%);
    opacity: 0;
  }
`;

const Dimming = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
`;

const Window = styled.div<{ height: string; isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  opacity: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: ${({ height }) => height};
  overflow: hidden;
  padding: 0 1em 1em 1em;
  background-color: ${({ theme }) => theme.colors.background};
  border-top-left-radius: ${({ theme }) => theme.rounded.large};
  border-top-right-radius: ${({ theme }) => theme.rounded.large};
  box-shadow: ${({ theme }) => theme.shadow.medium};
  ${({ isOpen }) => (isOpen
      ? css`
          animation: ${slideInMobile} 0.3s ease forwards;
        `
      : css`
          animation: ${slideOutMobile} 0.3s ease-out forwards;
        `)};

  @media (min-width: 75em) {
    max-width: 900px;
    margin-left: calc(50% - 18em - 1em);
    padding: 2em;
    border-radius: ${({ theme }) => theme.rounded.large};
    ${({ isOpen }) => (isOpen
        ? css`
            animation: ${slideInDesktop} 0.3s ease forwards;
          `
        : css`
            animation: ${slideOutDesktop} 0.3s ease forwards;
          `)};
  }
`;

const Content = styled.div`
  max-width: 900px;
  margin: auto;
`;

const DragLine = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 4em;
    height: 0.4em;
    border-radius: 9999px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  @media (min-width: 75em) {
    display: none;
  }
`;

const Overlay: FC<OverlayProps> = ({ isOpen, onClose, children }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startY, setStartY] = useState<number>(0);
  const [startHeight, setStartHeight] = useState<number>(0);

  const dragger = useRef<HTMLDivElement>(null);
  const sheet = useRef<HTMLDivElement>(null);

  const [overlayHeight, setOverlayHeight] = useState<string>('fit-content');

  const dragStart = (e: any) => {
    setStartY(e.pageY || e.touches?.[0].pageY);
    const height: number | undefined =
      sheet.current?.getBoundingClientRect().height;
    const windowHeight: number = window.innerHeight;

    if (height !== undefined) {
      const calculatedHeight: number = (height / windowHeight) * 100;
      setStartHeight(calculatedHeight - 3);
    } else {
      setStartHeight(0);
    }
    setIsDragging(true);
  };

  const dragging = (e: any) => {
    if (!isDragging) return;
    const delta = startY - (e.pageY || (e.touches && e.touches[0]?.pageY) || 0);
    const newHeight = startHeight + (delta / window.innerHeight) * 100;

    if (newHeight <= startHeight) {
      setOverlayHeight(`${newHeight}vh`);
    }
  };

  const dragStop = () => {
    setIsDragging(false);
  };

  const handleDimmingClick = (e: any) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const heightStringToNumber = (height: string) => {
    const regex = /^(\d+(\.\d+)?)vh$/;
    const match = height.match(regex);
    if (match) {
      return parseInt(match[1], 10);
    } 
      return null;
  };

  useEffect(() => {
    if (!isDragging) {
      const height: number | null = heightStringToNumber(overlayHeight);

      if (height && startHeight - height >= 5) onClose();
      setOverlayHeight('fit-content');
    }
  }, [isDragging, overlayHeight, onClose, startHeight]);

  // Add body styles to prevent scrolling when the overlay is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <Window ref={sheet} height={overlayHeight} isOpen={isOpen}>
        <Content>
          <DragLine
            ref={dragger}
            onMouseDown={dragStart}
            onTouchStart={dragStart}
            onMouseUp={dragStop}
            onTouchEnd={dragStop}
            onMouseMove={dragging}
            onTouchMove={dragging}
          >
            <div />
          </DragLine>
          {children}
        </Content>
      </Window>
      <Dimming onClick={handleDimmingClick} />
    </>
  ) : null;
};

export default Overlay;

Overlay.defaultProps = {
  children: null,
};
