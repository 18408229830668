import React, { type FC } from 'react';
import styled from 'styled-components';
import Tooltip from './Tooltip';

interface HelpProps {
  text: string;
}

const Questionmark = styled.div`
  position: relative;
  width: 1.5em;
  height: 1.5em;
  font-weight: 700;
  font-size: 0.8em;
  color: ${({ theme }) => theme.colors.detail};
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.full};

  &::before {
    content: "?";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -52%);
  }
`;

const Help: FC<HelpProps> = ({ text }) => (
    <Tooltip text={text}>
      <Questionmark />
    </Tooltip>
  );

export default Help;
