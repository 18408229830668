import React, { useEffect, type FC, useState, useCallback } from 'react';
import styled from 'styled-components';
import { MeetingRoom, Person, Schedule as ScheduleIcon } from '@styled-icons/material-rounded';
import { useLocation, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  H2,
  H3,
  TextSmall,
  InternalLink,
} from '../components/Typography';
import { Column, Row } from '../components/Flex';
import SegmentedButton, { Segment } from '../components/SegmentedButton';
import { Card } from '../components/Card';
import Assignment from '../components/Assignment';
import AddAssignment from '../components/AddAssignment';
import Icon from '../components/Icon';
import Skeleton from '../components/Skeleton';
import Help from '../components/Help';
import {
  type Assignment as AProps,
  useAssignment,
  useCourseAssignments,
} from '../hooks/api/use-assignment';
import ImportAssignment from '../components/ImportAssignment';
import { useCourseName } from '../hooks/api/use-course';
import Error from './Error';
import { useSchedule } from '../hooks/api/use-account';
import { Lesson } from '../hooks/api/use-lesson';
import { Container } from '../components/Container';
import useAuth from '../hooks/use-auth';
import LostImage from '../assets/images/connection_lost.svg';
import Button from '../components/Button';
import LoadingPage from './LoadingPage';

const VerticalDivider = styled.div`
  border-right: 2px solid ${({ theme }) => theme.colors.secondary};
`;

const Title = styled(H2)`
  margin: 1em;
  text-align: center;
  text-wrap: balance;
`;

const ColorCircle = styled.div<{ color?: string }>`
  position: relative;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: ${({ color }) => color ?? 'linear-gradient(222deg, #10b981 17.66%, #12da98 83.43%)'};

  &::before {
    content: "";
    position: absolute;
    top: calc(-1px - 0.1em); // border: 1px + offset: 0.1em;
    right: calc(-1px - 0.1em); // border: 1px + offset: 0.1em;
    bottom: calc(-1px - 0.1em); // border: 1px + offset: 0.1em;
    left: calc(-1px - 0.1em); // border: 1px + offset: 0.1em;
    border: ${({ theme }) => theme.border};
    border-radius: 50%;
  }
`;

const SectionWrapper = styled.section<{ margin?: number }>`
  margin: ${({ margin = 1 }) => margin}em 0;
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const getSharedId = (location: any): number | undefined => {
  const queryParams = new URLSearchParams(location.search);
  const assignmentId: number | undefined = parseInt(
    queryParams.get('share') ?? '', 10
  );
  return assignmentId;
};

const CoursePage: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { courseName } = useParams();
  const { login, isAuthenticated } = useAuth();
  const [receivedPoints, setReceivedPoints] = useState<number>(0);
  const [score, setScore] = useState<number>(0);
  const [grade, setGrade] = useState<string>('FX');
  const [lessons, setLessons] = useState<Lesson[]>([]);

  const course = useCourseName(courseName || "");
  const schedule = useSchedule();
  const { data } = useAssignment(getSharedId(location));

  const assignments = useCourseAssignments(course.data?.id ?? 0);

  useEffect(() => {
    if (course.data && schedule.data) {
      const courseLessons: Lesson[] | undefined = schedule.data?.filter((l: Lesson) => course.data.name === l.name);
      if (courseLessons) setLessons(courseLessons);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule.data, course.data]);

  const getReceivedPoints = useCallback(() => {
    let points = 0;
    if (assignments.data) {
      assignments.data.forEach((a: AProps) => {
        points += a.points;
      });
    }
    return points;
  }, [assignments.data]);

  const getMaxPoints = useCallback(() => {
    let maxPoints = 0;
    if (assignments.data) {
      assignments.data.forEach((a: AProps) => {
        maxPoints += a.maxPoints;
      });
    }
    return maxPoints;
  }, [assignments.data]);

  const getGrade = (points: number) => {
    if (points > 90) return 'A';
    if (points > 80) return 'B';
    if (points > 70) return 'C';
    if (points > 60) return 'D';
    if (points > 50) return 'E';
    return 'FX';
  };

  useEffect(() => {
    setReceivedPoints(getReceivedPoints());
    const successRate: number =
      Math.floor((getReceivedPoints() * 100) / getMaxPoints()) || 0;
    setScore(successRate);
    setGrade(getGrade(successRate));
  }, [assignments.data, getMaxPoints, getReceivedPoints]);

  const sortedAssignments =
  assignments.data && assignments.data.length !== 0
    ? assignments.data.slice().sort((a, b) => a.dueWeek - b.dueWeek)
    : [];

  // useEffect(() => {
  //   sortedAssignments =
  //   assignments.data && assignments.data.length !== 0
  //     ? assignments.data.slice().sort((a, b) => a.dueWeek - b.dueWeek)
  //     : [];
  // }, [assignments.data])

  if (courseName === undefined) return <Error message={t('forms.courseNameMissing')} />;

  const assignment: AProps | undefined = data;

  if (!isAuthenticated) login();

  if (!assignments) return <Error message={t('assignment.notFound')} />;

  if (assignments.isError) {
    toast.error(t('assignment.toastNotFound'));
  }

  return (
    <main>
      {course.isLoading && <LoadingPage />}
      {course.isError && (
        <Column alignItems="center">
          <img
            src={LostImage}
            alt={t('course.lostImgAlt') || ''}
            style={{ width: '12em', margin: '1em 0 1.5em 0' }}
          />
          <H3 align="center" fontWeight={600}>{t('course.notFound')}</H3>
          <TextSmall align="center">{t('course.notEnrolled')}</TextSmall>
          <Button
            variant="primary"
            style={{ margin: '1em' }}
            onClick={() => {
              window.location.href = '/schedule';
            }}
          >
            <TextSmall fontWeight={500}>{t('_schedule.set')}</TextSmall>
          </Button>
        </Column>
      )}
      {course.isSuccess && (
        <>
          <SectionWrapper margin={2}>
            <Row justifyContent="center">
              <ColorCircle color={course.data?.color} />
            </Row>
            <Title fontWeight={600}>{course.data?.name}</Title>
          </SectionWrapper>

          <SectionWrapper>
            <SegmentedButton>
              {lessons &&
                lessons.length !== 0 &&
                lessons.map((lesson, index) => (
                    <Segment
                      key={`segment-${index}`}
                      label={t([`_subjects.${lesson.type}`, 'unknown'])}
                    >
                      <IconText>
                        <Icon>
                          {' '}
                          <MeetingRoom />{' '}
                        </Icon>
                        <InternalLink
                          text={lesson.room}
                          href={`/room/${lesson.idRoom}`}
                          version="invisible"
                        />
                      </IconText>
                      <IconText>
                        <Icon>
                          {' '}
                          <Person />{' '}
                        </Icon>
                        <InternalLink
                          text={lesson.teacher}
                          href={`/people/${lesson.teachers && lesson.teachers[0]?.idEmployee || 'null'}`}
                          version="invisible"
                        />
                      </IconText>
                      <IconText>
                        <Icon>
                          {' '}
                          <ScheduleIcon />{' '}
                        </Icon>
                        <span>
                          {new Date(lesson.start).getHours()}:
                          {new Date(lesson.start).getMinutes()} -{' '}
                          {new Date(lesson.end).getHours()}:
                          {new Date(lesson.end)
                            .getMinutes()
                            .toString()
                            .padEnd(2, '0')}
                        </span>
                      </IconText>
                    </Segment>
                  ))}
            </SegmentedButton>
          </SectionWrapper>

          <SectionWrapper>
            <Row
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '1.25em 0 0.75em 0' }}
            >
              <Row alignItems="center" style={{ gap: '0.5em' }}>
                <H3 fontWeight={600}>{t('course.assignmentsAndExams')}</H3>
                <Help text={t('course.tracker')} />
              </Row>
              <AddAssignment />
            </Row>
            <Container>
              <Row style={{ gap: '1.5em' }} alignItems="stretch" justifyContent="space-between">
                <Column>
                  <Row alignItems="baseline" style={{ gap: '0.4em' }}>
                    <H3 fontWeight={600}>{receivedPoints}</H3>
                    <TextSmall fontWeight={500} color="detail">
                      /{getMaxPoints()}
                    </TextSmall>
                  </Row>
                  <TextSmall fontWeight={500} color="detail">
                    {t('5+points').toUpperCase()}
                  </TextSmall>
                </Column>
                <VerticalDivider />
                <Column>
                  <Row alignItems="baseline" style={{ gap: '0.4em' }}>
                    <H3 fontWeight={600}>{score}</H3>
                    <TextSmall fontWeight={500} color="detail">
                      %
                    </TextSmall>
                  </Row>
                  <TextSmall fontWeight={500} color="detail">
                    {t('successRate').toUpperCase()}
                  </TextSmall>
                </Column>
                <VerticalDivider />
                <Column>
                  <H3 fontWeight={600}>{grade}</H3>
                  <TextSmall fontWeight={500} color="detail">
                    {t('grade').toUpperCase()}
                  </TextSmall>
                </Column>
              </Row>
            </Container>

            <ul>
              {assignments.isLoading ? (
                <Card>
                  <Skeleton width="10%" height="0.75em" />
                  <Skeleton width="40%" height="1em" />
                </Card>
              ) : (
                sortedAssignments.map((a: AProps, index: number) => (
                  <li key={`${a.id}-${index}`}>
                    <Assignment assignment={a} clickable />
                  </li>
                ))
              )}
            </ul>
          </SectionWrapper>
        </>
      )}

      {assignment && course.data && <ImportAssignment assignment={assignment} />}
    </main>
  );
};

export default CoursePage;
