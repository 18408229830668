export interface buildingApiType {
  address: string;
  idBuilding: number;
  latitude: number;
  levels: any;
  longitude: number;
  name: string;
}

const baseURL = "";

export const useBuilding = () => {
  const getBuildingById = async (
    id: string | undefined
  ): Promise<buildingApiType> => {
    const request = await fetch(`${baseURL}/api/building/${id}`);
    const response = await request.json();
    return response;
  };

  return getBuildingById;
};
