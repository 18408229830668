import styled from 'styled-components';

interface ModalProps {
  children?: string | JSX.Element;
  closeModal: () => void;
  size?: string;
}

const ModalWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999999;
  box-sizing: border-box;
`;

const ModalLayout = styled.div`
  display: flex;
  height: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  box-sizing: border-box;
`;

const ModalPanel = styled.div.attrs((props: { size: string }) => props)`
  background: white;
  max-width: 100%;
  box-sizing: border-box;
  color: black;
  width: ${(props: any) => props.size};
`;

export function Modal(props: ModalProps) {
  const { children, closeModal, size } = props;

  const getModalSize = () => {
    if (size === 'sm') {
      return '300px';
    }
    if (size === 'md') {
      return '600px';
    }
    if (size === 'lg') {
      return '900px';
    }
    return '600px';
  };

  return (
    <ModalWrapper>
      <ModalLayout onClick={() => closeModal()}>
        <ModalPanel onClick={(e) => e.stopPropagation()} size={getModalSize()}>
          <div>{children}</div>
        </ModalPanel>
      </ModalLayout>
    </ModalWrapper>
  );
}

Modal.defaultProps = {
  children: null,
  size: 'md',
};
