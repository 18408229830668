import { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
/* eslint import/no-extraneous-dependencies: off */
import Map, {
  Source,
  Layer,
  Popup,
  NavigationControl,
  FullscreenControl,
} from 'react-map-gl';
import { useBuilding } from '../hooks/api/use-building';
/* eslint import/no-webpack-loader-syntax: off */
// @ts-ignore
import mapboxgl from '!mapbox-gl';
import { getFloor, getFloors, getRoom } from '../api/api';
import '../styles/MapStyles.css';
import { CloseIcon } from '../components/Icons';
import { Modal } from '../components/Modal';
import { NEW_MAP_DATA, MARKERS } from '../store/MapConstants.js';
import {
  MAP_ICONS,
  BUILDINGS_LAYER,
  BUILDING_LABELS_LAYER,
  FLOOR_CORRIDOR_LAYER,
  FLOOR_CORRIDOR_ROOM_LAYER,
  MARKERS_LAYER,
  buildMapDataMarkers,
  buildMapDataBuildings,
  buildMapDataBuildingLabels,
  buildFloorCorridors,
} from '../utils/mapUtils';
import MapBuildingDetail from '../components/map-feature-details/MapBuildingDetail';
import MaproomDetail from '../components/map-feature-details/MapRoomDetail';
import MapSearchbar from '../components/MapSearchbar';

const mapboxToken =
  'pk.eyJ1IjoibHVrYXNib3pvbnR1a2UiLCJhIjoiY2xxMjg0ZTVwMDFvZjJxcXFyeHY4N2kyZiJ9.sV2EIcZIctuxaDqj3tTtPg';
const mapStyle = 'mapbox://styles/mapbox/streets-v12';
const initialViewState: any = {
  longitude: 21.245628049059125,
  latitude: 48.73221587399511,
  zoom: 15,
};

const FloorSelectTitle = styled.div`
  margin-bottom: 0.75rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

const FloorSelectItem = styled.div`
  margin-bottom: 0.65rem;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.65rem 0.8rem;
  background: rgba(0, 0, 0, 0.05);
  transition: 0.15s ease;
  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }
`;

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default function () {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    building: buildingParam,
    level: levelParam,
    idRoom: roomParam,
  } = useParams();

  const map = useRef<any>(null);
  const [mapLoaded, setMaploaded] = useState<boolean>(false);
  const [initDataLoaded, setInitDataLoaded] = useState<boolean>(false);

  const prevValues = usePrevious({
    mapLoaded,
    buildingParam,
    levelParam,
    roomParam,
  });

  const [activeMapDataLayers, setActiveMapDataLayers] = useState<string[]>([]);

  const [activeFeatures, setActiveFeatures] = useState<any>([]);

  const [markersData, setMarkersData] = useState<any>(null);
  const [buildingsData, setBuildingsData] = useState<any>(null);
  const [buildingLabelsData, setBuildingLabelsData] = useState<any>(null);
  const [buildingFloors, setBuildingFloors] = useState<any>([]);
  const [levelData, setLevelData] = useState<any>(null);

  const [buildingData, setBuildingData] = useState<any>(null);
  const [roomData, setRoomData] = useState<any>(null);

  const [popupInfo, setPopupInfo] = useState<any>(null);

  const [floorSelectModalOpen, setFloorSelectModalOpen] =
    useState<boolean>(false);

  const [currentFeatureDetailType, setCurrentFeatureDetailType] = useState<
    string | null
  >(null);

  const getBuildingById = useBuilding();

  const loadBuildingsData = useCallback(() => {
    const mapDataBuildings = buildMapDataBuildings(NEW_MAP_DATA, buildingParam);
    const mapDataBuildingLabels = buildMapDataBuildingLabels(NEW_MAP_DATA);
    setBuildingsData(mapDataBuildings);
    setBuildingLabelsData(mapDataBuildingLabels);

    // center map
    if (buildingParam) {
      const b = NEW_MAP_DATA.find((x) => x.id === buildingParam);
      if (b) {
        map.current.flyTo({
          center: b.center?.slice().reverse(),
          zoom: 16.5,
          animate: true,
        });
      }
    }
  }, [buildingParam]);

  const loadMarkersData = () => {
    const mapDataMarkers = buildMapDataMarkers(MARKERS);
    setMarkersData(mapDataMarkers);
  };

  const loadLevelData = useCallback(async () => {
    const f = await getFloor(buildingParam, levelParam);
    if (f?.corridors?.length > 0) {
      const floorMapData = buildFloorCorridors(f.corridors, roomParam);
      setLevelData(floorMapData);

      const centerRoom = floorMapData?.rooms?.features?.find(
        (x: any) => x.properties?.selected,
      );
      if (centerRoom) {
        map.current.flyTo({
          center: centerRoom?.geometry.coordinates,
          zoom: 19,
          animate: true,
        });
      } else {
        const bounds = new mapboxgl.LngLatBounds();
        f?.corridors?.forEach((c: any) => {
          bounds.extend([c.x0, c.y0]);
          bounds.extend([c.x1, c.y1]);
        });
        map.current?.fitBounds(
          [
            {
              lat: bounds._ne.lat,
              lon: bounds._ne.lng,
            },
            {
              lat: bounds._sw.lat,
              lon: bounds._sw.lng,
            },
          ],
          {
            padding: window.innerWidth > 768 ? 140 : 30,
          },
        );
      }
    } else {
      // if level not created
      navigate(`/map/${buildingParam}`);
    }
  }, [buildingParam, levelParam, navigate, roomParam]);

  const handleInitMapLoad = (e: any) => {
    map.current = e.target;
    setMaploaded(true);

    // load images
    MAP_ICONS.forEach((x) => {
      map.current.loadImage(x.iconPath, (error: any, image: any) => {
        map.current.addImage(x.name, image);
      });
    });

    // hover cursor
    map.current.on(
      'mouseenter',
      ['buildings', 'markers', 'level_rooms'],
      () => {
        map.current.getCanvas().style.cursor = 'pointer';
      },
    );
    map.current.on(
      'mouseleave',
      ['buildings', 'markers', 'level_rooms'],
      () => {
        map.current.getCanvas().style.cursor = '';
      },
    );
  };

  const handleMapClick = useCallback(
    async (e: any) => {
      e.originalEvent.stopPropagation();
      const features = map.current?.queryRenderedFeatures(e.point, {
        activeMapDataLayers,
      });

      if (features?.length > 0) {
        const feature = features[0];

        if (
          feature?.layer?.id === 'buildings' ||
          feature?.layer?.id === 'building_labels'
        ) {
          const b = await getBuildingById(feature.properties.id);
          setBuildingData(b);

          setPopupInfo({
            type: 'building',
            lat: e.lngLat.lat,
            lng: e.lngLat.lng,
            properties: b,
          });

          navigate(`/map/${b.name}`);
        } else if (feature?.layer?.id === 'markers') {
          if (
            feature.properties.type === 'Restaurant' ||
            feature.properties.type === 'Caffee'
          ) {
            setPopupInfo({
              type: 'restaurant',
              lat: e.lngLat.lat,
              lng: e.lngLat.lng,
              properties: feature.properties,
            });
          } else if (feature.properties.type === 'SportHall') {
            setPopupInfo({
              type: 'sporthall',
              lat: e.lngLat.lat,
              lng: e.lngLat.lng,
              properties: feature.properties,
            });
          } else if (feature.properties.type === 'TramStop') {
            setPopupInfo({
              type: 'tramStop',
              class: 'transport-popup',
              lat: e.lngLat.lat,
              lng: e.lngLat.lng,
              properties: feature.properties,
            });
          } else if (feature.properties.type === 'BusStop') {
            setPopupInfo({
              type: 'busStop',
              class: 'transport-popup',
              lat: e.lngLat.lat,
              lng: e.lngLat.lng,
              properties: feature.properties,
            });
          } else if (feature.properties.type === 'TicketMachine') {
            setPopupInfo({
              type: 'ticketMachine',
              lat: e.lngLat.lat,
              lng: e.lngLat.lng,
              properties: feature.properties,
            });
          }
        } else if (feature?.layer?.id === 'level_rooms') {
          const r: any = await getRoom(feature.properties.idRoom);
          setRoomData(r);

          setPopupInfo({
            type: 'room',
            lat: e.lngLat.lat,
            lng: e.lngLat.lng,
            properties: r,
          });

          navigate(`/map/${buildingParam}/${levelParam}/${r.room.idRoom}`);
        } else {
          setPopupInfo(null);
        }
      }
    },
    [activeMapDataLayers, buildingParam, getBuildingById, levelParam, navigate],
  );

  const getTransportLineLabels = (labels: any) => {
    if (labels) {
      const data = JSON.parse(labels);
      return data?.map((x: any) => (
        <div
          key={x}
          className={classNames(
            'transport-line-label',
            `transport-line-label--${x}`,
          )}
        >
          {x}
        </div>
      ));
    }
  };

  const getPopupContent = (data: any) => {
    if (data.type === 'building') {
      return (
        <>
          <div className="map-marker__popup-title">{data.properties.name}</div>
          <div className="map-marker__popup-subtitle">
            {data.properties.address}
          </div>
          <a
            href={`/building/${data.properties.idBuilding}`}
            target="_blank"
            rel="noreferrer"
            className="map-marker__popup-btn"
          >
            {t('detail')}
          </a>
        </>
      );
    }
    if (data.type === 'restaurant' || data.type === 'caffee') {
      return (
        <>
          <div className="map-marker__popup-title">{data.properties.name}</div>
          <div className="map-marker__popup-subtitle">
            {t(data.properties.type.toLowerCase())}
          </div>
          <a
            href={`/canteen/${data.properties.idCanteen}`}
            target="_blank"
            rel="noreferrer"
            className="map-marker__popup-btn"
          >
            {t('canteenMenu')}
          </a>
        </>
      );
    }
    if (data.type === 'sporthall') {
      return (
        <>
          <div className="map-marker__popup-title">{data.properties.name}</div>
          <div className="map-marker__popup-subtitle">
            {t(data.properties.type.toLowerCase())}
          </div>
          {data.properties.scheduleLink && (
            <a
              href={data.properties.scheduleLink}
              target="_blank"
              rel="noreferrer"
              className="map-marker__popup-btn"
            >
              {t('schedule')}
            </a>
          )}
        </>
      );
    }
    if (data.type === 'tramStop' || data.type === 'busStop') {
      return (
        <>
          <div className="map-marker__popup-title">{data.properties.name}</div>
          <div className="map-marker__popup-subtitle">
            {t(data.properties.type.toLowerCase())}
          </div>

          <hr className="map-marker__line" />
          {data.properties.transportLines && (
            <div className="transport-line-labels">
              {getTransportLineLabels(data.properties.transportLines)}
            </div>
          )}

          {data.properties.cpLink && (
            <a
              href={data.properties.cpLink}
              target="_blank"
              rel="noreferrer"
              className="map-marker__popup-btn mt-2"
            >
              {t('travelTimetable')}
            </a>
          )}
        </>
      );
    }
    if (data.type === 'ticketMachine') {
      return (
        <>
          <div className="map-marker__popup-title">
            {t(data.properties.type.toLowerCase())}
          </div>
          <hr className="map-marker__line" />
          {data.properties.cardPay && <div>✓ {t('payByCard')}</div>}
        </>
      );
    }
    if (data.type === 'room') {
      return (
        <>
          <div className="map-marker__popup-title">
            {data.properties.room.number}
          </div>
          <div className="map-marker__popup-subtitle">
            {data.properties.room.roomType?.name}
          </div>
          <a
            href={`/room/${data.properties.room.idRoom}`}
            target="_blank"
            rel="noreferrer"
            className="map-marker__popup-btn"
          >
            {t('detail')}
          </a>
        </>
      );
    }
  };

  useEffect(() => {
    async function loadData() {
      if (
        mapLoaded &&
        (!initDataLoaded ||
          prevValues?.buildingParam !== buildingParam ||
          prevValues?.levelParam !== levelParam ||
          prevValues?.roomParam !== roomParam)
      ) {
        setInitDataLoaded(true);
        if (levelParam) {
          if (buildingsData) setBuildingsData(null);
          if (markersData) setMarkersData(null);
          loadLevelData();
          setActiveMapDataLayers(['level_corridors', 'level_rooms']);
        } else {
          if (levelData) setLevelData(null);
          setActiveMapDataLayers(['buildings', 'markers']);
          loadBuildingsData();
          loadMarkersData();
        }

        if (!buildingParam && !levelParam && !roomParam) {
          setCurrentFeatureDetailType(null);
        } else if (roomParam) {
          setCurrentFeatureDetailType('room');
        } else if (buildingParam) {
          setCurrentFeatureDetailType('building');
        }

        // active features
        const activeFeaturesList: any = [];
        if (buildingParam) {
          let building = buildingData;
          if (!building) {
            building = await getBuildingById(buildingParam);
            setBuildingData(building);
          }
          activeFeaturesList.push({
            title: buildingParam,
            type: 'building',
            label: t('building'),
          });
          if (!levelParam) {
            activeFeaturesList.push({
              type: 'floor_select',
              select: true,
              label: t('clickToSelectFloor'),
            });
          }
          const floors = await getFloors(buildingParam);
          setBuildingFloors(floors);
        }
        if (levelParam) {
          activeFeaturesList.push({
            title: levelParam,
            type: 'level',
            label: t('level'),
          });
        }
        if (roomParam) {
          let room = roomData;
          if (!room) {
            room = await getRoom(roomParam);
            setRoomData(room);
          }
          activeFeaturesList.push({
            title: room?.room.number,
            type: 'room',
            label: t('room'),
          });
        }

        setActiveFeatures(activeFeaturesList);
      }
    }
    loadData();
  }, [mapLoaded, buildingParam, levelParam, roomParam, prevValues, buildingsData, markersData, loadLevelData, levelData, loadBuildingsData, buildingData, t, roomData, initDataLoaded, getBuildingById]);

  const removeActiveFeature = (type: string) => {
    setPopupInfo(null);

    let f = [];
    if (type === 'building') {
      setBuildingData(null);
      setActiveFeatures([]);
    } else if (type === 'level') {
      f = activeFeatures.filter((x: any) => x.type === 'building');
      setActiveFeatures(f);
    } else if (type === 'room') {
      f = activeFeatures.filter((x: any) => x.type !== 'room');
      setRoomData(null);
      setActiveFeatures(f);
    }
    let url = '/map';

    const b = f.find((x: any) => x.type === 'building');
    const l = f.find((x: any) => x.type === 'level');
    const r = f.find((x: any) => x.type === 'room');

    if (b) {
      url += `/${b.title}`;
    }

    if (l) {
      url += `/${l.title}`;
    }

    if (r) {
      url += `/${r.title}`;
    }

    navigate(url);
  };

  const renderActiveFeatures = activeFeatures?.map((x: any) => (
    <>
      {!x.select && (
        <button
          type="button"
          className="map__active-feature"
          key={x.label}
          onClick={() => x.type === 'level' && setFloorSelectModalOpen(true)}
        >
          <div className="map__active-feature-label">{x.label}:</div>
          <div className="map__active-feature-title">{x.title}</div>
          <button
            type="button"
            aria-label="Remove"
            className="map__active-feature-close-btn"
            onClick={(e) => {
              e.stopPropagation();
              removeActiveFeature(x.type);
            }}
          >
            <CloseIcon color="black" height="20px" />
          </button>
        </button>
      )}
      {x.select && buildingFloors?.length > 0 && (
        <button
          type="button"
          className="map__active-feature"
          key={x.label}
          onClick={() => setFloorSelectModalOpen(true)}
        >
          <div className="map__active-feature-label">{x.label}</div>
        </button>
      )}
    </>
  ));

  const selectFloor = (floor: any) => {
    setPopupInfo(null);
    navigate(`/map/${buildingParam}/${floor.level}`);
  };

  const handleSelectSearchItem = async (item: any) => {
    if (item.type === 'ROOM') {
      const r: any = await getRoom(item.id);
      if (r) {
        setRoomData(r);
        navigate(
          `/map/${r.room.building.name}/${r.room.level}/${r.room.idRoom}`,
        );
      }
    }
    if (item.type === 'BUILDING') {
      const b = await getBuildingById(item.id);
      if (b) {
        setBuildingData(b);
        navigate(`/map/${b.name}`);
      }
    }
  };

  return (
    <>
      <div className="map__wrapper">
        <div className="map__controls-holder">
          <MapSearchbar selectItem={(item) => handleSelectSearchItem(item)} />
          {activeFeatures?.length > 0 && (
            <div className="map__active-features-holder">
              <div className="map__active-features">{renderActiveFeatures}</div>
            </div>
          )}
        </div>

        <div className="map__holder" id="map">
          <Map
            mapboxAccessToken={mapboxToken}
            initialViewState={initialViewState}
            mapStyle={mapStyle}
            minZoom={5}
            maxZoom={20}
            onLoad={handleInitMapLoad}
            onClick={handleMapClick}
          >
            <FullscreenControl position="bottom-right" />
            <NavigationControl position="bottom-right" />

            {buildingsData && buildingLabelsData && (
              <>
                <Source type="geojson" data={buildingsData}>
                  <Layer {...BUILDINGS_LAYER} />
                </Source>
                <Source type="geojson" data={buildingsData}>
                  <Layer {...BUILDING_LABELS_LAYER} />
                </Source>
              </>
            )}

            {levelData && (
              <>
                <Source type="geojson" data={levelData?.corridors}>
                  <Layer {...FLOOR_CORRIDOR_LAYER} />
                </Source>
                <Source type="geojson" data={levelData?.rooms}>
                  <Layer {...FLOOR_CORRIDOR_ROOM_LAYER} />
                </Source>
              </>
            )}

            {markersData && (
              <Source type="geojson" data={markersData}>
                  <Layer {...MARKERS_LAYER} />
              </Source>
            )}

            {popupInfo && (
              <Popup
                anchor="bottom"
                longitude={Number(popupInfo.lng)}
                latitude={Number(popupInfo.lat)}
                onClose={() => setPopupInfo(null)}
                closeOnClick={false}
              >
                <div
                  className={classNames('map-marker__popup', popupInfo.class)}
                >
                  {getPopupContent(popupInfo)}
                </div>
              </Popup>
            )}
          </Map>
        </div>

        {currentFeatureDetailType && window.innerWidth > 1100 && (
          <div className="map__feature-detail">
            {currentFeatureDetailType === 'building' && (
              <MapBuildingDetail
                building={buildingData}
                floors={buildingFloors}
                selectFloor={(floor) => selectFloor(floor)}
              />
            )}
            {currentFeatureDetailType === 'room' && (
              <MaproomDetail room={roomData} />
            )}
          </div>
        )}
      </div>

      {floorSelectModalOpen && (
        <Modal closeModal={() => setFloorSelectModalOpen(false)}>
          <div className="p-5">
            <FloorSelectTitle>{t('selectFloor')}</FloorSelectTitle>

            {buildingFloors.map((floor: any) => (
              <FloorSelectItem
                onClick={() => {
                  selectFloor(floor);
                  setFloorSelectModalOpen(false);
                }}
              >
                {floor.level}
              </FloorSelectItem>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
}
