import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  children: React.ReactNode;
}

const CONTAINER = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const ITEM = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export function MotionList(props: Props) {
  const { children } = props;
  return (
    <motion.ul
      className="container"
      variants={CONTAINER}
      initial="hidden"
      animate="visible"
      style={{
        listStyle: 'none',
        marginBlockStart: '0px',
        paddingInlineStart: '0px',
      }}
    >
      {children}
    </motion.ul>
  );
}

export function MotionItem(props: Props) {
  const { children } = props;
  return (
    <motion.li
      key={Math.random()}
      variants={ITEM}
      style={{ padding: '15px 0px' }}
    >
      {children}
    </motion.li>
  );
}
