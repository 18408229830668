import React, { type FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Column } from '../Flex';
import Input from '../input/Input';
import Select from '../input/Select';
import Button from '../Button';
import RollingPicker from '../input/RollingPicker';
import { type Assignment, useEditAssignment } from '../../hooks/api/use-assignment';
import Error from '../../containers/Error';
import { useCourseName } from '../../hooks/api/use-course';
import { H3, TextSmall } from '../Typography';

interface EditAssignmentFormProps {
  assignment: Assignment;
  onSubmitCallback: () => void;
}

const EditAssignmentForm: FC<EditAssignmentFormProps> = ({
  assignment,
  onSubmitCallback,
}) => {
  const { t } = useTranslation();
  const { courseName } = useParams();
  const course = useCourseName(courseName || "");
  const methods = useForm();
  const assignmentMutation = useEditAssignment();

  if (courseName === undefined) return <Error message="Zlý parameter - courseName." />;

  const weekOptions = [
    t('weekNotSpecified'),
    ...Array.from({ length: 13 }, (_, index) => `${t('selectWeek', { week: index + 1 })}`),
    t('examPeriod')
  ];  

  const generatePointsOptions = () => {
    const options: string[] = [];
    for (let i = 0; i <= 100; i+=1) {
      switch (i) {
        case 1:
          options.push(`${i} ${  t('1point')}`);
          break;
        case 2:
        case 3:
        case 4:
          options.push(`${i} ${ t('234points')}`);
          break;
        default:
          options.push(`${i} ${ t('5+points') }`);
      }
    }
    return options;
  };
  const pointsOptions: string[] = generatePointsOptions();

  const nameValidation = {
    required: {
      value: true,
      message: t('forms.nameRequired'),
    },
    minLength: {
      value: 3,
      message: t('forms.nameTooShort'),
    },
    maxLength: {
      value: 64,
      message: t('forms.nameTooLong'),
    },
  };

  const weekValidation = {
    required: {
      value: true,
      message: t('forms.deadlineRequired'),
    },
    min: {
      value: 0,
      message: t('forms.deadlineInvalid'),
    },
    max: {
      value: 14,
      message: t('forms.deadlineInvalid'),
    },
  };

  const pointsValidation = {
    required: {
      value: true,
      message: t('forms.scoreRequired'),
    },
    valueAsNumber: true,
    min: {
      value: 0,
      message: t('forms.scoreInvalid'),
    },
    max: {
      value: 100,
      message: t('forms.scoreInvalid'),
    },
  };

  const onSubmit = (data: any, e?: any) => {
    e.preventDefault();

    const newAssignment: Assignment = {
      studentCourse: {
        id: course.data?.id,
      },
      points:
        data.maxPoints < assignment.points ? data.maxPoints : assignment.points,
      creatorStudentAccount: assignment.creatorStudentAccount,
      ownerStudentAccount: assignment.ownerStudentAccount,
      dateCreated: assignment.dateCreated,

      ...data,
    };

    assignmentMutation.mutate(
      { assignmentId: assignment.id ?? 0, assignment: newAssignment },
      {
        onSuccess: () => {
          toast.success(t('forms.toastSuccessEdit'));
          onSubmitCallback();
        },
        onError: () => {
          toast.error(t('toastError'));
        },
      },
    );
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Column style={{ gap: '1em' }}>
          <H3 fontWeight={600}>{t('edit')}</H3>
          <TextSmall>{t('assignment.editDescription')}</TextSmall>
          <Input
            name="name"
            type="text"
            placeholder={t('assignmentName') || ''}
            defaultValue={assignment.name}
            validation={nameValidation}
          />

          {!isMobile && (
            <Input
              name="maxPoints"
              type="number"
              min={0}
              max={100}
              defaultValue={assignment.maxPoints}
              label={t('achievableScore') || ''}
              validation={pointsValidation}
            />
          )}

          <Select
            name="dueWeek"
            label={t('deadline')}
            value={assignment.dueWeek}
            validation={weekValidation}
            options={weekOptions}
          />

          {isMobile && (
            <RollingPicker
              name="maxPoints"
              label={t('achievableScore')}
              value={assignment.maxPoints}
              validation={pointsValidation}
              options={pointsOptions}
            />
          )}

          <Button type="submit" variant="primary" style={{ width: '100%' }}>
            {t('edit')}
          </Button>
        </Column>
      </form>
    </FormProvider>
  );
};

export default EditAssignmentForm;
