import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components';
import { H1, Text } from '../components/Typography';
import ErrorImage from '../assets/images/error.svg';
import Overlay from '../components/Overlay';
import ReportBugForm from '../components/forms/ReportBugForm';

const Image = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14em;
  margin-bottom: 2em;
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
`;

const Section = styled.section`
  margin-bottom: 1em;
`;

const NotFoundPage: FC = () => {
  const { t } = useTranslation();
  const [showBugReport, setShowBugReport] = useState<boolean>(false);

  return (
    <MainContent>
      <Image src={ErrorImage} />

      <Section>
        <H1 fontWeight={600} align="center">
          {t('notFound')}
        </H1>
      </Section>

      <Section>
        <Text align="center">
          {t('tryAgain1')}
          <u style={{ cursor: 'pointer' }} onClick={() => {setShowBugReport(true)}}>{t('tryAgain2')}</u>   
          .
        </Text>
      </Section>

      <Overlay isOpen={showBugReport} onClose={() => {setShowBugReport(false)}}>
        <ReportBugForm onSubmitCallback={() => {setShowBugReport(false)}} />
      </Overlay>
    </MainContent>
  );
}

export default NotFoundPage