import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Appointment, TimeTable } from '../components/Schedule';
import { useEmployeeSchedule } from '../hooks/api/use-schedule';
import { AppointmentFactory } from '../helper/util';

export default function () {
  const { id, date } = useParams();

  const employeeSchedule = useEmployeeSchedule(Number(id));
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  useEffect(() => {
    if (employeeSchedule.data) {
      setAppointments(AppointmentFactory(employeeSchedule.data, new Date(date || "")) || []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeSchedule.data]);

  return (
    <TimeTable appointments={appointments} editable={false} />
  );
}
