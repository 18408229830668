import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Column, Row } from '../components/Flex';
import { DepartmentIcon } from '../components/Icons';
import { MotionList, MotionItem } from '../components/Motion';
import { ExternalLink, H1, H3, InternalLink } from '../components/Typography';
import { ListItem } from '../components/ListItem';
import LoadingPage from './LoadingPage';
import { Department, useDepartment } from '../hooks/api/use-department';

export default function () {
  const { t, i18n } = useTranslation();
  const { idDepartment } = useParams();
  const [department, setDepartment] = useState<Department>();

  const { data, refetch } = useDepartment(idDepartment || "");

  if (idDepartment === undefined) return <>{t('invalidParams')}</>;

  useEffect(() => {
    if (idDepartment) {
      refetch();
      setDepartment(data);
    } else setDepartment(undefined)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDepartment, data]);

  if (department === undefined) return <LoadingPage />;

  const lang = i18n.language === 'sk';
  const departmentName = lang
    ? department.department.name
    : department.department.nameEn;

  return (
    <MotionList>
      <MotionItem>
        <Row>
          <DepartmentIcon color={department.department.color} />
          <Column>
            <H1 fontWeight={600}>
              {`${departmentName} (${department.department.acronym})`}
            </H1>
            <ExternalLink
              text={department.department.web}
              href={department.department.web}
            />
          </Column>
        </Row>
      </MotionItem>
      {department.topLevelDepartment && (
        <MotionItem>
          <H3 fontWeight={600}>{t('faculty')}</H3>
          <InternalLink
            href={`/department/${department.topLevelDepartment.idDepartment}`}
            text={
              lang
                ? department.topLevelDepartment.name
                : department.topLevelDepartment.nameEn
            }
          />
        </MotionItem>
      )}
      <MotionItem>
        <H3 fontWeight={600}>{t('employees')}</H3>
        {department.employees.map((employee: any) => {
          const position = lang ? employee.position : employee.positionEn;
          return (
            <ListItem
              description={position}
              type="EMPLOYEE"
              id={employee.idEmployee}
              text={`${employee.fullName}`}
              iconColor={department.department.color}
              key={`${employee.idEmployee} - ${employee.fullName} - ${Math.random()}`}
            />
          );
        })}
      </MotionItem>
      <MotionItem>
        <H3 fontWeight={600}>{t('rooms')}</H3>
        {department.rooms.map((room: any) => {
          if (room.name === null) return null;
          const roomName =
            lang || room.nameEn === null ? room.name : room.nameEn;
          return (
            <ListItem
              description={roomName}
              type="ROOM"
              id={room.idRoom}
              text={`${room.building.name} - ${room.number} ${room.name}`}
              iconColor={department.department.color}
              key={`${room.building.name} - ${roomName} - ${Math.random()}`}
            />
          );
        })}
      </MotionItem>
    </MotionList>
  );
}
