import React from 'react';
import { type FC, useState } from 'react';
import styled from 'styled-components';
import { Add } from '@styled-icons/material-rounded';
import { t } from 'i18next';
import Overlay from './Overlay';
import { Column } from './Flex';
import banner from '../assets/images/add_assignment_banner.jpg';
import AddAssignmentForm from './forms/AddAssignmentForm';
import Button from './Button';
import Icon from './Icon';

export const BannerImage = styled.img`
  width: 100%;
  aspect-ratio: 4/1;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.rounded.large};
`;

const AddAssignment: FC = () => {
  const [isOverlayShown, setIsOverlayShown] = useState(false);

  return (
    <>
      <Button variant="circular"
        title={t('add') || ""}
        icon={<Icon size={18}><Add /></Icon>}
        onClick={() => {setIsOverlayShown(true)}}
        style={{ gap: '0.25em' }} 
      />
      <Overlay
        isOpen={isOverlayShown}
        onClose={() => {
          setIsOverlayShown(false);
        }}
      >
        <Column style={{ gap: '1em' }}>
          <BannerImage src={banner} />

          <AddAssignmentForm
            onSubmitCallback={() => {
              setIsOverlayShown(false);
            }}
          />
        </Column>
      </Overlay>
    </>
  );
};

export default AddAssignment;
