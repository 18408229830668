import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row } from '../components/Flex';
import { BuildingIcon, DepartmentIcon } from '../components/Icons';
import { MotionList, MotionItem } from '../components/Motion';
import { H1, H3, InternalLink, Text } from '../components/Typography';
import Button from '../components/Button';
import LoadingPage from './LoadingPage';
import { buildingApiType, useBuilding } from '../hooks/api/use-building';

function Building() {
  const { t, i18n } = useTranslation();
  const { idBuilding } = useParams();
  const [building, setBuilding] = useState<buildingApiType>();
  const [levels, setLevels] = useState<string[]>([]);
  const skLang = i18n.language === 'sk';

  const getBuildingById = useBuilding();

  useEffect(() => {
    if (idBuilding !== undefined) {
      getBuildingById(idBuilding)
        .then((b) => {
          setBuilding(b);
          setLevels(Object.keys(b.levels as Record<string, any>));
        })
        .catch((error) => {
          console.error('Error fetching building:', error);
          setBuilding(undefined);
        });
    }
  }, [getBuildingById, idBuilding]);

  if (idBuilding === undefined) return <>{t('invalidParams')}</>;
  if (building === undefined) return <LoadingPage />;

  return (
    <MotionList>
      <MotionItem>
        <Row alignItems="center">
          <BuildingIcon color="#BDBDBD" />
          <H1 fontWeight={600}>
            {t('building')} {building.name}
          </H1>
        </Row>
      </MotionItem>
      <MotionItem>
        <H3 fontWeight={600}>{t('address')}</H3>
        <Text className="mb-4">{building.address}</Text>
        <Button
          variant="secondary"
          onClick={() => window.open(`/map/${building.name}`)}
        >
          {t('showOnMap')}
        </Button>
        {/* <InternalLink text={t('showOnMap')} href={`/map/${building.name}`} version="invisible" /> */}
      </MotionItem>
      <MotionItem key={10}>
        <H3 fontWeight={600}>{t('Poschodia')}</H3>
        {levels.map((level: string, levelIndex: number) => (
          <div key={`level-${levelIndex}`} style={{ marginTop: '20px' }}>
            <InternalLink
              href={`/building/${building.name}/${level}`}
              text={t(level)}
            />
            {building.levels[level].map((department: any, buildingIndex: number) => (
              <Row key={`department-${buildingIndex}`} alignItems="center">
                <DepartmentIcon color={department.color} />
                <InternalLink
                  text={skLang ? department.name : department.nameEn}
                  href={`/department/${department.idDepartment}`}
                  version="invisible"
                />
              </Row>
            ))}
          </div>
        ))}
      </MotionItem>
    </MotionList>
  );
}

export default Building;
