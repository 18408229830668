import React, { type FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Column } from '../Flex';
import Select from '../input/Select';
import Button from '../Button';
import RollingPicker from '../input/RollingPicker';
import useAuth from '../../hooks/use-auth';
import { StudentAccount, useAccount, useEditAccount } from '../../hooks/api/use-account';
import { H3, TextSmall } from '../Typography';
import Input from '../input/Input';

interface EditUserFormProps {
  onSubmitCallback: () => void;
}

const EditUserForm: FC<EditUserFormProps> = ({ onSubmitCallback }) => {
  const { t } = useTranslation()
  const { user, login, isAuthenticated } = useAuth();
  const methods = useForm();
  const accountMutation = useEditAccount();
  const account = useAccount();

  const isMobile = window.innerWidth <= 768;

  const facultyShort = ["EKF", "FBERG", "FEI", "FMMR", "FU", "FVT", "LF", "SVF", "SJF"];
  const facultyIndex = facultyShort.findIndex(fac => fac === account.data?.faculty) === -1 ?
    undefined : facultyShort.findIndex(fac => fac === account.data?.faculty);

  const facultyOptions = [t('faculties.EKF'), t('faculties.FBERG'), t('faculties.FEI'), t('faculties.FMMR'), t('faculties.FU'), t('faculties.FVT'), t('faculties.LF'), t('faculties.SVF'), t('faculties.SJF')];  
  const yearOptions = [`1. ${t('year').toLowerCase()}`, `2. ${t('year').toLowerCase()}`, `3. ${t('year').toLowerCase()}`, `4. ${t('year').toLowerCase()}`, `5. ${t('year').toLowerCase()}`, `6. ${t('year').toLowerCase()}`];

  const facultyValidation = {
    required: {
      value: true,
      message: t('forms.facultyRequired'),
    },
    min: {
      value: 0,
      message: t('forms.facultyInvalid'),
    },
    max: {
      value: 8,
      message: t('forms.facultyInvalid'),
    },
  };

  const yearValidation = {
    required: {
      value: true,
      message: t('forms.yearRequired')
    },
    valueAsNumber: true,
    min: {
      value: 0,
      message: t('forms.yearInvalid')
    },
    max: {
      value: 5,
      message: t('forms.yearInvalid')
    },
  };

  const onSubmit = (data: any, e?: any) => {
    e.preventDefault();

    if (!isAuthenticated) {
      login();
      return;
    }
    if (!user) return;

    const newAccount: StudentAccount = {
      faculty: facultyShort[data.faculty],
      year: isMobile ? data.year+1 : data.year, // starts with 0, +1 to be more descriptive
      lastActiveOn: new Date()
    };

    accountMutation.mutate(newAccount, {
      onSuccess: () => {
        toast.success(t('forms.toastSuccessAdd'));
        onSubmitCallback();
      },
      onError: () => {
        toast.error(t('toastError'));
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Column style={{ gap: '1em' }}>

          <H3 fontWeight={600}>{t('forms.studentInformation')}</H3>
          <TextSmall>{t('forms.chooseFaculty')}</TextSmall>

          <Select
            name="faculty"
            label={t('faculty')}
            value={facultyIndex}
            validation={facultyValidation}
            options={facultyOptions}
          />

          {!isMobile && (
            <Input
              name="year"
              type="number"
              min={1}
              max={6}
              defaultValue={account.data?.year}
              label={t('year') || ''}
              validation={yearValidation}
            />
          )}

          {isMobile && (
            <RollingPicker
              name="year"
              label={t('year')}
              value={account.data?.year && account.data.year-1}
              validation={yearValidation}
              options={yearOptions}
            />
          )}

          <Button type="submit" variant="primary" style={{ width: '100%' }}>
            {t('done')}
          </Button>
        </Column>
      </form>
    </FormProvider>
  );
};

export default EditUserForm;
