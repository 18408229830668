import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useTranslation } from 'react-i18next';
import { Text } from '../components/Typography';

export default function () {
  const [error, setError] = useState<any>();
  const { t } = useTranslation();

  const openLink = (link: any) => {
    if (link) window.location.href = link;
  };

  return (
    <>
      {!error && <Text>{t('useScanner')}</Text>}

      {error && (
        <>
          <Text style={{ marginTop: '20px' }}> {t('scanning error')}</Text>
          <Text style={{ color: 'red' }}>
            {error.code} {error.name}: {error.message}
          </Text>
        </>
      )}

      <QrReader
        onResult={(result: any, err: any) => {
          if (result) {
            openLink(result.text);
          }

          if (err) {
            setError(err);
          }
        }}
        constraints={{ facingMode: 'environment' }}
        // @ts-expect-error to fix error message
        style={{ width: '100%' }}
      />
    </>
  );
}
