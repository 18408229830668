import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { type Course } from "./use-course";
import { Lesson } from "./use-lesson";
import useAuth from "../use-auth";

export interface StudentAccount {
  id?: number;
  login?: string;
  faculty: string;
  programme?: string;
  year: number;
  lastActiveOn: Date;
  studentCourses?: Course[];
}

export interface StudentCourseLesson {
  id?: number;
  studentCourse: Course;
  room: string;
  startDate: Date;
}

const baseURL = "";

export const useAccount = () => {
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: ["account"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/account/`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load schedule.");
      }

      return (await response.json()) as StudentAccount;
    },
    enabled: isAuthenticated(),
  });
};

export const useEditAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (account: StudentAccount) => {
      const response = await fetch(`${baseURL}/api/account`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(account),
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to create account.");
      }

      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["account"] });
    },
  });
};

// Creates an account if it doesn't exist yet.
// Edits lastActiveOn if it exists.
export const useCreateAccount = () =>
  useMutation({
    mutationFn: async () => {
      const response = await fetch(`${baseURL}/api/account/login`, {
        method: "POST",
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to create account.");
      }

      return response;
    },
    onError: (error: any) => {
      console.error("Error creating account:", error);
    },
  });

export const useCreateSchedule = () =>
  useMutation({
    mutationFn: async (schedule: StudentCourseLesson[]) => {
      const response = await fetch(`${baseURL}/api/account/schedule`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(schedule),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to create schedule.");
      }

      return response;
    },
    onError: (error: any) => {
      console.error("Error creating schedule:", error);
    },
  });

export const useSchedule = () =>
  useQuery({
    queryKey: ["schedule"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/account/schedule`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load schedule.");
      }

      return (await response.json()) as Lesson[];
    },
    networkMode: "offlineFirst",
  });

export const useUsers = () =>
  useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const response = await fetch(`/api/account/users`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load users.");
      }

      return (await response.json()) as StudentAccount[];
    },
    // onError: (error: any) => {
    //   console.error("Error loading users:", error);
    // },
  });
