import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearch } from '../hooks/api/use-search';
import { Logo } from '../components/Logo';
import { SearchBar } from '../components/SearchBar';
import { ListItem } from '../components/ListItem';
import { Row } from '../components/Flex';
import { searchOptions } from '../store/uiConfig';
import { Chip } from '../components/Chip';

function Search() {
  const { t } = useTranslation();

  const [text, setText] = useState<string>(localStorage.searchPhrase || '');
  const [results, setResults] = useState([]);
  const [filter, setFilter] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const LIMIT = 8;

  const search = useSearch();

  useEffect(() => {
    localStorage.searchPhrase = text;
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        const json = await search(text, 0, LIMIT, filter);
        setResults(json);
        setOffset(LIMIT);
      })();
    }, 600);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, filter]);

  const loadMoreData = async () => {
    const newOffset = offset + LIMIT;
    const json = await search(text, newOffset, LIMIT, filter);
    if (!('error' in json)) {
      setResults(results.concat(json));
      setOffset(newOffset);
    }
  };

  const toggleFilter = (filterType: string) => {
    if (filterType === filter) {
      setFilter('');
    } else {
      setFilter(filterType);
    }
  };

  const renderResults = results.map((result: any) => (
    <ListItem
      type={result.type}
      text={result.name}
      id={result.id}
      iconColor={result.color}
      description={result.description}
    />
  ));

  const renderSearchOptions = searchOptions.map((elem) => {
    const { color, icon, title, filterType, id } = elem;
    return (
      <Chip
        toggle={() => {
          toggleFilter(filterType);
        }}
        color={filterType === filter ? color : ''}
        icon={icon}
        title={title}
        key={id}
      />
    );
  });

  return (
    <main style={{ height: "110vh" }}>
      <Logo />
      <SearchBar
        placeholder={t('search')}
        onChange={(value: string) => {
          setText(value);
        }}
        value={text}
      />
      <Row scrollable>{renderSearchOptions}</Row>
      {text && (
        <InfiniteScroll
          dataLength={results.length}
          next={loadMoreData}
          hasMore
          loader={<h4>{t('Loading...')}</h4>}
          endMessage="Yay! You have seen it all"
        >
          {renderResults}
        </InfiniteScroll>
      )}
    </main>
  );
}

export default Search;
