import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { buildingApiType, useBuilding } from '../hooks/api/use-building';
import { FacultyHeader } from '../components/FacultyHeader';
import { Row } from '../components/Flex';
import { LevelIcon, BuildingIcon } from '../components/Icons';
import { MotionList, MotionItem } from '../components/Motion';
import { H1, H3, InternalLink, Text } from '../components/Typography';
import { ListItem } from '../components/ListItem';
import Button from '../components/Button';
import LoadingPage from './LoadingPage';
import { useLevel } from '../hooks/api/use-level';

function Level() {
  const { t } = useTranslation();
  const { idBuilding, idLevel } = useParams();
  const [building, setBuilding] = useState<buildingApiType>();
  const [level, setLevel] = useState<any>(undefined);
  const [levelName, setLevelName] = useState(null);
  // idLevel môže byť len jedno písmeno, kľúče v response su celé slová

  const { data } = useLevel(idBuilding || "", idLevel || "");
  const getBuildingById = useBuilding();

  useEffect(() => {
    (async () => {
      try {
        const b = await getBuildingById(idBuilding);
        setBuilding(b);

        Object.keys(b.levels).forEach((key: any) => {
          if (idLevel && key.charAt(0) === idLevel.charAt(0)) setLevelName(key);
        });

        setLevel(data);
      } catch (e) {
        setBuilding(undefined);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idBuilding, idLevel, data]);

  if (idBuilding === undefined || idLevel === undefined) return <>{t('invalidParams')}</>;
  if (building === undefined || level === undefined || levelName === null) {
    return <LoadingPage />;
  }

  return (
    <MotionList>
      <MotionItem>
        <Row alignItems="center">
          <LevelIcon color="#BDBDBD" />
          <H1 fontWeight={600}> {t(levelName)}</H1>
        </Row>
      </MotionItem>
      <MotionItem>
        <H3 fontWeight={600}>{t('address')}</H3>
        <Row alignItems="center" className="mb-4">
          <BuildingIcon color="#BDBDBD" />
          <div>
            <InternalLink
              text={`${t('building')} ${idBuilding}`}
              href={`/building/${idBuilding}`}
              version="invisible"
            />
            <Text>{building.address}</Text>
            {/* <InternalLink text={`${building.address} (${t('showOnMap')}) `} href={`/map/${idBuilding}`} version="invisible" /> */}
          </div>
        </Row>
        <Button
          variant="secondary"
          onClick={() => window.open(`/map/${building.name}/${levelName}`)}
        >
          {t('showOnMap')}
        </Button>
      </MotionItem>

      <MotionItem key={level}>
        <H3 fontWeight={600}>
          <>{t('departments')}</>
        </H3>
        {building.levels[levelName].map((department: any) => (
          <div style={{ margin: '10px 0px' }}>
            <FacultyHeader department={department} />
          </div>
        ))}
      </MotionItem>
      <MotionItem>
        <H3 fontWeight={600}>{t('rooms')}</H3>
        {level.map((room: any) => {
          if (room.room.name === null) return null;
          return (
            <ListItem
              description={room.room.roomType.name}
              type="ROOM"
              id={room.room.idRoom}
              text={`${building.name} - ${room.room.number} ${room.room.name}`}
              iconColor={room.department.color}
            />
          );
        })}
      </MotionItem>
    </MotionList>
  );
}

export default Level;
