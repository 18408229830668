import styled from 'styled-components';

interface MarginProps {
  size: number;
  direction: string;
}

const Margin = styled.div<MarginProps>`
  ${({ size, direction }) => {
    let marginValue = '';

    switch (direction) {
      case 'top':
        marginValue = `${size}em 0 0 0`;
        break;
      case 'right':
        marginValue = `0 ${size}em 0 0`;
        break;
      case 'bottom':
        marginValue = `0 0 ${size}em 0`;
        break;
      case 'left':
        marginValue = `0 0 0 ${size}em`;
        break;
      case 'x':
        marginValue = `0 ${size}em 0 ${size}em`;
        break;
      case 'y':
        marginValue = `${size}em 0 ${size}em 0`;
        break;
      default:
        marginValue = `${size}em`;
        break;
    }

    return `margin: ${marginValue};`;
  }}
`;

export default Margin;
