import React from 'react';
import { KeyboardArrowRight } from '@styled-icons/material-rounded/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row } from './Flex';
import { BuildingIcon, DepartmentIcon, PersonIcon, RoomIcon } from './Icons';
import { Text, TextSmall } from './Typography';

interface Props {
  type: string;
  id: string;
  text: string;
  description: string;
  iconColor: string;
}

const KeyboardArrowRightIcon = styled(KeyboardArrowRight)`
  color: ${({ color, theme }) => color ?? theme.colors.icons};
  display: inline-block;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};

  &:hover ${KeyboardArrowRightIcon} {
    color: ${({ theme }) => theme.colors.text};
  }

  &:hover ${Row} {
    background: ${({ theme }) => theme.colors.pressBackground};
  }
`;

const AdjustedRow = styled(Row)`
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export function ListItem(props: Props) {
  const { type, id, text, description, iconColor } = props;
  let icon;
  let link;

  switch (type) {
    case 'BUILDING':
      icon = <BuildingIcon color={iconColor} />;
      link = `/building/${id}`;
      break;
    case 'EMPLOYEE':
      icon = <PersonIcon color={iconColor} />;
      link = `/people/${id}`;
      break;
    case 'DEPARTMENT':
      icon = <DepartmentIcon color={iconColor} />;
      link = `/department/${id}`;
      break;
    default:
      icon = <RoomIcon color={iconColor} />;
      link = `/room/${id}`;
      break;
  }

  return (
    <StyledLink to={link}>
      <Row alignItems="center">
        {icon}
        <AdjustedRow justifyContent="space-between">
          <Text>{text}</Text>
          <TextSmall>{description}</TextSmall>
        </AdjustedRow>
        <KeyboardArrowRightIcon size={20} />
      </Row>
    </StyledLink>
  );
}
