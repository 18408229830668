import { FC, useEffect, useState } from 'react'
import styled from 'styled-components';
import { ArrowForward } from '@styled-icons/material-rounded';
import MissingSchedule from '../components/MissingSchedule';
import { getUrlFriendlyName } from '../helper/util';
import { Row } from '../components/Flex';
import { ArchivedCourse, useArchivedCourses, useCourses, useCurrentCourses } from '../hooks/api/use-course'
import { H1, Text, TextSmall } from '../components/Typography'
import { useSemester } from '../hooks/api/use-semester';
import LoadingPage from './LoadingPage';

interface CoursesProps {
  
}

const Expandable = styled.details`
  margin-top: 1em;
  user-select: none;
`

const Summary = styled.summary<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => isActive ? theme.colors.background : theme.colors.text};
  background-color: ${({ theme, isActive }) => isActive ? theme.colors.primary : theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.medium};
  padding: 0.5em 0.75em;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const CourseItem = styled.li`
  border-radius: ${({ theme }) => theme.rounded.medium};
  padding: 0.25em 0.75em;
  font-weight: 600;

  .arrow {
    display: none;
  }

  &:hover {
    .arrow {
      display: block;
    }
  }
`

const CurrentCourses: FC = () => {
  const courses = useCurrentCourses();
  const currSemester = useSemester();

  return (
    <Expandable open>
      <Summary isActive>{currSemester.data && currSemester.data.name}</Summary>
      <ul>
        {courses.data && courses.data.length === 0 && (
          <MissingSchedule />
        )}
        {courses.data && courses.data.map(course => (
          <CourseItem key={course.id}>
            <a href={`/course/${getUrlFriendlyName(course.name)}`}>
              <Row alignItems="center" style={{ gap: "0.5em" }}>
                <Text fontWeight={500}>{course.name}</Text>
                <ArrowForward size={20} className="arrow" />
              </Row>
            </a>
          </CourseItem>
        ))}
      </ul>
    </Expandable>
  )
}

const Courses: FC<CoursesProps> = () => {
  const courses = useCourses();
  const archivedCourses = useArchivedCourses();
  const [semesterCourses, setSemesterCourses] = useState<{ [semester: string] : ArchivedCourse[]; }>({});

  useEffect(() => {
    if (archivedCourses.data) {
      const sems: string[] = archivedCourses.data.map(course => course.semester);
      const uniqueStringsSet = new Set(sems);
      const uniqueSemesters = Array.from(uniqueStringsSet);
      const semesterBuffer: { [semester: string] : ArchivedCourse[]; } = {};

      uniqueSemesters.forEach(semester => {
        const coursesForSemester = archivedCourses.data.filter(course => course.semester === semester);
        semesterBuffer[semester] = coursesForSemester;
      });

      setSemesterCourses(semesterBuffer);
    }
  }, [archivedCourses.data])

  const getGrade = (points: number) => {
    if (points > 90) return 'A';
    if (points > 80) return 'B';
    if (points > 70) return 'C';
    if (points > 60) return 'D';
    if (points > 50) return 'E';
    return 'FX';
  };

  if (courses.isLoading || archivedCourses.isLoading) {
    return <LoadingPage />
  }

  if ((courses.data && courses.data.length === 0) && 
      (archivedCourses.data && archivedCourses.data.length === 0)) {
    return (
      <main>
        <MissingSchedule />
      </main>
    )
  }

  if (courses.data && archivedCourses.data) {
    return (
      <main>
        <H1 fontWeight={600}>Predmety</H1>
          <CurrentCourses />
          {Object.keys(semesterCourses).map(sem => (
            <Expandable open>
              <Summary isActive={false}>{sem}</Summary>
              <ul>
                {semesterCourses[`${sem}`].map(course => (
                  <CourseItem key={course.id}>
                      <Row alignItems="center" justifyContent="space-between">
                        <Text fontWeight={500}>{course.name}</Text>
                        <Row alignItems="center" style={{ gap: "1em", width: "min-content" }}>
                          <Text fontWeight={700}>
                            {getGrade(course.points * 100 / course.maxPoints)}
                          </Text>
                          <TextSmall>{course.points * 100 / course.maxPoints}%</TextSmall>
                        </Row>
                      </Row>
                  </CourseItem>
                ))}
              </ul>
            </Expandable>
          ))}
      </main>
    )
  }

  return null;
}

export default Courses