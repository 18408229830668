import { createGlobalStyle } from 'styled-components';
import { type ThemeType } from './types';

export const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }

  html {
    font-size: 16px;
  }
  body {
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    margin: 0;

    min-height: 100dvh;
    min-height: -webkit-fill-available;

    // margin: 10px auto;
    // padding: 0px 10px;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
  }

  a{
      text-decoration: none;
      color: ${({ theme }) => theme.colors.text};
  }

  h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
  }

  ul{
    list-style-type: none;
    padding: 0;
  }

  textarea{
    width: 100%;
    height: 8em;
    padding: 0.5em 1em;
    box-sizing: border-box;
    border: ${({ theme }) => theme.border};
    border-radius: ${({ theme }) => theme.rounded.large};
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-size: 1em;
    font-family: "Open Sans", sans-serif;
  }

  // input[type="radio"] {
  //   -webkit-appearance: none;
  //   appearance: none;
  //   background-color: ${({ theme }) => theme.colors.background};
  //   margin: 0;
  //   font: inherit;
  //   color: ${({ theme }) => theme.colors.text};
  //   width: 1em;
  //   height: 1em;
  //   border: 0.12em solid ${({ theme }) => theme.colors.text};
  //   border-radius: 50%;
  //   transform: translateY(-0.075em);
  //   display: grid;
  //   place-content: center;
  //   cursor: pointer;

  //   &::before {
  //     content: "";
  //     width: 0.65em;
  //     height: 0.65em;
  //     border-radius: 50%;
  //     transform: scale(0);
  //     transition: 120ms transform ease-in-out;
  //     background-color: ${({ theme }) => theme.colors.text};
  //   }

  //   &:checked::before {
  //     transform: scale(1);
  //   }  
  // }
  

  .buildingMapMarker{
    color: ${({ theme }) => theme.colors.background};
  }

  .react-datepicker-wrapper {
    width: auto;
  }

  .picker{
    text-align: center;
    border: none;
    font-size: 1em;
    background: transparent;
    color: ${({ theme }) => theme.colors.text};
  }

  // spacing
  .m-0 {
    margin: 0;
  }

  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }

  .mt-1 {
    margin-top: 0.25rem;
  }
  .mt-2 {
    margin-top: 0.5rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }

  .ml-1 {
    margin-left: 0.25rem;
  }
  .ml-2 {
    margin-left: 0.5rem;
  }
  .ml-3 {
    margin-left: 0.75rem;
  }
  .ml-4 {
    margin-left: 1rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }
  .mr-3 {
    margin-right: 0.75rem;
  }
  .mr-4 {
    margin-right: 1rem;
  }

  .p-1 {
    padding: 0.25rem;
  }
  .p-2 {
    padding: 0.5rem;
  }
  .p-3 {
    padding: 0.75rem;
  }
  .p-4 {
    padding: 1rem;
  }
  .p-5 {
    padding: 1.5rem;
  }
  .p-6 {
    padding: 2rem;
  }

  // font size
  .text-xs {
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
  }
  .text-sm {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
  }
  .text-lg {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
  }
  .text-xl {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }
  .text-2xl {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
  }
  .text-3xl {
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
  }

  // font weight
  .font-medium {
    font-weight: 500;
  }
  .font-semibold {
    font-weight: 600;
  }
  .font-bold {
    font-weight: 700;
  }

  // flex utils
  .flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .items-center {
    align-items: center;
  }

  .toast {
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
  }
`;
