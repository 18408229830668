import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Search } from '@styled-icons/material-rounded';
import { useSearch } from '../hooks/api/use-search';
import { BuildingIcon, RoomIcon } from './Icons';

interface Props {
  selectItem: (item: any) => void;
}
const SearchResultItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem;
  transition: 0.15s ease;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

function MapSearchbar(props: Props) {
  const { t } = useTranslation();

  const { selectItem } = props;

  const [resultsActive, setResultsActive] = useState<boolean>(false);
  const [searchkey, setSearchkey] = useState<string>('');
  const [results, setResults] = useState<any>([]);

  const search = useSearch();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchkey?.length > 0) {
        (async () => {
          const res = await search(searchkey, 0, 8, 'ROOM');
          setResults(res);
        })();
      } else {
        setResults([]);
      }
    }, 400);
    return () => clearTimeout(delayDebounceFn);
  }, [search, searchkey]);

  const renderResults = results.map((result: any) => {
    let icon;

    switch (result.type) {
      case 'BUILDING':
        icon = <BuildingIcon color="#BDBDBD" className="m-0" />;
        break;
      default:
        icon = <RoomIcon color="#BDBDBD" className="m-0" />;
        break;
    }

    return (
      <SearchResultItem
        className=""
        onClick={() => {
          selectItem(result);
          setResultsActive(false);
        }}
      >
        <div className="flex items-center">
          {icon}
          <div className="flex flex-column ml-3">
            <div className="font-semibold text-sm">{result.name}</div>
            <div className="text-xs">{result.description}</div>
          </div>
        </div>
      </SearchResultItem>
    );
  });

  const showResults = resultsActive && results.length > 0;

  return (
    <div className="map-searchbar__wrapper">
      {resultsActive && (
        <div
          className="map-searchbar__overlay"
          onClick={() => setResultsActive(false)}
        />
      )}

      <input
        type="text"
        className={`map-searchbar__input ${showResults && 'map-searchbar__input-results-active'}`}
        placeholder={t('search') || ''}
        onFocus={() => setResultsActive(true)}
        onChange={(e) => setSearchkey(e.target.value)}
      />
      <Search className="map-searchbar__input-icon" />
      {showResults && (
        <div className="map-searchbar__results"> {renderResults}</div>
      )}
    </div>
  );
}

MapSearchbar.defaultProps = {};

export default MapSearchbar;
