import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Row } from './Flex';
import { TextSmall } from './Typography';

interface OptionSpan {
  color: string;
  title: string;
  icon: React.ReactNode;
  toggle: any;
}

const Option = styled.span<{ color: string }>`
  border: ${({ theme }) => theme?.border};
  border-radius: 25px;
  padding: 2px 13px 2px 7px;
  display: inline-block;
  margin: 3px;
  cursor: pointer;
  background-color: ${({ theme, color }) => color || theme.colors.background};
  opacity: 0.8;

  &:hover {
    border: ${({ theme }) => theme?.borderHover};
    background-color: ${({ color }) => color};
    opacity: 1;
  }

  p {
    display: inline-block;
    color: ${({ color, theme }) => (color ? 'black' : theme.colors.text)};
  }
`;

export function Chip(props: OptionSpan) {
  const { color, title, icon, toggle } = props;
  const { t } = useTranslation();
  return (
    <Option color={color} onClick={toggle} tabIndex={0}>
      <Row alignItems="center">
        {icon}
        <TextSmall>{t(title)}</TextSmall>
      </Row>
    </Option>
  );
}
