import { useQuery } from "@tanstack/react-query";

const baseURL = "";

export const useScheduleType = (type: string, id: string) =>
  useQuery({
    queryKey: ["scheduletype"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/schedule/${type}/${id}`);

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to load feedback.");
      }

      return response.json();
    },
  });
