import styled from 'styled-components';

interface FlexRowProps {
  alignItems?: string;
  justifyContent?: string;
  scrollable?: boolean;
}

export const Row = styled.div<FlexRowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};

  @media only screen and (max-width: 500px) {
    ${({ scrollable }) => (scrollable ?? false) && 'overflow-x: scroll;'};
  }
`;

Row.defaultProps = {
  alignItems: 'start',
  justifyContent: 'start',
  scrollable: false,
};

export const Column = styled.div<FlexRowProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

Column.defaultProps = {
  alignItems: 'start',
  justifyContent: 'start',
};

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media only screen and (max-width: 600px) {
    & {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
`;
