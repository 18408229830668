/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { type FC, type ComponentPropsWithoutRef, useState, useEffect } from 'react';
import { Calendar, CalendarBody, CalendarHeader, useCalendar } from 'react-hook-calendar';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight, Edit } from '@styled-icons/material-rounded';
import LoadingPage from '../containers/LoadingPage';
import { getAcronym, getUrlFriendlyName } from '../helper/util';
import { Assignment, useUserAssignments } from '../hooks/api/use-assignment';
import { Text, H2, H3 } from './Typography';
import { Column, Row } from './Flex';
import { useCurrentCourses, type Course } from '../hooks/api/use-course';
import { useSchedule } from '../hooks/api/use-account';
import { useSemester } from '../hooks/api/use-semester';
import Button from './Button';
import Icon from './Icon';
import MissingSchedule from './MissingSchedule';
import Subject from './Subject';
import Badge from './Badge';
import Overlay from './Overlay';
import ScheduleUpload from './ScheduleUpload';

interface DayProps extends ComponentPropsWithoutRef<'button'> {
  calendarDate: Date;
  appointments: Array<{ title: string; room: string; start: Date; end: Date }>;
}

const DayWrapper = styled.div<{ isToday?: boolean, isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 5em;
  margin: 0.25em 0.25em;
  color: ${({ theme }) => theme.colors.detail};
  border-radius: ${({ theme }) => theme.rounded.medium};
  ${({ isToday, isActive }) => isToday &&
    css`
      border: 2px solid ${({ theme }) => isActive ? theme.colors.primary : theme.colors.detail};
    `};
  ${({ isActive }) => isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.text};
      box-shadow: ${({ theme }) => theme.shadow.base};
    `};
`;

const SubjectDot = styled.div<{ color: string; isActive: boolean }>`
  position: relative;
  width: 0.625em;
  height: 0.625em;
  border-radius: ${({ theme }) => theme.rounded.full};
  background-color: ${({ color }) => color};
  opacity: ${({ isActive }) => (isActive ? '100%' : '40%')};
  &::before {
    content: "";
    position: absolute;
    top: calc(-1px - 0.1em); // border: 1px + offset: 0.1em;
    right: calc(-1px - 0.1em); // border: 1px + offset: 0.1em;
    bottom: calc(-1px - 0.1em); // border: 1px + offset: 0.1em;
    left: calc(-1px - 0.1em); // border: 1px + offset: 0.1em;
    border: solid 0.16em
      ${({ theme, isActive }) => (isActive ? theme.colors.background : theme.colors.secondary)};
    border-radius: 50%;
  }
`;

function generateColor(title: string): string {
  const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i+=1) {
      if (i === 5) color += letters[title.length % 16];
      else color += letters[title.charCodeAt(i % title.length) % 16];
    }
    return color;
}

const getCourseColor = (courses: any, title: string) => {
  if (!courses.data) return "#FF0000";
  const userCourse: Course | undefined = courses.data.find(
    (course: Course) => course.name === title
  );
  return userCourse ? userCourse.color : generateColor(title);
};

export const Day: FC<DayProps> = ({ calendarDate, appointments }) => {
  const { date, setDate } = useCalendar();
  const courses = useCurrentCourses();
  const isSelectedDay = (selectedDate: Date) => selectedDate.toDateString() === date.toDateString();

  return (
    <DayWrapper
      isToday={calendarDate.getDate() === new Date().getDate() && calendarDate.getMonth() === new Date().getMonth()}
      isActive={isSelectedDay(calendarDate)}
      onClick={() => {
        setDate(calendarDate);
      }}
    >
      <Text>
        {Intl.DateTimeFormat('sk-SK', { weekday: 'short' }).format(
          calendarDate,
        )}
      </Text>
      <H2 fontWeight={700}>{calendarDate.getDate()}</H2>
      <Row
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '0.625em' }}
      >
        {appointments
          .filter((appointment: any) => (
              calendarDate.getDate() === appointment.start.getDate() &&
              calendarDate.getMonth() === appointment.start.getMonth()
            ))
          .map((appointment) => (
              <SubjectDot
                key={`dot-${appointment.title}~${appointment.start.toISOString()}`}
                color={getCourseColor(courses, appointment.title)}
                isActive={isSelectedDay(calendarDate)}
              />
            ))}
      </Row>
    </DayWrapper>
  );
};

const ScheduleHeader = styled(CalendarHeader)`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.large};
  margin-bottom: 0.5em;
  padding: 0 0.5em 0 0.1em;
`;

const ScheduleWeekHeader: FC = () => {
  const { t } = useTranslation();
  const { date, setDate } = useCalendar();
  const semester = useSemester();
  const [week, setWeek] = useState<number>(semester.data?.currentWeek || 0);

  useEffect(() => {
    if (semester.data) {
      setWeek(semester.data.currentWeek);
    }
  }, [semester.data])

  const monthNames = Array.from({ length: 12 }, (_, index) =>
    t(`months.${index + 1}`)
  );

  const handlePreviousWeek = () => {
    const previousWeek = new Date(date);
    previousWeek.setDate(previousWeek.getDate() - 7);
    setDate(previousWeek);
    setWeek(week - 1);
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(date);
    nextWeek.setDate(nextWeek.getDate() + 7);
    setDate(nextWeek);
    setWeek(week + 1);
  };

  return (
    <Row
      alignItems="center"
      justifyContent="space-between"
      style={{ marginBottom: "0.5em" }}
    >
      <Button
        variant="icon"
        icon={<ChevronLeft size={24} />}
        onClick={handlePreviousWeek}
      />
      {week >= 1 && week <= 13 ? (
        <Column alignItems="center">
          <H3 fontWeight={600}>{t("selectWeek", { week })}</H3>
          <Text>{monthNames[date.getMonth()]}</Text>
        </Column>
      ) : (
        <H3 fontWeight={600}>{monthNames[date.getMonth()]}</H3>
      )}
      <Button
        variant="icon"
        icon={<ChevronRight size={24} />}
        onClick={handleNextWeek}
      />
    </Row>
  );
}

export interface Appointment {
  title: string;
  type: string;
  room: string;
  start: Date;
  end: Date;
}

const AssignmentList = styled.ul`
  width: 100%;
`;

const AssignmentStrip = styled.li`
  padding: 0.4em 0.75em;
  margin-bottom: 0.4em;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.rounded.medium};
  color: ${({ theme }) => theme.colors.background};
  font-size: 0.8em;
  font-weight: 600;
`;

const passedSemesterWeeks = (date: Date, semesterStartDate: Date) => {
  const difference = Math.abs(date.getTime() - semesterStartDate.getTime());
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const weeks = difference / millisecondsInDay / 7;

  if (Math.abs(weeks - Math.floor(weeks)) < 0.9) {
    return Math.floor(weeks);
  } 
  return Math.ceil(weeks);
  
};

interface ScheduleEventsProps {
  editable: boolean
  appointments: Appointment[];
}

const ScheduleEvents: FC<ScheduleEventsProps> = ({ editable, appointments }) => {
  const { date } = useCalendar();
  const courses = useCurrentCourses();
  const assignments = useUserAssignments();
  const semester = useSemester();

  const getCourseLink = (title: string) => {
    if (!courses.data) return "/error";
    const userCourse: Course | undefined = courses.data.find(
      (course: Course) => course.name === title
    );
    return userCourse ? `/course/${getUrlFriendlyName(userCourse.name)}` : "";
  };

  return (
    <Column>
      {editable && assignments.data && semester.data && (
        <AssignmentList>
          {assignments.data
            .filter((a: Assignment) => a.dueWeek === passedSemesterWeeks(date, new Date(semester.data.startDate))+1)
            .map((a: Assignment) => (
              <AssignmentStrip key={a.name}>
                <strong>{getAcronym(a.studentCourse.name)}:</strong> {a.name}
              </AssignmentStrip>
            ))}
        </AssignmentList>
      )}

      {appointments
        .filter(
          (appointment) =>
            date.getDate() === appointment.start.getDate() &&
            date.getMonth() === appointment.start.getMonth()
        )
        .map((appointment) => (
          <Subject
            key={`${appointment.room}~${appointment.start.toISOString()}`}
            color={getCourseColor(courses, appointment.title)}
            name={appointment.title}
            time={`${appointment.start.getHours().toString().padStart(2, "0")}:${appointment.start.getMinutes().toString().padStart(2, "0")} - ${appointment.end.getHours().toString().padStart(2, "0")}:${appointment.end.getMinutes().toString().padStart(2, "0")}`}
            room={appointment.room}
            courseLink={getCourseLink(appointment.title)}
            openable={editable}
          >
            <Badge variant="secondary" subject={appointment.type} />
          </Subject>
        ))}
    </Column>
  );
}

interface TimeTableProps {
  editable: boolean;
  appointments: Appointment[];
}

export const TimeTable: FC<TimeTableProps> = ({ appointments, editable }) => {
  const { t } = useTranslation();
  const schedule = useSchedule();
  const [isOverlayShown, setIsOverlayShown] = useState<boolean>(false);
  
  return <>
    {(schedule.isLoading && appointments.length === 0) || schedule.isRefetching ? (
      <LoadingPage />
    ) : (
      <>
        {editable && (
          <Row
            justifyContent={
              schedule.data && schedule.data.length > 0
                ? "space-between"
                : "left"
            }
            alignItems="center"
          >
            <H2 fontWeight={600}>{t("scheduleShort")}</H2>
            {schedule.data && schedule.data.length > 0 && (
              <Button
                variant="circular"
                onClick={() => {
                  setIsOverlayShown(true);
                }}
                icon={
                  <Icon size={16}>
                    <Edit />
                  </Icon>
                }
              />
            )}
          </Row>
        )}
        <Calendar
          defaultView="week"
          timeStart="6:00"
          timeEnd="20:00"
          weekStartsOn={1}
        >
          <ScheduleWeekHeader />
          <ScheduleHeader>
            {({ date }) => (
              <Day
                calendarDate={date}
                appointments={appointments}
              />
            )}
          </ScheduleHeader>
          <CalendarBody style={{ display: "flex" }}>
            <ScheduleEvents appointments={appointments} editable={editable} />
          </CalendarBody>
        </Calendar>
      </>
    )}

    {schedule.data?.length === 0 && !schedule.isRefetching && <MissingSchedule />}

    <Overlay
      isOpen={isOverlayShown}
      onClose={() => {
        setIsOverlayShown(false);
      }}
    >
      <ScheduleUpload edit />
    </Overlay>
  </>
}
