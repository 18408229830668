import React, { type FC, useState } from 'react';
import styled from 'styled-components';
import { TextSmall } from './Typography';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const TooltipContent = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 130%;
  left: 50%;
  width: auto;
  white-space: nowrap;
  transform: translateX(-50%);
  padding: 0.25em 0.5em;
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.rounded.base};
  box-shadow: ${({ theme }) => theme.shadow.large};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const Tooltip: FC<TooltipProps> = ({ text, children }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <TooltipContainer
      onMouseEnter={() => {
        setTooltipVisible(true);
      }}
      onMouseLeave={() => {
        setTooltipVisible(false);
      }}
      onTouchStart={() => {
        setTooltipVisible(true);
      }}
      onTouchEnd={() => {
        setTooltipVisible(false);
      }}
    >
      <TooltipContent isVisible={isTooltipVisible}>
        <TextSmall>{text}</TextSmall>
      </TooltipContent>
      {children}
    </TooltipContainer>
  );
};

export default Tooltip;
