import React, { FC, useState, ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { TextSmall } from './Typography';
import { Row } from './Flex';

interface MultistepProps {
  onNextClick?: () => void
  onBackClick?: () => void
}

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 12em;
`;

const MultistepContainer = styled.div`
  width: 100%;
  height: 100%;
`;

interface MultistepProps {
  children: ReactNode;
}

const Multistep: FC<MultistepProps> = ({ onNextClick, onBackClick, children }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(0);
  const totalSteps = React.Children.count(children);

  const handleNextStep = () => {
    if (step < totalSteps - 1) setStep(step + 1);
    if (onNextClick) onNextClick()
  };

  const handlePreviousStep = () => {
    if (step > 0) setStep(step - 1);
    if (onBackClick) onBackClick()
  };

  const renderStep = () => React.Children.map(children, (child, index) => {
      if (index === step) {
        return child;
      }
      return null;
    });

  return (
    <MultistepContainer>
      <Row justifyContent={step !== 0 ? 'space-between' : 'right'} alignItems="center">
        {step !== 0 && (<Button variant="ghost" onClick={handlePreviousStep}>{t('back')}</Button>)}
        <TextSmall align="right">{step + 1}/{totalSteps}</TextSmall>
      </Row>
      
      {renderStep()}

      {step < totalSteps-1 && (
        <Button style={{ width: "100%" }} variant="primary" onClick={handleNextStep}>
          {t('continue')}
        </Button>
      )}
    </MultistepContainer>
  );
};

const Step: FC = ({ children }) => (
  <StepContainer>
    { children }
  </StepContainer>
);

export { Multistep, Step };

Multistep.defaultProps = {
  onNextClick: undefined,
  onBackClick: undefined
}