import React, { useRef, FC, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { AdminPanelSettings, Apps, BugReport, DateRange, Home, IosShare, Map, Menu, Restaurant, KeyboardArrowUp, KeyboardArrowDown } from '@styled-icons/material-rounded';
import { useTranslation } from 'react-i18next';
import LanguageToggler from '../components/LanguageToggler';
import Button from '../components/Button';
import ThemeToggler from '../components/ThemeToggler';
import { Text, TextSmall } from '../components/Typography';
import { Column, Row } from '../components/Flex';
import { Course, useCurrentCourses } from '../hooks/api/use-course';
import Icon from '../components/Icon';
import { getUrlFriendlyName } from '../helper/util';
import useAuth from '../hooks/use-auth';
import Profile from '../components/Profile';
import ReportBugForm from '../components/forms/ReportBugForm';
import Overlay from '../components/Overlay';
import Mockup from '../assets/images/mockup.png'

const TopBar = styled.div`
  position: fixed;
  display: flex;
  justify-content: left;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5em 0.75em;
  box-sizing: border-box;
  border-bottom: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(20px); /* Apply blur effect */
  z-index: 1;

  @media (min-width: 75em) {
    display: none;
  }
`

const Dimming = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 2;
`;

const SidebarContainer = styled.div<{ isExpanded: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ isExpanded }) => (isExpanded ? '0' : '-18em')};
  width: 18em;
  height: 100%;
  display: flex;
  margin: 0 0 1em 0;
  flex-direction: column;
  justify-content: space-between;
  border-right: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.background};
  transition: left 0.2s ease-in-out;
  z-index: 3;

  @media(min-width: 75em) {
    left: 0;
  }
`;

const Tab = styled.a<{ isActive: boolean }>`
  position: relative;
  font-size: 1em;
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  padding: 0.5em 0.75em;
  font-weight: ${({ theme }) => theme.weights.medium};
  border-radius: ${({ theme }) => theme.rounded.medium};
  text-align: left;
  gap: 0.75em;
  color: ${({ theme }) => theme.colors.detail};
  ${({ isActive }) => isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary};
      color: ${({ theme }) => theme.colors.text};
      cursor: default;
    `};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.text};
  }

  & > p {
    padding-left: 1.5em;
  }
`;

const TabGroup = styled.ul`
  width: 100%;
  padding: 0 1em 1em 1em;
  margin: 1em 0 0 0;
`;

const GroupItem = styled.li``;

const ExpandableGroupItem = styled(GroupItem)<{ expanded: boolean }>`
  position: relative;
  ${GroupItem} {
    display: ${({ expanded }) => expanded ? 'block' : 'none'};

    ${Tab} {
      padding-left: 1em;
    }
  }
`;

const SubjectDot = styled.div<{ color: string }>`
  position: absolute;
  left: 1em;
  width: 0.75em;
  height: 0.75em;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.rounded.full};
`;

const ReportBug = styled(Button)`
  color: ${({ theme }) => theme.colors.detail};
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.text};
  }
`

const InstallPWAPanel = styled.div`
  width: 100%;
  padding: 0.75em 1em 0 1em;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const CollapsibleItem: FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return <ExpandableGroupItem expanded={isCollapsed}>
    <Button variant="circular"
      style={{ position: 'absolute', right: '1em', top: '0.2em', zIndex: '1', backgroundColor: 'transparent', width: '2.5em', height: '2.5em' }} 
      onClick={() => {setIsCollapsed(!isCollapsed)}} 
    >
      {isCollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </Button>
    {children}
  </ExpandableGroupItem>
}

const ExpandableSidebar: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, isLoading, isAdmin, login } = useAuth();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>();
  const [displayInstall, setDisplayInstall] = useState<boolean>(sessionStorage.getItem("ignoreInstall") !== "true");
  const [isIOS, setIsIOS] = useState<boolean>(false);

  const [showBugReport, setShowBugReport] = useState<boolean>(false);

  const courses = useCurrentCourses();

  const handleClickOutside = (event: any) => {
    if (
      sidebarRef.current != null &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const ios = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (ios) {
      setDeferredPrompt(!window.matchMedia('(display-mode: standalone)').matches);
      setIsIOS(ios);
    }

    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallPrompt = () => {
    if (deferredPrompt) {
        deferredPrompt.prompt();
    }
  };

  const closeInstall = () => {
    setDisplayInstall(false);
    sessionStorage.setItem("ignoreInstall", "true");
  }

  return (
    <>
      <TopBar>
        <Button 
          variant='icon' 
          onClick={() => setIsOpen(true)}
          icon={<Icon><Menu /></Icon>} />
      </TopBar>
      <Dimming onMouseDown={handleClickOutside} show={isOpen} />
      <SidebarContainer ref={sidebarRef} isExpanded={isOpen}>
        <Column>
          <TabGroup>

            {!isLoading && isAuthenticated() && isAdmin() && (
              <GroupItem>
                <Tab
                  href="/admin/users"
                  isActive={/^\/admin(?:\/|$)/.test(location.pathname)}
                >
                  <Icon size={28} >
                    <AdminPanelSettings />
                  </Icon>
                  Admin
                </Tab>
              </GroupItem>
            )}

            <GroupItem>
              <Tab
                href="/"
                isActive={location.pathname === '/'}
              >
                <Icon size={28} >
                  <Home />
                </Icon>
                {t('home')}
              </Tab>
            </GroupItem>

            { isAuthenticated() && (
              <GroupItem>
                <Tab
                  href="/schedule"
                  isActive={location.pathname === '/schedule'}
                >
                  <Icon size={28} >
                    <DateRange />
                  </Icon>
                  {t('scheduleShort')}
                </Tab>
              </GroupItem>
            )}


            <GroupItem key="map">
              <Tab href="/map" isActive={/^\/map(?:\/|$)/.test(location.pathname)}>
                <Icon size={28}>
                  <Map />
                </Icon>
                {t('map')}
              </Tab>
            </GroupItem>

            <GroupItem>
              <Tab
                href="/canteen"
                isActive={location.pathname === '/canteen'}
              >
                <Icon size={28}>
                  <Restaurant />
                </Icon>
                {t('canteens')}
              </Tab>
            </GroupItem>

            { isAuthenticated() && (
              <CollapsibleItem>
                <Tab
                  href="/courses"
                  isActive={location.pathname === '/courses'}
                >
                  <Icon size={28}>
                    <Apps />
                  </Icon>
                  {t('subjects')}
                </Tab>
                {courses.data && courses.data.length > 0 &&
                  <ul>
                    {courses.isLoading && <Text>Načítava sa...</Text>}
                    {courses.data.length !== 0 &&
                      courses.data.map((course: Course) => (
                          <GroupItem key={`course-${course.id}`}>
                            <Tab
                              href={`/course/${getUrlFriendlyName(course.name)}`}
                              isActive={location.pathname === `/course/${getUrlFriendlyName(course.name)}`}
                            >
                              <SubjectDot color={course.color} />
                              <Text fontWeight={500}>{course.name}</Text>
                            </Tab>
                          </GroupItem>
                        ))
                    }
                  </ul>
                }
              </CollapsibleItem>
            )}
            
          </TabGroup>
        </Column>

        <Column>
          {deferredPrompt && displayInstall && (
            <InstallPWAPanel>
              <Row style={{ gap: '1em' }}>            
                <img src={Mockup} alt="Mobil" style={{ width: '4em' }}/>
                <div style={{ marginBottom: '0.75em' }}>
                  {isIOS ? (
                    <>
                      <TextSmall fontWeight={500} style={{ marginBottom: '0.5em' }}>{t('TUNAappiOS1')} <IosShare size={18} color="#007AFF" /> {t('TUNAappiOS2')}</TextSmall>
                      <Row alignItems="center" justifyContent="flex-end">
                        <Button variant="ghost" onClick={closeInstall}><TextSmall color='detail'>{t('later')}</TextSmall></Button>
                      </Row>
                    </>
                  ) : (
                    <>
                      <TextSmall fontWeight={500} style={{ marginBottom: '0.5em' }}>{t('TUNAapp')}</TextSmall>
                      <Row alignItems="center" justifyContent="space-between">
                        <Button variant="ghost" onClick={closeInstall}><TextSmall color='detail'>{t('later')}</TextSmall></Button>
                        <Button variant="primary" onClick={handleInstallPrompt}><TextSmall>{t('install')}</TextSmall></Button>
                      </Row>
                    </>
                  )}
                </div>
              </Row>
            </InstallPWAPanel>
          )}
        
          <TabGroup>

            <GroupItem>
              <Row>
                <ThemeToggler />
                <LanguageToggler />
                <ReportBug variant="ghost"
                  title={t('reportBug') || ""}
                  onClick={() => {
                    if (!isAuthenticated()) login();
                    else setShowBugReport(true);
                  }}
                  icon={<Icon><BugReport /></Icon>}
                />
              </Row>  
            </GroupItem>

            <Profile />

          </TabGroup>
        </Column>
      </SidebarContainer>

      <Overlay isOpen={showBugReport} onClose={() => {setShowBugReport(false)}}>
        <ReportBugForm onSubmitCallback={() => {setShowBugReport(false)}} />
      </Overlay>
    </>
  );
}

export default ExpandableSidebar;
