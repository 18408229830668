import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next';
import { Text } from '../components/Typography'

const loaderWidth = '16em';
const loaderDotSize = '1.25em';

const loaderAnim = keyframes`
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(calc(${loaderWidth} - ${loaderDotSize}));
  }
  65% {
    transform: translateX(calc(${loaderWidth} - ${loaderDotSize}));
  }
  95% {
    transform: translateX(0);
  }
`;

const loadingText = keyframes`
  0% {
    content: "";
  }

  25% {
    content: ".";
  }

  50% {
    content: "..";
  }

  75% {
    content: "...";
  }
`;

const Loader = styled.div`
  height: ${loaderDotSize};
  width: ${loaderWidth};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .loader--dot {
    animation: ${loaderAnim} 3s ease-in-out infinite;
    height: ${loaderDotSize};
    width: ${loaderDotSize};
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: ${({ theme }) => `0.15em solid ${theme.colors.background}`};

    &:first-child {
      background-color: ${({ theme }) => theme.colors.fei};
      animation-delay: 0.8s;
    }

    &:nth-child(2) {
      background-color: ${({ theme }) => theme.colors.svf};
      animation-delay: 0.7s;
    }

    &:nth-child(3) {
      background-color: ${({ theme }) => theme.colors.fmmr};
      animation-delay: 0.6s;
    }

    &:nth-child(4) {
      background-color: ${({ theme }) => theme.colors.ekf};
      animation-delay: 0.5s;
    }

    &:nth-child(5) {
      background-color: ${({ theme }) => theme.colors.fvt};
      animation-delay: 0.4s;
    }

    &:nth-child(6) {
      background-color: ${({ theme }) => theme.colors.sjf};
      animation-delay: 0.3s;
    }

    &:nth-child(7) {
      background-color: ${({ theme }) => theme.colors.lf};
      animation-delay: 0.2s;
    }

    &:nth-child(8) {
      background-color: ${({ theme }) => theme.colors.fu};
      animation-delay: 0.1s;
    }

    &:nth-child(9) {
      background-color: ${({ theme }) => theme.colors.fberg};
      animation-delay: 0s;
    }
  }

  .loader--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: fit-content;
    margin: auto;

    &:after {
      content: "";
      animation: ${loadingText} 3s infinite;
    }
  }
`;

const LoadingPage: FC = () => {
  const { t } = useTranslation();

  return <main style={{ height: "50vh" }}>
      <Loader>
        <div className="loader--dot" />
        <div className="loader--dot" />
        <div className="loader--dot" />
        <div className="loader--dot" />
        <div className="loader--dot" />
        <div className="loader--dot" />
        <div className="loader--dot" />
        <div className="loader--dot" />
        <div className="loader--dot" />
        <Text fontWeight={500} className="loader--text">{t('loading')}</Text>
      </Loader>
    </main>
};

export default LoadingPage;