import { FC, useEffect, useState } from 'react'
import { AccountCircle, Login, Logout } from '@styled-icons/material-rounded';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/use-auth';
import { Row } from './Flex'
import Button from './Button'
import Icon from './Icon';
import { H2, Text, TextSmall } from './Typography';
import Overlay from './Overlay';
import EditUserForm from './forms/EditUserForm';
import { useAccount } from '../hooks/api/use-account';
import { Step, Multistep } from './Multistep';
import explore from '../assets/images/explore.svg';

const ProfileTab = styled(Row)`
  margin-top: 0.5em;
  padding: 0 0 0 1em;
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.rounded.medium};
`

const Profile: FC = () => {
  const { t } = useTranslation();
  const [showNewUserOverlay, setShowNewUserOverlay] = useState<boolean>(false);
  const { user, isAuthenticated, isLoading, isEmployee, login } = useAuth();
  const account = useAccount();

  useEffect(() => {
    if (isAuthenticated() && account.data && !account.data.faculty && !isEmployee()) setShowNewUserOverlay(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated(), account.data])

  const imageAlt: string = t('exploreImgAlt');

  if (!isLoading && isAuthenticated() && user) {
    return (
      <>
        <ProfileTab alignItems="center" justifyContent="space-between">
          <Row alignItems="center" style={{ gap: '0.4em', cursor: 'pointer' }} onClick={() => {setShowNewUserOverlay(true)}}>
            <Icon><AccountCircle /></Icon>
            <TextSmall fontWeight={600}>{user.fullName}</TextSmall>
          </Row>
          <Button
            onClick={() => {window.location.href = "/logout"}}
            variant='ghost'
            title={t('logOut') ?? ''}
            icon={<Icon size={20} color="detail"><Logout /></Icon>}
          />
        </ProfileTab>

        <Overlay isOpen={showNewUserOverlay} onClose={() => {setShowNewUserOverlay(false)}}>
          <Multistep>
            <Step>
              <img style={{ width: '12em', margin: '1em 0 1.5em 0' }} src={explore} alt={imageAlt} />
              <H2 fontWeight={600}>{t('welcome')} 👋</H2>
              <Text align="center" color="detail" style={{ marginBottom: '2em' }}>{t('tunaDescription')}</Text>
            </Step>
            <Step>
              <EditUserForm onSubmitCallback={() => {setShowNewUserOverlay(false)}} />
            </Step>
          </Multistep>
        </Overlay>
      </>
    )
  }

  return (
    <Button variant="outline" onClick={login} style={{ width: '100%', marginTop: "0.5em" }}>
      <Row alignItems="center" justifyContent="center" style={{ gap: "0.5em" }}>
        <Login size={20} />
        <Text>{t('logIn')}</Text>
      </Row>
    </Button>
  );
}

export default Profile