import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCanteenMenu, rateCanteenFood } from '../api/api';
import { Canteen } from '../api/api.types';
import { Text } from './Typography';
import { IconStar, IconStarOutline } from './Icons';
import LoadingPage from '../containers/LoadingPage';

const CardContent = styled.div`
  padding: 5px 10px 5px 10px;
`;

const Divider = styled.span`
  width: 100%;
  height: 1px;
  display: block;
  border-top: ${({ theme }) => theme.border};
  margin-top: 8px;
`;

const EmptyStar = styled(IconStarOutline)`
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.25);
  }
`;

interface Props {
  canteen: Canteen;
  date: Date;
}

function CanteenMenuItem({
  item,
  rate,
}: {
  item: Canteen['menus'][number]['items'][number];
  rate: (idCanteenFood: number) => Promise<void>;
}) {
  const [isRated, setRated] = useState(false);

  const formatDescription = (description: string) => description.split(',').join(', ');

  useEffect(() => {
    setRated(localStorage.getItem(item.food.idCanteenFood.toString()) != null);
  }, [item.food.idCanteenFood]);

  return (
    <div
      key={item.idCanteenMenuItem}
      style={{
        display: 'grid',
        gridTemplateColumns: '70% 15% 15%',
        marginBottom: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          wordBreak: 'break-word',
          paddingRight: '8px',
        }}
      >
        <Text>
          {item.food.name}
          {item.food.allergens !== '' && (
            <span
              style={{ padding: 0, fontWeight: 'lighter', fontSize: '14px' }}
            >
              {' '}
              ({item.food.allergens})
            </span>
          )}
        </Text>
        <Text style={{ padding: 0, fontWeight: 'lighter', fontSize: '15px' }}>
          {formatDescription(item.food.description)}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          marginTop: '4px',
        }}
      >
        {isRated ? (
          <IconStar size={24} />
        ) : (
          <EmptyStar
            size={24}
            onClick={async () => {
              await rate(item.food.idCanteenFood);

              localStorage.setItem(item.food.idCanteenFood.toString(), '');
              setRated(true);
            }}
          />
        )}
        <Text style={{ padding: 0, fontWeight: 'lighter', fontSize: '16px' }}>
          {item.food.rating}x
        </Text>
      </div>
      <Text
        style={{
          marginLeft: 'auto',
          alignSelf: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {item.price}
      </Text>
    </div>
  );
}

export function CanteenMenu({ canteen, date }: Props) {
  const [menu, setMenu] = useState<Canteen['menus'][number]['items']>();

  const itemsByCategory = menu?.reduce(
    (acc, item) => {
      if (!acc[item.food.category]) acc[item.food.category] = [];
      acc[item.food.category].push(item);
      return acc;
    },
    {} as Record<string, typeof menu>,
  );

  useEffect(() => {
    setMenu(undefined);

    (async () => {
      try {
        const m = await getCanteenMenu(canteen.idCanteen, date);
        setMenu(m);
      } catch (e) {
        setMenu(undefined);
      }
    })();
  }, [canteen.idCanteen, date]);

  const rate = async (idCanteenFood: number) => {
    await rateCanteenFood(idCanteenFood);

    setMenu((oldMenu) => oldMenu?.map((i) => {
        const { food } = i;

        if (food.idCanteenFood === idCanteenFood) {
          food.rating += 1;
        }

        return {
          ...i,
          food,
        };
      }));
  };

  if (menu === undefined || itemsByCategory === undefined) {
    return <LoadingPage />;
  }

  return (
    <CardContent>
      {Object.entries(itemsByCategory).map(([category, items]) => (
        <div key={category}>
          <Text style={{ fontWeight: 'bold' }}>{category}</Text>
          {items.map((item) => (
            <CanteenMenuItem item={item} rate={rate} />
          ))}
          <Divider />
        </div>
      ))}
    </CardContent>
  );
}
