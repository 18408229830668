export default {
  type: "light",
  mapUrl:
    "https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token={accessToken}",
  colors: {
    background: "rgb(255,255,255)",
    background10: "rgba(255,255,255,0.1)",
    background50: "rgba(255,255,255,0.5)",
    pressBackground: "#f7f7f7",
    shadow: "#C0C0C0",
    icons: "#787c81",
    text: "#232323",
    detail: "rgb(35,35,35,0.5)",
    primary: "#000000",
    secondary: "rgb(246,246,246)",
    secondary80: "rgba(246, 246, 246, 0.8)",
    accent: "#eaaa00",
    destructive: "#ff4d4d",
    glass: "rgba(255, 255, 255, 0.4)",
    fei: "#eaaa00",
    svf: "#e57200",
    fmmr: "#d50032",
    ekf: "#a51890",
    fvt: "#5f259f",
    sjf: "#003dA5",
    lf: "#41b6e6",
    fu: "#84bd00",
    fberg: "#00843d",
  },
  size: {
    linkIcons: "48",
    buttonIcons: "30",
    text: "1em",
    icon: "10px",
  },
  weights: {
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
  },
  rounded: {
    small: "0.125em",
    base: "0.25em",
    medium: "0.375em",
    large: "0.5em",
    XLarge: "0.75em",
    full: "9999px",
  },
  shadow: {
    small: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    base: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    medium: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    large: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  },
  courseColor: {
    turquoise: "#1abc9c",
    alizarinCrimson: "#e74c3c",
    sunflower: "#f1c40f",
    emerald: "#2ecc71",
    amethyst: "#9b59b6",
    carrot: "#e67e22",
    riverBlue: "#3498db",
    wisteria: "#8e44ad",
    bluebell: "#2980b9",
    pumpkin: "#d35400",
    greenSea: "#16a085",
    nephritis: "#27ae60",
    midnightBlue: "#2c3e50",
    wetAsphalt: "#34495e",
    orange: "#f39c12",
    pomegranate: "#c0392b",
    concrete: "#7f8c8d",
    asbestos: "#95a5a6",
    silver: "#bdc3c7",
    maroon: "#800000",
  },
  brightness: "brightness(0.95)",
  shadowHover: "0 5px 15px -6px rgba(75,75,75,0.9)",
  border: "1px solid #D6D6D6",
  borderHover: "1px solid #F3F3F3",
  outline: "1px solid #D6D6D6",
  outlineHover: "1px solid #F3F3F3",
  margins: "20px 3px",
  textMargins: "5px 0px",
  paddings: "0.8em",
  smallPaddings: "0.3em",
  titleImage: "tuna-logo-black.png",
};
