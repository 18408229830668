import { FC } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "./Button";
import { Text } from "./Typography"

const TogglerButton = styled(Button)`
  color: ${({ theme }) => theme.colors.detail};
  
  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`

const LanguageToggler: FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === "sk") {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
    } else {
      localStorage.setItem("lang", "sk");
      i18n.changeLanguage("sk");
    }
  };

  return (
    <TogglerButton
      variant="ghost"
      title={t("change language") ?? ""}
      style={{ width: "100%", justifyContent: "left" }}
      onClick={() => {
        changeLanguage();
      }}
    >
      <Text color="inherit" fontWeight={700}>
        {i18n.language === "sk" ? "EN" : "SK"}
      </Text>
    </TogglerButton>
  );
};

export default LanguageToggler;
