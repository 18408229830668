import React, { type ComponentPropsWithoutRef, type FC } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Row } from '../Flex';
import Margin from '../Margin';

interface InputProps extends ComponentPropsWithoutRef<'input'> {
  name: string;
  label?: string;
  validation?: any;
}

const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
`;

const InputField = styled.input<InputProps>`
  padding: 0.75em;
  font-size: 1em;
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.rounded.medium};
  color: ${({ theme }) => theme.colors.text};
`;

const AbsoluteLabel = styled.label`
  position: absolute;
  top: 0.5em;
  left: 1em;
`

const Input: FC<InputProps> = ({
  label,
  type,
  value,
  id,
  name,
  validation,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  if (type === 'radio') {
    return (
      <Row alignItems="center" style={{ gap: '0.5em' }}>
        <InputField
          id={id}
          type="radio"
          value={value}
          {...register(name, validation)}
          {...rest}
        />
        <Label htmlFor={id}>{label}</Label>
        <ErrorMessage errors={errors} name={name} />
      </Row>
    );
  }
  if (type === 'number') {
    return (
      <InputContainer>
        <AbsoluteLabel>{label}</AbsoluteLabel>
        <InputField
          id={id}
          type={type}
          value={value}
          style={{ textAlign: "right" }}
          {...register(name, validation)}
          {...rest} 
        />
      </InputContainer>
    );
  }
  return (
    <InputContainer>
      {label && (
        <Margin size={0.25} direction="bottom">
          <Label htmlFor={id}>{label}</Label>
        </Margin>
      )}
      <InputField
        id={id}
        type={type}
        value={value}
        {...register(name, validation)}
        {...rest}
      />
      <ErrorMessage errors={errors} name={name} />
    </InputContainer>
  );
};

export default Input;

Input.defaultProps = {
  label: '',
  validation: null,
};
