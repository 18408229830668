import React, { ComponentPropsWithoutRef, type FC } from 'react';
import styled from 'styled-components';

interface AnnouncementProps extends ComponentPropsWithoutRef<'a'> {
}

const Wrapper = styled.a`
  display: flex;
  gap: 0.75em;
  padding: 0.5em 1em;
  background-color: ${({ theme }) => theme.colors.background};
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.rounded.medium};
  box-shadow: ${({ theme }) => theme.shadow.base};
  cursor: pointer;
`;

const Announcement: FC<AnnouncementProps> = ({ href, onClick, children }) => (
    <Wrapper href={href} target="_blank" onClick={onClick}>
      {children}
    </Wrapper>
  );

export default Announcement;