import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { Text } from "./Typography"
import { Row } from "./Flex";

const slideInDesktop = keyframes`
  from {
    transform: translateY(200%);
    bottom: 0;
    opacity: 0;
  }
  to {
    transform: translateY(50%);
    bottom: 50%;
    opacity: 100%;
  }
`;

const slideInMobile = keyframes`
  from {
    transform: translateY(200%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 100%;
  }
`;

const Dimming = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 6;
`;

const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2em;
  z-index: 7;
  background-color: ${({ theme }) => theme.colors.background};
  border-top-left-radius: ${({ theme }) => theme.rounded.large};
  border-top-right-radius: ${({ theme }) => theme.rounded.large};
  animation: ${slideInMobile} 0.3s ease forwards;

  @media (min-width: 75em) {
    bottom: 50%;
    max-width: calc(900px - 2em);
    margin-left: calc(50% - 18em);
    padding: 2em;
    border-radius: ${({ theme }) => theme.rounded.large};
    animation: ${slideInDesktop} 0.3s ease forwards;
  }
`

interface ConfirmationModalProps {
  message: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ message, isOpen, onConfirm, onCancel }) =>  {
  const { t } = useTranslation();

  const handleDimmingClick = (e: any) => {
    if (e.target === e.currentTarget) {
      onCancel();
    }
  };

  if (!isOpen) return null;
  
  return (
    <>
      <Dimming onClick={handleDimmingClick} />
      <ModalContainer>
        <Text className="mb-3">{message}</Text>
        <Row justifyContent="space-between">
          <Button variant="outline" onClick={onCancel}>{t('cancel')}</Button>
          <Button variant="primary" onClick={onConfirm}>{t('confirm')}</Button>
        </Row>
      </ModalContainer>
    </>
  )
};

export default ConfirmationModal;