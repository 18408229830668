import { useQuery } from "@tanstack/react-query";

const baseURL = "https://at.tuke.sk";

export const usePerson = (idPerson: string) =>
  useQuery({
    queryKey: ["person"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/employee/${idPerson}`);

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to load feedback.");
      }

      return response.json();
    },
  });
