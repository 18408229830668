import React, { useState, useMemo } from 'react';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import lightTheme from './theme/light';
import darkTheme from './theme/dark';
import { GlobalStyles } from './styles/GlobalStyles';
import RouterResolver from './router/RouterResolver';

function App() {
  const getInitialTheme = () => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme != null) {
      return storedTheme;
    }
    return window.matchMedia('(prefers-color-scheme: light)').matches
      ? 'light'
      : 'dark';
  };

  const [theme, setTheme] = useState(getInitialTheme());

  const memoizedValue = useMemo(() => {
    const toggleTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
      localStorage.setItem('theme', newTheme);
    };
    return { theme, toggleTheme };
  }, [theme]);

  const themeFile = theme === 'light' ? lightTheme : darkTheme;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 60 * 24, // 24 hours
      },
    },
  });

  const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persister: localStoragePersister,
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContext.Provider value={memoizedValue}>
        <ThemeProvider theme={themeFile}>
          <RouterResolver />
          <GlobalStyles theme={themeFile} />
          <Toaster
            position="top-right"
            toastOptions={{
              className: 'toast',
              style: {
                zIndex: 10,
              },
            }}
          />
        </ThemeProvider>
      </ThemeContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
