import { useMutation, useQuery } from "@tanstack/react-query";

export interface Feedback {
  idFeedback?: number;
  login?: string;
  createdOn: Date;
  content: string;
  reply?: string;
  type: string;
}

const baseURL = "";

export const useFeedback = () =>
  useQuery({
    queryKey: ["feedback"],
    queryFn: async () => {
      const response = await fetch(`${baseURL}/api/feedback`);

      if (!response.ok) {
        const error = await response.json();
        // UNAUTHORIZED
        if (response.status === 401) {
          throw new Error(
            error.message || "Unauthorized! Please log in again."
          );
        }
        throw new Error(error.message || "Failed to load feedback.");
      }

      return (await response.json()) as Feedback[];
    },
  });

export const useSendFeedback = () =>
  useMutation({
    mutationFn: async (feedback: Feedback) => {
      const response = await fetch(`${baseURL}/api/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedback),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to send feedback");
      }

      return response;
    },
    onError: (error) => {
      console.error("Error sending feedback:", error);
    },
  });
