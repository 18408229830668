import React, {
  useRef,
  type ComponentPropsWithoutRef,
  type FC,
  type ChangeEvent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Row } from '../Flex';
import { Text } from '../Typography';

interface InputProps extends ComponentPropsWithoutRef<'input'> {
  onUpload: (event: ChangeEvent<HTMLInputElement>) => void;
}

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 0.5em 0.75em;
  border-radius: ${({ theme }) => theme.rounded.medium};
`;

const Input: FC<InputProps> = ({
  id,
  onUpload,
  ...rest
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileItemRef = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);

    if (fileSizeInMB > 3 && fileItemRef.current) {
      fileItemRef.current.innerHTML = t('input.fileSizeTooBig');
      return;
    }

    const regex = /\.ics$/i;
    if (!regex.test(file.name) && fileItemRef.current) {
      fileItemRef.current.innerHTML = t('input.fileTypeInvalid');
      return;
    }

    if (fileItemRef.current) {
      fileItemRef.current.innerHTML = file.name;
      if (onUpload) onUpload(event);
    }
  };

  return (
    <Row style={{ gap: '0.75em' }} alignItems="center">
      <FileInputLabel htmlFor={id}>
        {t('browse')}
        <FileInput
          id={id}
          ref={fileInputRef}
          type="file"
          onChange={handleFileUpload}
          {...rest}
        />
      </FileInputLabel>
      <Text ref={fileItemRef} color="detail">
        {t('input.noFileSelected')}
      </Text>
    </Row>
  );
};

export default Input;