import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InternalLink } from './Typography';

interface Props {
  department: any;
  topLevelDepartment?: any;
}

interface WrapperProps {
  backgroundColor?: string;
}

const gradientBackground = (color: string) => {
  switch (color.toLocaleUpperCase()) {
    case '#5F259F':
      return 'linear-gradient(3deg,rgba(95,37,159,1) 30%,rgba(95,37,159,0.8) 100%)';
    case '#EAAA00':
      return 'linear-gradient(3deg,rgba(234,170,0,1) 30%,rgba(234,170,0,0.8) 100%)';
    case '#00843D':
      return 'linear-gradient(3deg,rgba(0,132,61,1) 30%,rgba(0,132,61,0.8) 100%)';
    default:
      return color;
  }
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 10px 0px 10px 10px;
  border-radius: 5px;
  a {
    color: white;
    opacity: 1;
    text-decoration: none;
    padding: 5px;
  }
`;

export function FacultyHeader(props: Props) {
  const { department, topLevelDepartment } = props;

  const { i18n } = useTranslation();
  const skLang = i18n.language === 'sk';

  const departmentName = skLang ? department.name : department.nameEn;
  const topLevelDepartmentName = skLang
    ? topLevelDepartment?.name
    : topLevelDepartment?.nameEn;

  return (
    <Wrapper backgroundColor={gradientBackground(department.color)}>
      <InternalLink
        text={departmentName}
        href={`/department/${department.idDepartment}`}
        version="invisible"
      />
      {Boolean(topLevelDepartment) && (
        <InternalLink
          text={topLevelDepartmentName}
          href={`/department/${topLevelDepartment.idDepartment}`}
          version="invisible"
        />
      )}
    </Wrapper>
  );
}

FacultyHeader.defaultProps = {
  topLevelDepartment: null,
};
